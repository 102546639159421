import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './index.scss';

const hintLabels = {
  ever: 'EVER',
  percent: '%'
};

const hintClasses = {
  percent: 'filter-pair__input--persent',
  ever: 'filter-pair__input--ton'
};

const FormPair = ({
  title,
  hint,
  from,
  to,
  onChangeFrom,
  onChangeTo
}) => (
  <div className="filter-pair">
    <div className="filter-pair__title">{title}</div>
    <div className="filter-pair__row">
      <label htmlFor="pair-from" className="filter-pair__col">
        {hint && <div className="filter-pair__col-title">{hintLabels[hint]}</div>}
        <input value={from ?? ''} onChange={onChangeFrom} type="text" className={classnames(['filter-pair__input', hintClasses[hint]])} placeholder="From" />
      </label>
      <label htmlFor="pair-to" className="filter-pair__col">
        {hint && <div className="filter-pair__col-title">{hintLabels[hint]}</div>}
        <input value={to ?? ''} onChange={onChangeTo} type="text" className={classnames(['filter-pair__input', hintClasses[hint]])} placeholder="To" />
      </label>
    </div>
  </div>
);

FormPair.propTypes = {
  title: PropTypes.node,
  hint: PropTypes.string,
  from: PropTypes.node,
  to: PropTypes.node,
  onChangeFrom: PropTypes.func,
  onChangeTo: PropTypes.func
};

FormPair.defaultProps = {
  title: null,
  hint: null,
  from: null,
  to: null,
  onChangeFrom: null,
  onChangeTo: null
};

export default FormPair;
