import React from 'react';

import PropTypes from 'prop-types';

import './index.scss';

const ToolWallet = ({ label, avatar, onClick }) => (
  <div className="tools-wallet">
    <div className="tools-wallet__ava">
      {avatar}
    </div>
    <div className="tools-wallet__id" onClick={onClick}>{label}</div>
  </div>
);

ToolWallet.propTypes = {
  label: PropTypes.node,
  avatar: PropTypes.node,
  onClick: PropTypes.func
};

ToolWallet.defaultProps = {
  label: null,
  avatar: null,
  onClick: null
};

export default ToolWallet;
