import React from 'react';

import PropTypes from 'prop-types';
import classnames from 'classnames';

const Table = ({ className, children }) => (
  <div className={classnames(['swap-table', className])}>
    {children}
  </div>
);

Table.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

Table.defaultProps = {
  className: null,
  children: null
};

export default Table;
