import BigNumber from 'bignumber.js';
import PropTypes from 'prop-types';
import React from 'react';
import SectionGrid from 'components/_common/Broxus/SectionGrid';
import Section from 'components/_common/Broxus/Section';
import statistics from 'utils/statistics';
import Deposit from './Deposit';
import Withdraw from './Withdraw';

const MyStakeDistribution = ({
  id,
  boc,
  stake,
  myTotal,
  myRounds,
  myWithdraw,
  myReinvest,
  stakeFee,
  minStake
}) => {
  const info = [
    {
      label: 'My stake, EVER',
      type: 'ton',
      value: myTotal,
      delta: statistics.getStakeDelta({ myRounds })
    },
    {
      label: 'My reward, EVER',
      type: 'ton',
      value: statistics.getDepoolReward({ myRounds }),
      delta: statistics.getDepoolRewardDelta({ myRounds })
    },
    {
      label: 'APY',
      value: `${(statistics.getDepoolMyApyAverage({ myRounds }) * 100).toFixed(1)}%`
    },
    {
      label: 'Your share',
      type: 'percent',
      value: `${new BigNumber(myTotal ?? 0).dividedBy(stake ?? 0).multipliedBy(100).toFixed(4)}%`
    }
  ];

  return (
    <React.Fragment>
      <Section title="My stakes distribution" />
      <SectionGrid info={info}>
        <Deposit
          id={id}
          stakeFee={stakeFee}
          minStake={minStake}
        />
        <Withdraw
          id={id}
          boc={boc}
          minStake={minStake}
          stakeFee={stakeFee}
          myTotal={myTotal}
          myWithdraw={myWithdraw}
          myReinvest={myReinvest}
        />
      </SectionGrid>
    </React.Fragment>
  );
};

MyStakeDistribution.propTypes = {
  id: PropTypes.node,
  boc: PropTypes.string,
  myTotal: PropTypes.string,
  stake: PropTypes.string,
  myRounds: PropTypes.arrayOf(PropTypes.object),
  myWithdraw: PropTypes.node,
  myReinvest: PropTypes.node,
  stakeFee: PropTypes.node,
  minStake: PropTypes.node
};

MyStakeDistribution.defaultProps = {
  id: null,
  boc: null,
  myTotal: null,
  stake: null,
  myRounds: null,
  myWithdraw: null,
  myReinvest: null,
  stakeFee: null,
  minStake: null
};

export default MyStakeDistribution;
