import React from 'react';

import Avatar from 'components/_common/Broxus/PageInfo/Avatar';
import InfoList from 'components/_common/Broxus/PageInfo/InfoList';
import PageInfo from 'components/_common/Broxus/PageInfo';
import AddressAvatar from 'components/_common/AddressAvatar';
import PropTypes from 'prop-types';
import { BigNumber } from 'bignumber.js';

import {
  addrf, prcf, tonf, addressf
} from 'utils/format';
import copy from 'clipboard-copy';

const disabledLabelsOnPoolClosed = ['Users value locked', 'Staking efficiency', 'APY', 'Stakeholders'];

const Profile = ({
  id,
  name,
  imageUrl,
  validatorRewardFraction,
  stake,
  currentRound,
  currentRoundTotal,
  currentRoundUnused,
  rounds,
  proxies,
  minStake,
  balance,
  validatorAssurance,
  // creator,
  validatorWallet,
  codeHash,
  poolClosed,
  depoolVersion,
  apy,
  participantQty,
  onCurrentRoundClick,
  onTotalValueLockedClick,
  onStakeholdersClick
}) => {
  const avatar = imageUrl ? <img src={imageUrl} alt="" style={{ margin: 'auto' }} /> : <AddressAvatar address={id} small={false} />;
  const roundsSuccess = rounds.filter((e) => e.completionReason === 5).length;
  const roundsSuccessPercent = !rounds?.length ? 0 : (roundsSuccess * 100 / rounds.length).toFixed();
  const stakingEfficiencyPercent = !currentRoundTotal || !currentRoundUnused || currentRoundTotal === '0' ? 100
    : new BigNumber(currentRoundTotal).minus(currentRoundUnused).multipliedBy(100).dividedBy(currentRoundTotal)
      .toFixed();
  const apyPercent = new BigNumber(apy).multipliedBy(100).toFixed(2);

  const dataStats = [
    { label: 'Balance', value: tonf(balance), hint: 'ton' },
    {
      label: 'Users value locked',
      value: tonf(stake, { fixed: 0 }),
      valueTitle: tonf(stake),
      hint: 'ton',
      onValueClick: onTotalValueLockedClick
    },
    { label: 'Successful rounds', value: `${roundsSuccess ?? 0} / ${rounds.filter((e) => e.completionReason !== 2).length ?? 0} (${roundsSuccessPercent}%)` },
    {
      label: 'Current round',
      value: `${currentRound ?? 0} (${currentRound && (currentRound % 2) ? 'Odd' : 'Even'})`,
      onValueClick: onCurrentRoundClick
    },
    {
      label: 'Staking efficiency',
      value: prcf(stakingEfficiencyPercent)
    },
    { label: 'APY', value: `${apyPercent}%` },
    {
      label: 'Stakeholders',
      value: participantQty,
      onValueClick: onStakeholdersClick
    }
  ];

  const dataAddresses = [
    /* {
      label: 'Creator',
      value: addrf(creator),
      hint: 'copy',
      onIconClick: () => copy(creator)
    }, */
    {
      label: 'Validator',
      value: addressf(validatorWallet),
      valueTitle: validatorWallet,
      hint: 'copy',
      onIconClick: () => copy(validatorWallet),
      onValueClick: () => window.open(`https://everscan.io/accounts/${validatorWallet}`, '_blank')
    },
    {
      label: 'Proxy Address 1',
      value: addressf(proxies[0]),
      valueTitle: proxies[0],
      hint: 'copy',
      onIconClick: () => copy(proxies[0]),
      onValueClick: () => window.open(`https://everscan.io/accounts/${id}`, '_blank')
    },
    {
      label: 'Proxy Address 2',
      value: addressf(proxies[1]),
      valueTitle: proxies[1],
      hint: 'copy',
      onIconClick: () => copy(proxies[1]),
      onValueClick: () => window.open(`https://everscan.io/accounts/${id}`, '_blank')
    }
  ];

  // TODO: Add type and remove tonf to design component.
  const dataSettings = [
    { label: 'Minimum stake', value: tonf(minStake), hint: 'ton' },
    { label: 'Validator assurance', value: tonf(validatorAssurance), hint: 'ton' },
    { label: 'Depool fee', value: prcf(validatorRewardFraction) },
    { label: 'Depool closed', value: poolClosed ? 'Yes' : 'No' },
    { label: 'Depool version', value: depoolVersion },
    {
      label: 'Depool code hash',
      value: addressf(codeHash),
      valueTitle: codeHash,
      hint: 'copy',
      onIconClick: () => copy(codeHash)
    }
  ];

  return (
    <React.Fragment>
      <PageInfo>
        <Avatar
          avatar={avatar}
          name={name}
          label={addrf(id)}
          url={`https://everscan.io/accounts/${id}`}
          onCopy={() => copy(id)}
          onLink={() => window.open(`https://everscan.io/accounts/${id}`, '_blank')}
        />
        <InfoList title="Brief stats" data={dataStats.filter((e) => !poolClosed || !disabledLabelsOnPoolClosed.includes(e.label))} />
        <InfoList title="Addresses" data={dataAddresses} />
        <InfoList title="Settings" data={dataSettings} />
      </PageInfo>
    </React.Fragment>
  );
};

Profile.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  imageUrl: PropTypes.string,
  validatorRewardFraction: PropTypes.number,
  stake: PropTypes.string,
  currentRound: PropTypes.number,
  currentRoundTotal: PropTypes.string,
  currentRoundUnused: PropTypes.string,
  rounds: PropTypes.arrayOf(PropTypes.object),
  // creator: PropTypes.string,
  balance: PropTypes.string,
  proxies: PropTypes.arrayOf(PropTypes.string),
  minStake: PropTypes.string,
  validatorAssurance: PropTypes.string,
  validatorWallet: PropTypes.string,
  codeHash: PropTypes.string,
  depoolVersion: PropTypes.string,
  poolClosed: PropTypes.bool,
  apy: PropTypes.string,
  participantQty: PropTypes.number,
  onCurrentRoundClick: PropTypes.func,
  onTotalValueLockedClick: PropTypes.func,
  onStakeholdersClick: PropTypes.func
};

Profile.defaultProps = {
  id: null,
  name: null,
  imageUrl: null,
  validatorRewardFraction: null,
  stake: null,
  currentRound: null,
  currentRoundTotal: null,
  currentRoundUnused: null,
  rounds: null,
  // creator: null,
  proxies: null,
  minStake: null,
  balance: null,
  validatorAssurance: null,
  validatorWallet: null,
  codeHash: null,
  depoolVersion: null,
  poolClosed: null,
  apy: null,
  participantQty: null,
  onCurrentRoundClick: null,
  onTotalValueLockedClick: null,
  onStakeholdersClick: null
};

export default Profile;
