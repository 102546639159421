import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ExpandForm from 'components/_common/Broxus/ExpandForm';
import FormPair from 'components/_common/Broxus/ExpandForm/FormPair';

const FilterPanel = ({
  numberFrom,
  numberTo,
  stakeFrom,
  stakeTo,
  unusedFrom,
  unusedTo,
  participantRewardFrom,
  participantRewardTo,
  participantQtyFrom,
  participantQtyTo,
  apyFrom,
  apyTo,
  onFilterChanged
}) => {
  const [roundsFromLocal, setRoundsFromLocal] = useState(numberFrom);
  const [roundsToLocal, setRoundsToLocal] = useState(numberTo);
  const [totalStakeFromLocal, setTotalStakeFromLocal] = useState(stakeFrom);
  const [totalStakeToLocal, setTotalStakeToLocal] = useState(stakeTo);
  const [unusedStakeFromLocal, setUnusedStakeFromLocal] = useState(unusedFrom);
  const [unusedStakeToLocal, setUnusedStakeToLocal] = useState(unusedTo);
  const [rewardFromLocal, setRewardFromLocal] = useState(participantRewardFrom);
  const [rewardToLocal, setRewardToLocal] = useState(participantRewardTo);
  const [participantsFromLocal, setParticipantsFromLocal] = useState(participantQtyFrom);
  const [participantsToLocal, setParticipantsToLocal] = useState(participantQtyTo);
  const [apyFromLocal, setApyFromLocal] = useState(apyFrom);
  const [apyToLocal, setApyToLocal] = useState(apyTo);
  const [count, setCount] = useState(0);
  const counters = [
    () => (numberFrom || numberTo),
    () => (stakeFrom || stakeTo),
    () => (unusedFrom || unusedTo),
    () => (participantRewardFrom || participantRewardTo),
    () => (participantQtyFrom || participantQtyTo),
    () => (apyFrom || apyTo)
  ];

  useEffect(() => {
    setCount(counters.filter((e) => e()).length);
  }, [
    numberFrom, numberTo, stakeFrom, stakeTo, unusedFrom,
    unusedTo, participantRewardFrom, participantRewardTo, participantQtyFrom, participantQtyTo, apyFrom, apyTo
  ]);

  const handleApply = () => {
    onFilterChanged({
      numberFrom: roundsFromLocal ? parseFloat(roundsFromLocal) : null,
      numberTo: roundsToLocal ? parseFloat(roundsToLocal) : null,
      stakeFrom: totalStakeFromLocal ? parseFloat(totalStakeFromLocal) : null,
      stakeTo: totalStakeToLocal ? parseFloat(totalStakeToLocal) : null,
      unusedFrom: unusedStakeFromLocal ? parseFloat(unusedStakeFromLocal) : null,
      unusedTo: unusedStakeToLocal ? parseFloat(unusedStakeToLocal) : null,
      participantRewardFrom: rewardFromLocal ? parseFloat(rewardFromLocal) : null,
      participantRewardTo: rewardToLocal ? parseFloat(rewardToLocal) : null,
      participantQtyFrom: participantsFromLocal ? parseFloat(participantsFromLocal) : null,
      participantsTo: participantsToLocal ? parseFloat(participantsToLocal) : null,
      apyFrom: apyFromLocal ? parseFloat(apyFromLocal) : null,
      apyTo: apyToLocal ? parseFloat(apyToLocal) : null
    });
  };

  const handleClear = () => {
    setRoundsFromLocal('');
    setRoundsToLocal('');
    setTotalStakeFromLocal('');
    setTotalStakeToLocal('');
    setUnusedStakeFromLocal('');
    setUnusedStakeToLocal('');
    setRewardFromLocal('');
    setRewardToLocal('');
    setParticipantsFromLocal('');
    setParticipantsToLocal('');
    setApyFromLocal('');
    setApyToLocal('');
  };

  return (
    <React.Fragment>
      <ExpandForm title="Filter" count={count} onApply={handleApply} onClear={handleClear}>
        <FormPair
          title="Round"
          from={roundsFromLocal}
          to={roundsToLocal}
          onChangeFrom={(event) => setRoundsFromLocal(event.target.value)}
          onChangeTo={(event) => setRoundsToLocal(event.target.value)}
        />
        <FormPair
          title="Total stake"
          hint="ton"
          from={totalStakeFromLocal}
          to={totalStakeToLocal}
          onChangeFrom={(event) => setTotalStakeFromLocal(event.target.value)}
          onChangeTo={(event) => setTotalStakeToLocal(event.target.value)}
        />
        <FormPair
          title="Unused stake"
          hint="ton"
          from={unusedStakeFromLocal}
          to={unusedStakeToLocal}
          onChangeFrom={(event) => setUnusedStakeFromLocal(event.target.value)}
          onChangeTo={(event) => setUnusedStakeToLocal(event.target.value)}
        />
        <FormPair
          title="Reward"
          hint="ton"
          from={rewardFromLocal}
          to={rewardToLocal}
          onChangeFrom={(event) => setRewardFromLocal(event.target.value)}
          onChangeTo={(event) => setRewardToLocal(event.target.value)}
        />
        <FormPair
          title="Participants"
          from={participantsFromLocal}
          to={participantsToLocal}
          onChangeFrom={(event) => setParticipantsFromLocal(event.target.value)}
          onChangeTo={(event) => setParticipantsToLocal(event.target.value)}
        />
        <FormPair
          title="APY"
          from={apyFromLocal}
          to={apyToLocal}
          onChangeFrom={(event) => setApyFromLocal(event.target.value)}
          onChangeTo={(event) => setApyToLocal(event.target.value)}
        />
      </ExpandForm>
    </React.Fragment>
  );
};

FilterPanel.propTypes = {
  numberFrom: PropTypes.number,
  numberTo: PropTypes.number,
  stakeFrom: PropTypes.number,
  stakeTo: PropTypes.number,
  unusedFrom: PropTypes.number,
  unusedTo: PropTypes.number,
  participantRewardFrom: PropTypes.number,
  participantRewardTo: PropTypes.number,
  participantQtyFrom: PropTypes.number,
  participantQtyTo: PropTypes.number,
  apyFrom: PropTypes.number,
  apyTo: PropTypes.number,
  onFilterChanged: PropTypes.func
};

FilterPanel.defaultProps = {
  numberFrom: null,
  numberTo: null,
  stakeFrom: null,
  stakeTo: null,
  unusedFrom: null,
  unusedTo: null,
  participantRewardFrom: null,
  participantRewardTo: null,
  participantQtyFrom: null,
  participantQtyTo: null,
  apyFrom: null,
  apyTo: null,
  onFilterChanged: null
};

export default FilterPanel;
