import React from 'react';
import PropTypes from 'prop-types';

import InfoList from 'components/_common/Broxus/List/InfoList';

import { tonf, roundStage } from 'utils/format';
import { roundStages, roundReasons } from 'constants/depools';
import { BigNumber } from 'bignumber.js';

const Info = ({
  stake,
  ordinaryStake,
  vestingStake,
  lockedStake,
  validatorStake,
  unused,
  step,
  completionReason
}) => {
  const dataInfo = [
    { label: 'Total value locked', value: tonf(stake, { fixed: 0 }), hint: 'ton' },
    { label: 'Ordinary stake', value: tonf(ordinaryStake, { fixed: 0 }), hint: 'ton' },
    { label: 'Vesting stake', value: tonf(vestingStake, { fixed: 0 }), hint: 'ton' },
    { label: 'Locked stake', value: tonf(lockedStake, { fixed: 0 }), hint: 'ton' },
    { label: 'Validator stake', value: tonf(validatorStake, { fixed: 0 }), hint: 'ton' },
    { label: 'Unused stake', value: tonf(unused, { fixed: 0 }), hint: 'ton' },
    {
      label: 'Efficiency',
      value: `${!parseFloat(stake) ? 0 : parseInt(new BigNumber(stake).minus(unused).dividedBy(stake).multipliedBy(100), 10)}%`
    },
    { label: 'Stage', value: roundStages[roundStage(step, completionReason)] },
    { label: 'Reason', value: roundReasons[completionReason ?? 0] }
  ];

  return (
    <InfoList title="Round Information" data={dataInfo} />
  );
};

Info.propTypes = {
  stake: PropTypes.string,
  ordinaryStake: PropTypes.string,
  vestingStake: PropTypes.string,
  lockedStake: PropTypes.string,
  validatorStake: PropTypes.string,
  unused: PropTypes.string,
  step: PropTypes.string,
  completionReason: PropTypes.string
};

Info.defaultProps = {
  stake: null,
  ordinaryStake: null,
  vestingStake: null,
  lockedStake: null,
  validatorStake: null,
  unused: null,
  step: null,
  completionReason: null
};

export default Info;
