import React, { useState, useEffect } from 'react';
import AnaliticsBar from 'components/_common/Broxus/AnaliticsBar';
import Distribution from 'components/_common/Broxus/AnaliticsBar/Distribution';
import InfoList from 'components/_common/Broxus/AnaliticsBar/InfoList';
import Section from 'components/_common/Broxus/AnaliticsBar/Section';
import Stage from 'components/_common/Broxus/AnaliticsBar/Stage';

import PropTypes from 'prop-types';
import { BigNumber } from 'bignumber.js';

import { prcf, tonf, roundStage } from 'utils/format';
import { roundStages, roundReasons } from 'constants/depools';

// TODO: Add type and remove tonf to design component.
const getDataInfo = (current, totalStake) => ([
  {
    label: 'Elections',
    value: !current?.supposedElectedAt ? '-'
      : new Date(current?.supposedElectedAt * 1000)
        .toLocaleTimeString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
  },
  {
    label: 'Total stake, EVER',
    value: !totalStake || totalStake === '0' ? '-' : tonf(totalStake, { fixed: 0 })
  },
  {
    label: 'Efficiency',
    value: !totalStake || totalStake === '0' ? '-' : prcf(
      new BigNumber(totalStake).minus(current?.unused).multipliedBy(100).dividedBy(totalStake)
        .toFixed()
    ),
    hint: 'info'
  },
  { label: 'Reward, EVER', value: tonf(current?.participantReward, { fixed: 0 }) },
  { label: 'Stakeholders', value: current?.participantQty },
  { label: 'Completion reason', value: `${roundReasons[current?.completionReason ?? 0]} (${current?.completionReason})` }
]);

const distributionMeta = [
  { id: 'type', type: 'color', label: 'Stake Type' },
  { id: 'amount', type: 'ton', label: 'Amount, EVER' },
  { id: 'share', type: 'percent', label: 'Share' }
];

const getShare = (amount, total) => {
  if ((total ?? '0') === '0')
    return 0;

  return parseInt(new BigNumber(amount).multipliedBy(100).dividedBy(total).toFixed(), 10);
};

const RoundsBars = ({
  data,
  roundNumber
}) => {
  const [current, setCurrent] = useState();
  const [dataInfo, setDataInfo] = useState();
  const [distributionData, setDistributionData] = useState();

  useEffect(() => {
    setCurrent(data?.filter((e) => e.number === roundNumber)[0]);
  }, [data, roundNumber]);

  useEffect(() => {
    if (!current)
      return;

    const totalStake = new BigNumber(current.validatorStake ?? '0')
      .plus(current.ordinaryStake ?? '0')
      .plus(current.vestingStake ?? '0')
      .plus(current.lockedStake ?? '0')
      .plus(current.unused ?? '0')
      .toString();

    setDataInfo(getDataInfo(current, totalStake));

    setDistributionData([
      { type: 'Unused', amount: current.unused, share: getShare(current.unused, totalStake) },
      { type: 'Validator', amount: current.validatorStake, share: getShare(current.validatorStake, totalStake) },
      { type: 'Ordinary', amount: current.ordinaryStake, share: getShare(current.ordinaryStake, totalStake) },
      { type: 'Vesting', amount: current.vestingStake, share: getShare(current.vestingStake, totalStake) },
      { type: 'Locked', amount: current.lockedStake, share: getShare(current.lockedStake, totalStake) }
    ]);
  }, [current]);

  return (
    <AnaliticsBar>
      <Section title="Round info">
        {dataInfo && <InfoList data={dataInfo} />}
      </Section>
      <Section title="Stake distribution">
        {distributionData && <Distribution meta={distributionMeta} data={distributionData} />}
      </Section>
      <Section title="Stage">
        <Stage data={roundStages} index={roundStage(current?.step, current?.completionReason)} />
      </Section>
    </AnaliticsBar>
  );
};

RoundsBars.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    number: PropTypes.number,
    validatorStake: PropTypes.string,
    ordinaryStake: PropTypes.string,
    vestingStake: PropTypes.string,
    lockedStake: PropTypes.string
  })),
  roundNumber: PropTypes.number
};

RoundsBars.defaultProps = {
  data: null,
  roundNumber: null
};

export default RoundsBars;
