/* eslint-disable quote-props */
export const depoolVersions = {
  '3': '14e20e304f53e6da152eb95fffc993dbd28245a775d847eed043f7c78a503885'
  // '2': 'a46c6872712ec49e481a7f3fc1f42469d8bd6ef3fae906aa5b9927e5a3fb3b6b'
};
/* eslint-enable quote-props */

export const roundReasons = [
  'Round is not completed yet',
  'Depool is closed',
  'Fake round',
  'Validator stake is too small',
  'Stake is rejected by elector',
  'Reward is received from elector',
  'Depool has participated in elections but lost the elections',
  'Validator is blamed during investigation phase',
  'Validator sent no request during election phase'
];

export const roundStages = [
  'Pre-pooling',
  'Pooling',
  'Validator request',
  'Stake acceptance',
  'Start of validation',
  'Election results',
  'Stake unfreeze',
  'Reward distribution',
  'Completing',
  'Completed'
];

export const stakeTypes = {
  ordinary: 'Ordinary',
  vestingDonor: 'Vesting (donor)',
  vestingBeneficiary: 'Vesting (beneficiary)',
  lockedDonor: 'Locked (donor)',
  lockedBeneficiary: 'Locked (beneficiary)'
};

export const currentRounds = [2, 3, 4, 5, 6, 7];

export const mainPageFilter = {
  stake: 10000000000000,
  validatorRewardFraction: 50,
  minElectedAt: (() => {
    const d = new Date();
    d.setDate(d.getDate() - 5);
    return d.getTime() / 1000;
  })()
};
