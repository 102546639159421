import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const InlineSearchBar = ({
  search,
  onSearch
}) => (
  <div className="search">
    <input
      type="text"
      className="search__input"
      placeholder="Search..."
      value={search ?? ''}
      onChange={onSearch}
    />
  </div>
);

InlineSearchBar.propTypes = {
  search: PropTypes.node,
  onSearch: PropTypes.func
};

InlineSearchBar.defaultProps = {
  search: null,
  onSearch: null
};

export default InlineSearchBar;
