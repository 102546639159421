import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const Avatar = ({
  avatar,
  name,
  label,
  url,
  onCopy,
  onLink
}) => {
  const handleLink = !onLink ? null : (e) => {
    onLink();

    e.preventDefault();
    e.stopPropagation();
    return false;
  };

  return (
    <React.Fragment>
      <div className="swap-profile__blc swap-profile-main">
        <div className="swap-profile-main__ava">
          {avatar}
        </div>
        {name && <div className="swap-profile-main__id">{name}</div>}
        <div className="swap-profile-main__id">{label}</div>
        <div className="swap-profile-main__tools">
          {onCopy && (
            <button type="button" className="btn swap-profile-main__tool" onClick={onCopy}>
              <img src="/assets/svg/copy-tool.svg" alt="" />
            </button>
          )}
          {onLink && (
            <a href={url} type="button" className="btn swap-profile-main__tool" onClick={handleLink}>
              <img src="/assets/svg/link-tool.svg" alt="" />
            </a>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

Avatar.propTypes = {
  name: PropTypes.string,
  label: PropTypes.node,
  avatar: PropTypes.node,
  url: PropTypes.string,
  onCopy: PropTypes.func,
  onLink: PropTypes.func
};

Avatar.defaultProps = {
  name: null,
  label: null,
  avatar: null,
  url: null,
  onCopy: null,
  onLink: null
};

export default Avatar;
