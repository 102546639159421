import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import BigNumber from 'bignumber.js';

import { tonf } from 'utils/format';
import API from 'utils/api';
import TonWalletExt from 'utils/tonWalletExt';

import { BALANCE_LOAD, MY_DEPOOL_LOAD } from 'constants/actions';
import SectionBlock from 'components/_common/Broxus/SectionBlock';

const ZeroNum = new BigNumber(0);
const WaitingTimeout = 300000;
const PoolingSteps = ['0', '1'];

const getPoolingStakes = (stakes, roundsAll) => {
  if (!roundsAll || !stakes)
    return '0';
  return Object.keys(stakes)
    .map((num) => roundsAll.rounds[num])
    .filter((r) => PoolingSteps.includes(r.step))
    .map((r) => stakes[r.id])
    .reduce((a, e) => a.plus(e ?? 0), new BigNumber(0))
    .toString();
};

const Withdraw = ({
  id: address,
  boc,
  stakeFee: depoolFee,
  minStake,
  myTotal,
  myWithdraw,
  myReinvest
}) => {
  const dispatch = useDispatch();

  const { myAddress } = useSelector((state) => state.config);
  const [balance, setBalance] = useState();
  const [balanceInProgress, setBalanceInProgress] = useState(myWithdraw ?? '0');
  const [poolingStake, setPoolingStake] = useState('0');
  const [value, setValue] = useState('');
  const [all, setAll] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [transfering, setTransfering] = useState(false);
  const [participantRegistered, setParticipantRegistered] = useState(true);
  const [warningWithdrawOnRoundCompletion, setWarningWithdrawOnRoundCompletion] = useState(false);
  const [warningWithdrawFully, setWarningWithdrawFully] = useState(false);

  useEffect(() => {
    const b = (new BigNumber(myTotal ?? '0')).minus(myWithdraw ?? '0');
    setBalance((b.isLessThan(0) ? ZeroNum : b).toString());
    setBalanceInProgress(myWithdraw ?? '0');
  }, [myTotal, myWithdraw, myAddress]);

  useEffect(async () => {
    let accountBoc = boc;
    if (!boc) {
      const acc = await API.getAccount({ address });
      if (acc?.hash_code)
        accountBoc = acc.boc;
    }
    if (!accountBoc)
      return;
    const participantInfo = await API.getParticipantInfo({ address, boc: accountBoc, participant: myAddress });
    setParticipantRegistered(!!participantInfo);
    if (participantInfo) {
      const { stakes } = participantInfo;
      const roundsAll = await API.getRounds({ address, boc: accountBoc });
      setPoolingStake(getPoolingStakes(stakes, roundsAll));
    }
  }, [address, boc, myAddress]);

  useEffect(async () => {
    if (!value || !participantRegistered) {
      setDisabled(true);
      return;
    }

    const balanceNum = new BigNumber(balance);
    const valueNum = new BigNumber(value).multipliedBy(10 ** 9);
    setDisabled(valueNum.lte(ZeroNum)
      || balanceNum.isLessThan(valueNum));
  }, [value, balance, myAddress]);

  useEffect(() => {
    setWarningWithdrawOnRoundCompletion(
      (new BigNumber(poolingStake ?? 0).lt(new BigNumber(value).multipliedBy(10 ** 9))
        || new BigNumber(poolingStake ?? 0).lte(0))
        && participantRegistered && (new BigNumber(balance)).gt(ZeroNum)
    );
    setWarningWithdrawFully(new BigNumber(value).multipliedBy(10 ** 9).lte(0) ? false
      : new BigNumber(balance).minus(new BigNumber(value).multipliedBy(10 ** 9))
        .lte(minStake));
  }, [value, poolingStake, participantRegistered, balance]);

  const handleMax = () => {
    setValue(new BigNumber(balance).dividedBy(10 ** 9).toString());
    setAll(false);
  };

  const handleSetValue = (v) => {
    setValue(v.toString());
    setAll(false);
  };

  const handleWithdraw = async () => {
    const acc = await API.getAccount({ address });
    const participantInfo = await API.getParticipantInfo({ address, boc: acc?.boc, participant: myAddress });
    const roundsAll = await API.getRounds({ address, boc: acc?.boc });
    if (!participantInfo)
      return;

    const {
      withdrawValue: curWithdrawal,
      reinvest: curReinvest,
      total: curTotal,
      stakes: curStakes
    } = participantInfo;
    const withdrawingNum = new BigNumber(value).multipliedBy(10 ** 9);
    const withdrawValueNum = withdrawingNum.plus(curWithdrawal ?? '0');
    const curPoolingStake = getPoolingStakes(curStakes, roundsAll);
    const immediateWithdraw = withdrawingNum.lte(curPoolingStake);

    setTransfering(true);
    let result;
    try {
      if (immediateWithdraw) {
        result = await TonWalletExt.withdrawFromPoolingRound({
          addressFrom: myAddress,
          addressTo: address,
          withdrawValue: withdrawingNum.toString(),
          amount: depoolFee
        });
      }
      else if (all && (curReinvest ?? true)) {
        result = await TonWalletExt.withdrawAll({
          addressFrom: myAddress,
          addressTo: address,
          amount: depoolFee
        });
      }
      else if (!all && withdrawValueNum.isLessThanOrEqualTo(curTotal ?? '0')) {
        result = await TonWalletExt.withdrawPart({
          addressFrom: myAddress,
          addressTo: address,
          withdrawValue: withdrawValueNum.toString(),
          amount: depoolFee
        });
      }
    }
    catch {
      result = null;
    }

    if (!result) {
      setTransfering(false);
      return;
    }

    const untilTime = new Date(new Date().getTime() + WaitingTimeout);
    const timerId = setInterval(async () => {
      try {
        const newAccount = await API.getAccount({ address });
        const {
          withdrawValue: newWithdrawal,
          reinvest: newReinvest,
          stakes: newStakes
        } = await API.getParticipantInfo({ address, boc: newAccount.boc, participant: myAddress });
        const newPoolingStake = new BigNumber(getPoolingStakes(newStakes, roundsAll) ?? 0);

        if ((!immediateWithdraw && !all && withdrawValueNum.lte(newWithdrawal))
          || (immediateWithdraw && newPoolingStake.lt(curPoolingStake))
          || (!immediateWithdraw && all && newReinvest)
          || (untilTime < new Date())) {
          clearInterval(timerId);
          setValue('');
          setTransfering(false);
          dispatch({
            type: MY_DEPOOL_LOAD,
            participant: myAddress,
            address,
            boc: newAccount.boc
          });
          dispatch({ type: BALANCE_LOAD, address: myAddress });
        }
      }
      catch {
        clearInterval(timerId);
        setTransfering(false);
      }
    }, 1000);
  };

  const description = (
    <React.Fragment>
      Your unclaimed balance on depool:&nbsp;
      {tonf(balance)}
      &nbsp;EVER
      {!participantRegistered && (new BigNumber(balance)).gt(ZeroNum) && (
        <React.Fragment>
          <br />
          Sorry, your deposit is not processed yet: withdrawal is locked.
        </React.Fragment>
      )}
      {(new BigNumber(balanceInProgress)).gt(ZeroNum) && (
        <React.Fragment>
          <br />
          Withdrawals in process:&nbsp;
          {tonf(balanceInProgress)}
          &nbsp;EVER
        </React.Fragment>
      )}
      {participantRegistered && (new BigNumber(poolingStake ?? 0).gt(0)) && (
        <React.Fragment>
          <br />
          Pooling round stake (immediate withdrawal):&nbsp;
          {tonf(poolingStake)}
          &nbsp;EVER
        </React.Fragment>
      )}
      {myReinvest && warningWithdrawOnRoundCompletion && !warningWithdrawFully && (
        <React.Fragment>
          <br />
          Note. Withdrawal will be processed after the round completion.
        </React.Fragment>
      )}
      {warningWithdrawFully && (
        <React.Fragment>
          <br />
          Note. Stake will be withdrawed fully.
        </React.Fragment>
      )}
      {!warningWithdrawFully && !myReinvest && (
        <React.Fragment>
          <br />
          Re-invest is disabled: on round completion its stake will be withdrawed fully.
        </React.Fragment>
      )}
    </React.Fragment>
  );

  return (
    <SectionBlock
      title="Withdraw EVER funds"
      description={description}
      value={value}
      actionTitle="Withdraw"
      onChangeValue={(v) => handleSetValue(v)}
      onMax={handleMax}
      onApply={handleWithdraw}
      locked={disabled}
      disabled={transfering}
      styleColor="red"
    />
  );
};

Withdraw.propTypes = {
  id: PropTypes.string.isRequired,
  boc: PropTypes.string,
  stakeFee: PropTypes.string.isRequired,
  minStake: PropTypes.string.isRequired,
  myTotal: PropTypes.string,
  myWithdraw: PropTypes.string,
  myReinvest: PropTypes.bool
};

Withdraw.defaultProps = {
  boc: null,
  myTotal: null,
  myWithdraw: null,
  myReinvest: null
};

export default Withdraw;
