import React from 'react';
import BigNumber from 'bignumber.js';
import PropTypes from 'prop-types';

import SideBar from 'components/_common/Broxus/SideBar';
import { recommendFixed, tonf } from 'utils/format';

const Overview = ({ statistics, children }) => {
  const textView = (value) => (value === null || value === undefined ? null : <React.Fragment>{!value ? '-' : value.toLocaleString()}</React.Fragment>);

  const getDeltaHint = (value, type) => ((type === 'ton' && new BigNumber(value).isLessThan(0))
    || (type !== 'ton' && value < 0) ? 'down' : 'up');

  const getDisplayValue = (type, value, ignoreZero) => {
    if (type === 'ton') {
      const fixed = recommendFixed(value);
      const result = tonf(value, { fixed });
      if (ignoreZero && (!value || new BigNumber(value).abs().isZero() || result === '0' || result === '-0'))
        return null;
      return result;
    }
    return textView(value);
  };

  const data = (statistics ?? []).map((s) => ({
    label: s.label,
    value: getDisplayValue(s.type, s.value, false),
    comment: getDisplayValue(s.type, s.delta, true),
    commentHint: getDeltaHint(s.delta, s.type),
    onClick: s.onClick
  }));

  return (
    <SideBar data={data}>
      {children}
    </SideBar>
  );
};

Overview.propTypes = {
  statistics: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    type: PropTypes.string,
    value: PropTypes.node,
    delta: PropTypes.node,
    onClick: PropTypes.func
  })).isRequired,
  children: PropTypes.node
};

Overview.defaultProps = {
  children: null
};

export default Overview;
