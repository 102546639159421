import React from 'react';
import PropTypes from 'prop-types';
import copy from 'clipboard-copy';

import List from 'components/_common/Broxus/List';
import { tonf } from 'utils/format';
import history from 'utils/history';

const tonFixed = (value) => ((value ?? '').length < 12 ? 2 : 0);

const Depools = ({ data }) => {
  const meta = [
    {
      id: 'id',
      type: 'address',
      label: 'Address',
      image: (item) => item.imageUrl,
      title: (item) => item.name,
      onCopy: (item) => copy(item.id),
      onOpen: (item) => window.open(`https://everscan.io/accounts/${item.id}`, '_blank')
    },
    {
      id: 'myTotal',
      type: 'ton',
      label: 'Stakeholder\nStake, EVER',
      view: (v) => tonf(v, { fixed: tonFixed(v) })
    },
    {
      id: 'myReward',
      type: 'ton',
      label: 'Stakeholder\nReward, EVER'
    },
    {
      id: 'validatorRewardFraction', type: 'percent', label: 'Fee', hideable: true
    },
    {
      id: 'apyPercent', type: 'percent', label: 'APY', hideable: true
    },
    {
      id: 'participantCount', type: 'numeric', label: 'StakeHolders', hideable: true
    },
    { id: 'stake', type: 'ton', label: 'TVL, EVER' },
    { label: 'More', action: 'info', onClick: (item) => history.push(`/depools/${item.id}`) }
  ];
  return (
    <List data={data} meta={meta} className="participant-depools-table" />
  );
};

Depools.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({}))
};

Depools.defaultProps = {
  data: null
};

export default Depools;
