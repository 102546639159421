import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import copy from 'clipboard-copy';

import ToolBar from 'components/_common/Broxus/ToolBar';
import Loader from 'components/_common/Broxus/ToolBar/Loader';
import ToolAction from 'components/_common/Broxus/ToolBar/ToolAction';
import ToolLabel from 'components/_common/Broxus/ToolBar/ToolLabel';
import ToolWallet from 'components/_common/Broxus/ToolBar/ToolWallet';
import ToolExit from 'components/_common/Broxus/ToolBar/ToolExit';
import AddressAvatar from 'components/_common/AddressAvatar';
import { useLocation } from 'react-router-dom';

import tonWalletExt from 'utils/tonWalletExt';

import { tonf, addrf } from 'utils/format';
import {
  SET_MY_ADDRESS, BALANCE_LOAD, WALLET_CONNECT_POPUP, FAVORITES_LOAD, PARTICIPANT_DEPOOLS_LOAD
} from 'constants/actions';
import history from '../../utils/history';

const ToolPanel = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    myAddress,
    myBalance,
    loaded,
    depools
  } = useSelector((state) => state.config);

  const handleLogin = async () => {
    if (!(await tonWalletExt.hasProvider())) {
      dispatch({ type: WALLET_CONNECT_POPUP, cantconnectview: true });
      return;
    }

    const accountInteraction = await tonWalletExt.requestAccountInteraction();
    if (!accountInteraction)
      return;

    dispatch({ type: FAVORITES_LOAD });
    dispatch({ type: BALANCE_LOAD, address: accountInteraction.address });
    dispatch({ type: PARTICIPANT_DEPOOLS_LOAD, participant: accountInteraction.address, data: depools });
  };

  const handleExit = async () => {
    await tonWalletExt.disconnect();

    dispatch({ type: SET_MY_ADDRESS, address: null });
    dispatch({ type: FAVORITES_LOAD });

    if (location.pathname === '/my')
      history.push('/');
  };

  if (!myAddress) {
    return (
      <ToolBar>
        {!loaded && <Loader />}
        <ToolAction label="Connect to a wallet" onClick={handleLogin} />
      </ToolBar>
    );
  }

  const avatar = <AddressAvatar address={myAddress} small />;

  const getBalanceFixed = () => {
    if ((myBalance?.length ?? 0) <= 9)
      return null;
    if (document.body.offsetWidth <= 1040) {
      let fixed = Math.ceil(((document.body.offsetWidth - 980) / 6) + 11 - myBalance.length);
      if (fixed < 2)
        fixed = 2;
      return { fixed };
    }

    return null;
  };

  return (
    <ToolBar>
      {!loaded && <Loader />}
      <ToolLabel label={`${tonf(myBalance, getBalanceFixed())} EVER`} />
      <ToolWallet avatar={avatar} label={addrf(myAddress)} onClick={() => copy(myAddress)} />
      <ToolExit onClick={handleExit} />
    </ToolBar>
  );
};

export default ToolPanel;
