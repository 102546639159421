import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Section from 'components/_common/Broxus/Section';
import ChartBar from 'components/_common/Broxus/ChartBar';
import Overview from 'components/Overview';
import Depools from 'components/MyPage/Depools';
import Withdrawals from 'components/MyPage/Withdrawals';
import FilterPanel from 'components/MyPage/FilterPanel';
import BigNumber from 'bignumber.js';

import { PARTICIPANT_DEPOOLS_LOAD, SET_LOADED } from 'constants/actions';

import statistics from 'utils/statistics';

const getMyDepools = (data) => (!data ? null : data.filter((e) => e.myTotal));

const getMyWithdrawingDepools = (data) => (!data ? null : data.filter((e) => (e.myWithdraw ?? '0') !== '0' || !(e.myReinvest ?? true)));

const getMyAllDepools = (data) => (!data ? null : data.filter((e) => e.myRounds));

const getMyStats = (items, all) => [
  {
    label: 'TVL, EVER',
    type: 'ton',
    value: statistics.getStakesTotal({ depools: items, prop: 'myTotal' }),
    delta: statistics.getStakesDelta({ depools: all })
  },
  {
    label: 'Reward, EVER',
    type: 'ton',
    value: statistics.getRewardsTotal({ depools: all }),
    delta: statistics.getRewardsDelta({ depools: all })
  },
  { label: 'Average APY', value: `${(statistics.getMyApyAverage({ depools: all }) * 100).toFixed(1)}%` },
  { label: 'Active depools', value: !items ? 0 : items.filter((e) => new BigNumber(e.myTotal).gt(0)).length }
];

const getMyCharts = (items) => [
  { label: 'TVL', ...statistics.getMyStakesChart({ depools: items }) },
  { label: 'APY', ...statistics.getMyApyChart({ depools: items }) },
  { label: 'Rewards', ...statistics.getMyRewardsChart({ depools: items }) }
];

const MyPage = () => {
  const dispatch = useDispatch();
  const [myDepools, setMyDepools] = useState();
  const [myWithdrawingDepools, setMyWithdrawingDepools] = useState(null);
  const [myStats, setMyStats] = useState(getMyStats([], []));
  const [myCharts, setMyCharts] = useState(getMyCharts([]));
  const [loaded, setLoaded] = useState(false);

  const {
    depools,
    depoolsLoaded,
    myAddress,
    depoolsRewardLoaded,
    favorites,
    participants,
    myPoolsHistory
  } = useSelector((state) => state.config);

  const getMyFavoriteDepools = () => (!depools ? null : depools.filter((e) => e.myTotal
    || (favorites && favorites.includes(e.id))));

  useEffect(() => {
    const isDataLoaded = participants && (!!participants[myAddress] || typeof participants[myAddress] !== 'undefined');
    dispatch({ type: SET_LOADED, loaded: isDataLoaded });
    setLoaded(isDataLoaded);
  }, [participants, myAddress]);

  useEffect(() => {
    const myActiveDepools = getMyDepools(depools);
    const myAllDepools = getMyAllDepools(depools);
    setMyStats(getMyStats(myActiveDepools, myAllDepools));
    setMyCharts(getMyCharts(myAllDepools));
    setMyWithdrawingDepools(getMyWithdrawingDepools(depools));
  }, [depools, depoolsRewardLoaded, depoolsLoaded, myAddress, loaded]);

  useEffect(() => {
    const myFavoriteDepools = getMyFavoriteDepools(depools);
    setMyDepools(myFavoriteDepools);

    let filtered = depools;
    if (!filtered)
      return;
    if (!myPoolsHistory) {
      filtered = filtered.filter((e) => new BigNumber(e.myTotal) > 0);
      setMyDepools(filtered);
    }
  }, [depools, depoolsRewardLoaded, depoolsLoaded, favorites, myPoolsHistory]);

  useEffect(async () => {
    if (!myAddress || !depoolsLoaded)
      return;

    dispatch({ type: PARTICIPANT_DEPOOLS_LOAD, participant: myAddress, data: depools });
  }, [myAddress, depools, depoolsLoaded]);

  return (
    <React.Fragment>
      <Section title="Portfolio Performance">
        <Overview key="my" statistics={myStats}>
          <ChartBar data={myCharts} />
        </Overview>
      </Section>
      <Section title="Pending withdrawals from depools">
        <Withdrawals data={myWithdrawingDepools} />
      </Section>
      <Section title="My depools" tool={<FilterPanel />} comment={!depoolsLoaded ? ' loading...' : null}>
        <Depools key="my" data={myDepools} />
      </Section>
    </React.Fragment>
  );
};

export default MyPage;
