import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import copy from 'clipboard-copy';
import { BigNumber } from 'bignumber.js';

import List from 'components/_common/Broxus/List';
import Section from 'components/_common/Broxus/Section';
import FilterPanel from 'components/DepoolPage/Stakeholders/FilterPanel';

import history from 'utils/history';
import { getSearchString } from 'utils/url';
import { stakeTypes } from 'constants/depools';

const Stakeholders = forwardRef(({ data }, ref) => {
  const [filter, setFilter] = useState({ search: getSearchString(window.location.search, 'stakeholder') });
  const [filteredItems, setFilteredItems] = useState(data);

  useEffect(() => {
    let items = data;
    if (filter.amountFrom)
      items = items.filter((e) => new BigNumber(e.amount).dividedBy(10 ** 9).gte(filter.amountFrom));
    if (filter.amountTo)
      items = items.filter((e) => new BigNumber(e.amount).dividedBy(10 ** 9).lte(filter.amountTo));
    if (filter.unusedStakeFrom)
      items = items.filter((e) => new BigNumber(e.unusedStake).dividedBy(10 ** 9).gte(filter.unusedStakeFrom));
    if (filter.unusedStakeTo)
      items = items.filter((e) => new BigNumber(e.unusedStake).dividedBy(10 ** 9).lte(filter.unusedStakeTo));
    if (filter.dayRewardFrom)
      items = items.filter((e) => new BigNumber(e.dayReward).dividedBy(10 ** 9).gte(filter.dayRewardFrom));
    if (filter.dayRewardTo)
      items = items.filter((e) => new BigNumber(e.dayReward).dividedBy(10 ** 9).lte(filter.dayRewardTo));
    if (filter.totalRewardFrom)
      items = items.filter((e) => new BigNumber(e.totalReward).dividedBy(10 ** 9).gte(filter.totalRewardFrom));
    if (filter.totalRewardTo)
      items = items.filter((e) => new BigNumber(e.totalReward).dividedBy(10 ** 9).lte(filter.totalRewardTo));
    if (filter.type)
      items = items.filter((e) => e.type === filter.type);
    if (filter.search)
      items = items.filter((e) => e.address === filter.search || e.address.includes(filter.search));
    setFilteredItems(items);
  }, [filter, data]);

  const meta = [
    {
      id: 'address',
      type: 'address',
      label: 'Address',
      onClick: (item) => history.push(`/stakeholders/${item.address}`),
      onCopy: (item) => copy(item.address),
      onOpen: (item) => window.open(`https://everscan.io/accounts/${item.address}`, '_blank'),
      onLink: (item) => copy(
        `${window.location.protocol}//${window.location.host}${window.location.pathname}?stakeholder=${item.address}`
      )
    },
    {
      id: 'type',
      type: 'string',
      label: 'Stake type',
      view: (v) => stakeTypes[v]
    },
    { id: 'amount', type: 'ton', label: 'Amount, EVER' },
    { id: 'unusedStake', type: 'ton', label: 'Unused stake, EVER' },
    { id: 'dayReward', type: 'ton', label: 'Latest reward, EVER' },
    { id: 'totalReward', type: 'ton', label: 'Total reward, EVER' },
    { id: 'date', type: 'datetime', label: 'Staking since, UTC' }
  ];

  const handleFilterChanged = (e) => {
    setFilter({
      ...filter,
      ...e
    });
  };

  const handleSearchChanged = (e) => {
    setFilter({
      ...filter,
      ...e
    });
  };

  const tool = (
    <FilterPanel {...filter} onFilterChanged={handleFilterChanged} onSearchChanged={handleSearchChanged} />
  );

  return (
    <Section ref={ref} title="Stakeholders" tool={tool}>
      <List data={filteredItems.map((e) => ({ ...e, id: `${e.address}-${e.type}` }))} meta={meta} className="stakeholders-table" />
    </Section>
  );
});

Stakeholders.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({}))
};

Stakeholders.defaultProps = {
  data: null
};

export default Stakeholders;
