export const getSearchString = (search, variable) => {
  const query = search.substring(search.indexOf('?') + 1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === variable) {
      if (pair.length === 1)
        return '';
      return decodeURIComponent(pair[1]);
    }
  }
  return undefined;
};

export const getSearchFloat = (search, variable) => {
  const result = getSearchString(search, variable);
  if (!result)
    return null;
  const parseResult = parseFloat(result);
  if (!parseResult)
    return null;
  return parseResult;
};

export const getSearchBool = (search, variable, def) => {
  const result = getSearchString(search, variable);
  if (!result)
    return def;
  return result === 'true' || result !== 'false';
};

export const getSearchParams = (search, ignored) => {
  const query = search.substring(1);
  const vars = query.split('&');
  const result = {};
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');

    if (!ignored || ignored.filter((e) => e.toLowerCase() === pair[0].toLowerCase()).length === 0) {
      if (pair.length > 1)
        result[decodeURI(pair[0])] = decodeURIComponent(pair[1]);
      else
        result[decodeURI(pair[0])] = '';
    }
  }
  return result;
};
