import { openDB } from 'idb';

import { DATABASE_VERSION, FAVORITES_STORE } from 'constants/cache';

let store = null;
let cacheDbName = null;

const cache = {
  init: async (dbName) => {
    cacheDbName = dbName;
    store = await openDB(dbName, DATABASE_VERSION, {
      upgrade(db, oldVersion, newVersion) {
        if (oldVersion < newVersion) {
          try {
            db.createObjectStore(FAVORITES_STORE);
          }
          catch {
            // empty console
          }
        }
      }
    });
  },

  put: (dbName, value, query) => {
    if (!store || dbName !== cacheDbName)
      return null;

    try {
      return store.put(FAVORITES_STORE, value, query);
    }
    catch (error) {
      return null;
    }
  },

  delete: (dbName, key) => {
    if (!store || dbName !== cacheDbName)
      return null;

    try {
      return store.delete(FAVORITES_STORE, key);
    }
    catch (error) {
      return null;
    }
  },

  get: (dbName, query) => {
    if (!store || dbName !== cacheDbName)
      return null;

    try {
      return store.get(FAVORITES_STORE, query);
    }
    catch (error) {
      return null;
    }
  },

  getAllKeys: (dbName) => {
    if (!store || dbName !== cacheDbName)
      return [];

    try {
      return store.getAllKeys(FAVORITES_STORE);
    }
    catch (error) {
      return [];
    }
  }
};

export default cache;
