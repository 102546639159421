import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './index.scss';

const SectionBlock = ({
  styleColor,
  title,
  description,
  value,
  actionTitle,
  onChangeValue,
  onMax,
  onApply,
  onCancel,
  locked,
  disabled
}) => {
  const handleChange = (evt) => {
    let verifiedValue = evt.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
    if (verifiedValue.includes('.') && (verifiedValue.split('.')[1].length > 9))
      verifiedValue = parseFloat(verifiedValue).toFixed(9);
    onChangeValue(verifiedValue);
  };

  return (
    <React.Fragment>
      <section className={classnames(['block block--sm', `block--${styleColor}-grad row-block`])}>
        <h3 className="block-title">{title}</h3>
        <p className="block-txt">{description}</p>
        <div className="simple-form">
          <div className="simple-form__row simple-form__row--mob-blc">
            <div className="simple-label">
              <input type="text" className="simple-input" placeholder="Amount..." value={value} onChange={handleChange} disabled={disabled} />
              {onMax && (
                <div className="amount__tools">
                  <button onClick={onMax} className="btn--xs btn--secondary simple-label__btn" type="button" disabled={disabled}>Max</button>
                </div>
              )}
            </div>
            {onApply && <button onClick={onApply} className="btn--primery btn--s simple-form__submit" type="submit" disabled={locked || disabled}>{actionTitle}</button>}
            {onCancel && <button onClick={onCancel} className="btn--primery btn--s simple-form__submit" type="button" disabled={disabled}>Cancel</button>}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

SectionBlock.propTypes = {
  styleColor: PropTypes.node,
  title: PropTypes.node,
  description: PropTypes.node,
  value: PropTypes.node,
  actionTitle: PropTypes.string,
  onChangeValue: PropTypes.func,
  onMax: PropTypes.func,
  onApply: PropTypes.func,
  onCancel: PropTypes.func,
  locked: PropTypes.bool,
  disabled: PropTypes.bool
};

SectionBlock.defaultProps = {
  styleColor: null,
  title: null,
  description: null,
  value: null,
  actionTitle: null,
  onChangeValue: null,
  onMax: null,
  onApply: null,
  onCancel: null,
  locked: true,
  disabled: true
};

export default SectionBlock;
