import { all } from 'redux-saga/effects';

import depools from './depools';
import favorites from './favorites';

export default function* rootSaga() {
  yield all([
    depools(),
    favorites()
  ]);
}
