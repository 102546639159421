import React from 'react';

import './index.scss';

const Loader = () => (
  <div className="tools-loader">
    <div className="tools-loader__ring">
      <img src="/assets/svg/loader.svg" alt="" />
    </div>
    <div>Loading data...</div>
  </div>
);

export default Loader;
