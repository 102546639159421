import React from 'react';

import PropTypes from 'prop-types';

import './index.scss';

const ToolBar = ({ children }) => (
  <div className="tools">
    <div className="tools__bar">
      {children}
    </div>
  </div>
);

ToolBar.propTypes = {
  children: PropTypes.node
};

ToolBar.defaultProps = {
  children: null
};

export default ToolBar;
