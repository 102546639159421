import BigNumber from 'bignumber.js';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './index.scss';
import { prcf, recommendFixed, tonf } from 'utils/format';

const SectionGrid = ({ children, info }) => {
  const textView = (value) => (value === null || value === undefined ? null : <React.Fragment>{!value ? '-' : value.toLocaleString()}</React.Fragment>);
  const getDeltaHint = (value, type) => ((type === 'ton' && new BigNumber(value).isLessThan(0))
  || (type !== 'ton' && value < 0) ? 'down' : 'up');

  const getDisplayValue = (type, value, ignoreZero) => {
    if (type === 'ton') {
      const fixed = recommendFixed(value);
      const result = tonf(value, { fixed });
      if (ignoreZero && (!value || new BigNumber(value).abs().isZero() || result === '0' || result === '-0'))
        return null;
      return result;
    }
    if (type === 'percent') {
      const result = prcf(value);
      if (ignoreZero && (!value || new BigNumber(value).abs().isZero() || result === '0' || result === '-0'))
        return null;
      return result;
    }
    return textView(value);
  };

  const data = (info ?? []).map((s) => ({
    label: s.label,
    value: getDisplayValue(s.type, s.value, false),
    comment: getDisplayValue(s.type, s.delta, true),
    commentHint: getDeltaHint(s.delta, s.type)
  }));

  return (
    <main className="section-main">
      <div className="information-list grid grid--4">
        {data && data.map((s) => (
          <div key={s.label} className="information-item">
            <div className="information-item__title">{s.label}</div>
            <div className="information-item__val">{s.value}</div>
            {s.comment && <div className={classnames(['dynamic-val', s.commentHint])}>{s.comment}</div>}
          </div>
        ))}
      </div>
      <div className="grid grid--2">
        {children}
      </div>
    </main>
  );
};

SectionGrid.propTypes = {
  children: PropTypes.node,
  info: PropTypes.arrayOf(PropTypes.shape({}))
};

SectionGrid.defaultProps = {
  children: null,
  info: null
};

export default SectionGrid;
