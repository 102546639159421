import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FormCheckBox from 'components/_common/Broxus/ExpandForm/FormCheckBox';
import ExpandForm from 'components/_common/Broxus/ExpandForm';
import { MY_DEPOOL_FILTER_APPLY } from 'constants/actions';

const FilterPanel = () => {
  const dispatch = useDispatch();

  const {
    myPoolsHistory
  } = useSelector((state) => state.config);

  const [poolHistoryLocal, setPoolHistoryLocal] = useState(myPoolsHistory);
  const [count, setCount] = useState(0);
  const counters = [
    () => (myPoolsHistory)
  ];

  useEffect(() => {
    setCount(counters.filter((e) => e()).length);
  }, [myPoolsHistory]);

  const handleApply = () => {
    dispatch({
      type: MY_DEPOOL_FILTER_APPLY,
      myPoolsHistory: poolHistoryLocal
    });
  };

  const handleClear = () => {
    setPoolHistoryLocal(true);
  };

  return (
    <React.Fragment>
      <ExpandForm title="Filter" count={count} onApply={handleApply} onClear={handleClear}>
        <FormCheckBox
          title="Show history"
          isChecked={poolHistoryLocal}
          onClick={(event) => setPoolHistoryLocal(event.target.checked)}
        />
      </ExpandForm>
    </React.Fragment>
  );
};

export default FilterPanel;
