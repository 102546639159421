import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const FavoriteActionContent = ({
  isActive,
  onClick
}) => {
  const id = `favCheckbox-${Math.floor(Math.random() * 1000)}`;

  return (
    <label htmlFor={id} className="star-select">
      <input id={id} defaultChecked={isActive} type="checkbox" onChange={onClick} />
      <span className="star-select__stars">
        <img src="assets/svg/star-no-selected.svg" alt="" />
        <img src="assets/svg/star-selected.svg" alt="" />
      </span>
    </label>
  );
};

FavoriteActionContent.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func
};

FavoriteActionContent.defaultProps = {
  isActive: null,
  onClick: null
};

export default FavoriteActionContent;
