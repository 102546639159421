import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const HeaderAction = ({
  url,
  disabled,
  onClick,
  children
}) => {
  const handleClick = !onClick ? null : (e) => {
    onClick();

    e.preventDefault();
    e.stopPropagation();
    return false;
  };

  return (
    <li>
      <a href={url} onClick={handleClick} className={disabled || !onClick ? null : 'active'} target={onClick ? null : '_blank'} rel="noreferrer">
        {children}
        {!onClick && (
          <React.Fragment>
            &nbsp;
            <img src="/assets/svg/external-link.svg" alt="" />
          </React.Fragment>
        )}
      </a>
    </li>
  );
};

HeaderAction.propTypes = {
  url: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node
};

HeaderAction.defaultProps = {
  url: null,
  disabled: false,
  onClick: null,
  children: null
};

export default HeaderAction;
