import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import history from 'utils/history';

const Header = ({ children }) => {
  const url = '/';
  const handleClick = (e) => {
    history.push(url);

    e.preventDefault();
    e.stopPropagation();
    return false;
  };

  return (
    <header className="header">
      <div className="logo">
        <a href={url} type="button" onClick={handleClick}>
          <img className="logo__desctop" src="/assets/svg/ever-pools-beta.svg" alt="EVER Pool beta" />
          <img className="logo__mob" src="/assets/svg/logo-mob-beta.svg" alt="EVER Pool beta" />
        </a>
      </div>
      <nav className="main-nav">
        <ul>
          {children}
        </ul>
      </nav>
    </header>
  );
};
Header.propTypes = {
  children: PropTypes.node
};

Header.defaultProps = {
  children: null
};

export default Header;
