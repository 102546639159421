import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const Section = forwardRef(({
  title,
  tool,
  info,
  children
}, ref) => (
  <React.Fragment>
    <div ref={ref} className="swap__header swap__header--long swap__header--mob-wrap">
      {info && (
        <div className="swap__header-hgroup">
          {title && <h2 className="swap-title">{title}</h2>}
          {info}
        </div>
      )}
      {!info && (
        <React.Fragment>
          {title && <h2 className="swap-title">{title}</h2>}
        </React.Fragment>
      )}
      {tool && <div className="swap__header-bar">{tool}</div>}
    </div>
    {children}
  </React.Fragment>
));

Section.propTypes = {
  title: PropTypes.node,
  tool: PropTypes.node,
  info: PropTypes.node,
  children: PropTypes.node
};

Section.defaultProps = {
  title: null,
  tool: null,
  info: null,
  children: null
};

export default Section;
