import React from 'react';
import PropTypes from 'prop-types';

import List from 'components/_common/Broxus/List';

import { tonf } from 'utils/format';

const tonFixed = (value) => (((value ?? '')).length < 12 ? 2 : 0);

const meta = [
  { id: 'id', type: 'address', label: 'Depool address' },
  {
    id: 'myWithdrawTxId',
    type: 'address',
    label: 'Tx id',
    onClick: (item) => window.open(`https://everscan.io/transactions/${item.myWithdrawTxId}`, '_blank')
  },
  {
    id: 'myWithdraw', type: 'ton', label: 'Withdrawal, EVER', view: (v) => tonf(v, tonFixed(v))
  },
  {
    id: 'myTotal', type: 'ton', label: 'TVL, EVER', view: (v) => tonf(v, tonFixed(v))
  },
  { id: 'myWithdrawDateUtc', type: 'datetime', label: 'Requested at, UTC' }
];

const Withdrawals = ({ data }) => (
  <List data={data} meta={meta} className="pending-withdrawals-from-participants-table" />
);

Withdrawals.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({}))
};

Withdrawals.defaultProps = {
  data: null
};

export default Withdrawals;
