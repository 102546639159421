import React, { useState } from 'react';
import TableContainer from 'components/_common/Broxus/List/TableContainer';
import Table from 'components/_common/Broxus/List/Table';
import TablePagination from 'components/_common/Broxus/List/TablePagination';
import TableHeader from 'components/_common/Broxus/List/TableHeader';
import ActionContent from 'components/_common/Broxus/List/ActionContent';
import TableRow from 'components/_common/Broxus/List/TableRow';
import Empty from 'components/_common/Broxus/List/Empty';
import PropTypes from 'prop-types';
import { BigNumber } from 'bignumber.js';

import './index.scss';

const descendingComparator = (a, b, orderBy, headCells) => {
  const headCell = headCells.filter((e) => e.id === orderBy)[0];

  if (headCell && headCell.numeric
    && (typeof a[orderBy] === 'string' || typeof b[orderBy] === 'string')
  ) {
    const bigB = new BigNumber((b[orderBy] || null) ?? '0');
    const bigA = new BigNumber((a[orderBy] || null) ?? '0');
    if (bigB.isLessThan(bigA))
      return -1;
    if (bigB.isGreaterThan(bigA))
      return 1;
    return 0;
  }

  if (b[orderBy] < a[orderBy])
    return -1;
  if (b[orderBy] > a[orderBy])
    return 1;
  return 0;
};

const getComparator = (order, orderBy, headCells) => (
  order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy, headCells)
    : (a, b) => -descendingComparator(a, b, orderBy, headCells)
);

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0)
      return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const getHeaderCellType = (meta, index) => {
  if (index === 0)
    return 'key';
  if (meta.action)
    return 'action';
  return meta.type;
};

const List = ({
  meta,
  data,
  showPagination,
  className,
  children,
  sortedBy,
  sortedAsc,
  onSorted
}) => {
  const [order, setOrder] = React.useState(sortedAsc ? 'asc' : 'desc');
  const [orderBy, setOrderBy] = React.useState(sortedBy ?? '');
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(20);

  const metaStartIndex = !meta[0]?.id && !meta[0]?.label && meta[0]?.action ? 1 : 0;
  meta.forEach((e) => {
    if (e.action && !e.content)
      e.content = (<ActionContent icon={e.action} />);
  });

  const headCells = meta.map((m, ind) => {
    if (ind < metaStartIndex)
      return null;

    if (m.id) {
      return {
        id: m.id,
        align: (m.type === 'ton' || m.type === 'numeric' || m.type === 'percent' || m.type === 'datetime')
          ? 'right' : 'left',
        disablePadding: false,
        type: getHeaderCellType(m, ind - metaStartIndex),
        hideable: m.hideable,
        label: m.label,
        numeric: (m.type === 'ton' || m.type === 'numeric' || m.type === 'percent')
      };
    }
    return {
      type: getHeaderCellType(m, ind - metaStartIndex),
      hideable: m.hideable,
      label: m.label
    };
  }).filter((e) => e);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    if (onSorted)
      onSorted({ sortedBy: property, sortedAsc: !isAsc });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  if (!data?.length) {
    return (
      <TableContainer>
        <Table className={className}>
          <TableHeader
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <Empty />
        </Table>
      </TableContainer>
    );
  }

  return (
    <TableContainer>
      <Table className={className}>
        <TableHeader
          headCells={headCells}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        {stableSort(data, getComparator(order, orderBy, headCells))
          .splice(page * rowsPerPage, rowsPerPage)
          .map((row) => <TableRow key={row.id ?? row.action} meta={meta} item={row}>{children}</TableRow>)}
      </Table>
      {showPagination && (
        <TablePagination
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
        />
      )}
    </TableContainer>
  );
};

List.propTypes = {
  meta: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    action: PropTypes.string
  })).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    action: PropTypes.string
  })),
  showPagination: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  sortedBy: PropTypes.string,
  sortedAsc: PropTypes.bool,
  onSorted: PropTypes.func
};

List.defaultProps = {
  data: null,
  showPagination: true,
  className: null,
  children: null,
  sortedBy: null,
  sortedAsc: null,
  onSorted: null
};

export default List;
