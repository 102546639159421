import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const AnaliticsBar = ({
  children
}) => (
  <div className="swap-container swap-graph-sec">
    <div className="swap-rounds">
      {children}
    </div>
  </div>
);

AnaliticsBar.propTypes = {
  children: PropTypes.node
};

AnaliticsBar.defaultProps = {
  children: null
};

export default AnaliticsBar;
