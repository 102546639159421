import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ExpandForm from 'components/_common/Broxus/ExpandForm';
import InlineSearchBar from 'components/_common/Broxus/InlineSearchBar';
import FormPair from 'components/_common/Broxus/ExpandForm/FormPair';
import FormCheckBox from 'components/_common/Broxus/ExpandForm/FormCheckBox';
import { DEPOOL_FILTER_APPLY, DEPOOL_SEARCHING } from 'constants/actions';

const FilterPanel = () => {
  const dispatch = useDispatch();

  const {
    apyFrom,
    apyTo,
    feeFrom,
    feeTo,
    tvlFrom,
    tvlTo,
    stakeholdersFrom,
    stakeholdersTo,
    poolClosed,
    search
  } = useSelector((state) => state.config);

  const [apyFromLocal, setApyFromLocal] = useState(apyFrom);
  const [apyToLocal, setApyToLocal] = useState(apyTo);
  const [feeFromLocal, setFeeFromLocal] = useState(feeFrom);
  const [feeToLocal, setFeeToLocal] = useState(feeTo);
  const [tvlFromLocal, setTvlFromLocal] = useState(tvlFrom);
  const [tvlToLocal, setTvlToLocal] = useState(tvlTo);
  const [stakeholdersFromLocal, setStakeholdersFromLocal] = useState(stakeholdersFrom);
  const [stakeholdersToLocal, setStakeholdersToLocal] = useState(stakeholdersTo);
  const [poolClosedLocal, setPoolClosedLocal] = useState(poolClosed);
  const [count, setCount] = useState(0);
  const counters = [
    () => (apyFrom || apyTo),
    () => (feeFrom || feeTo),
    () => (tvlFrom || tvlTo),
    () => (stakeholdersFrom || stakeholdersTo),
    () => (poolClosed)
  ];

  useEffect(() => {
    setCount(counters.filter((e) => e()).length);
  }, [apyFrom, apyTo, feeFrom, feeTo, tvlFrom, tvlTo, stakeholdersFrom, stakeholdersTo, poolClosed]);

  const handleApply = () => {
    dispatch({
      type: DEPOOL_FILTER_APPLY,
      apyFrom: apyFromLocal ? parseFloat(apyFromLocal) : null,
      apyTo: apyToLocal ? parseFloat(apyToLocal) : null,
      feeFrom: feeFromLocal ? parseFloat(feeFromLocal) : null,
      feeTo: feeToLocal ? parseFloat(feeToLocal) : null,
      tvlFrom: tvlFromLocal ? parseFloat(tvlFromLocal) : null,
      tvlTo: tvlToLocal ? parseFloat(tvlToLocal) : null,
      stakeholdersFrom: stakeholdersFromLocal ? parseFloat(stakeholdersFromLocal) : null,
      stakeholdersTo: stakeholdersToLocal ? parseFloat(stakeholdersToLocal) : null,
      poolClosed: poolClosedLocal
    });
  };

  const handleClear = () => {
    setApyFromLocal('');
    setApyToLocal('');
    setFeeFromLocal('');
    setFeeToLocal('');
    setTvlFromLocal('');
    setTvlToLocal('');
    setStakeholdersFromLocal('');
    setStakeholdersToLocal('');
    setPoolClosedLocal(false);
  };

  const handleSearchChange = (event) => {
    dispatch({ type: DEPOOL_SEARCHING, search: event.target.value });
  };

  return (
    <React.Fragment>
      <InlineSearchBar search={search} onSearch={handleSearchChange} />
      <ExpandForm title="Filter" count={count} onApply={handleApply} onClear={handleClear}>
        <FormPair
          title="APY"
          hint="percent"
          from={apyFromLocal}
          to={apyToLocal}
          onChangeFrom={(event) => setApyFromLocal(event.target.value)}
          onChangeTo={(event) => setApyToLocal(event.target.value)}
        />
        <FormPair
          title="Fee"
          hint="percent"
          from={feeFromLocal}
          to={feeToLocal}
          onChangeFrom={(event) => setFeeFromLocal(event.target.value)}
          onChangeTo={(event) => setFeeToLocal(event.target.value)}
        />
        <FormPair
          title="TVL"
          hint="ever"
          from={tvlFromLocal}
          to={tvlToLocal}
          onChangeFrom={(event) => setTvlFromLocal(event.target.value)}
          onChangeTo={(event) => setTvlToLocal(event.target.value)}
        />
        <FormPair
          title="Stakeholders"
          hint="number"
          from={stakeholdersFromLocal}
          to={stakeholdersToLocal}
          onChangeFrom={(event) => setStakeholdersFromLocal(event.target.value)}
          onChangeTo={(event) => setStakeholdersToLocal(event.target.value)}
        />
        <FormCheckBox
          title="Open depools only"
          isChecked={poolClosedLocal}
          onClick={(event) => setPoolClosedLocal(event.target.checked)}
        />
      </ExpandForm>
    </React.Fragment>
  );
};

export default FilterPanel;
