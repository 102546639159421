import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const FormSelector = ({
  title,
  value,
  onSelect,
  data
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filteredItems, setFilteredItems] = useState(Object.keys(data ?? {}));
  const [inputValue, setInputValue] = useState(!value ? '' : data[value]);

  const handleDropClick = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (key) => {
    onSelect(key);
    setIsOpen(false);
    setFilteredItems(Object.keys(data));
    setInputValue(!key ? '' : data[key]);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    if (!event.target.value) {
      setFilteredItems(Object.keys(data));
      return;
    }

    const filteredValues = Object.values(data)
      .filter((e) => e.toLowerCase().includes(event.target.value.toLowerCase()));
    const filteredKeys = Object.keys(data).filter((e) => filteredValues.includes(data[e]));
    setFilteredItems(filteredKeys);
  };

  return (
    <div className="filter-pair">
      <div className="filter-pair__title">{title}</div>
      <div className={classnames(['filter-drop', isOpen ? 'open' : null])}>
        <div className="filter-drop__main">
          <input type="text" value={inputValue} onChange={handleInputChange} className="filter-pair__input" placeholder="Select..." />
          <span className="filter-drop__arrow" onClick={handleDropClick}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 14.5L7 9.5H17L12 14.5Z" fill="white" />
            </svg>
          </span>
          <div className="filter-drop__hide">
            <div
              className={classnames(['filter-drop-item', !value ? 'active' : null])}
              onClick={() => handleItemClick('')}
            >
              &lt; Empty &gt;
            </div>
            {filteredItems.map((key) => (
              <div
                key={key}
                className={classnames(['filter-drop-item', key === value ? 'active' : null])}
                onClick={() => handleItemClick(key)}
              >
                {data[key]}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

FormSelector.propTypes = {
  title: PropTypes.node,
  value: PropTypes.node,
  onSelect: PropTypes.func,
  data: PropTypes.shape({})
};

FormSelector.defaultProps = {
  title: null,
  value: null,
  onSelect: null,
  data: null
};

export default FormSelector;
