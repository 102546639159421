import React from 'react';

import PropTypes from 'prop-types';
import classnames from 'classnames';

import './index.scss';

const ToolExit = ({ onClick }) => (
  <React.Fragment>
    <button type="button" className="btn tools-exit" title="Exit" onClick={onClick}>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M18.4753 18.2903H19.295H20.1146V21.5162V23.9355H15.1966L15.1967 27L13.0492 26.2799L8.11633 24.662C7.4459 24.433 7 24.2782 7 24.2782V7H8.63938C8.66196 7 8.68378 7.00459 8.70558 7.00919C8.72248 7.01275 8.73936 7.0163 8.75659 7.01772C8.76929 7.01605 8.78125 7.01267 8.79315 7.00931C8.80968 7.00464 8.8261 7 8.84424 7H17.6556H20.1146V11.8387H19.295H18.4753L18.4754 8.61267L17.6556 8.61281H13.8376H11.918L15.1966 9.41936V22.3226H18.4753V21.5162V18.2903ZM23.153 11.2686L27 15.0644C27 15.0644 26.7522 15.3194 26.4318 15.6346L23.153 18.8605L21.7541 20.2257L21.7539 15.8709H17.6556V15.0645V14.2581H21.7539L21.7541 9.90301L23.153 11.2686Z" />
      </svg>
    </button>
    <button type="button" className={classnames(['btn', 'tools-context'])} title="Exit" onClick={onClick}>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="16" cy="16" r="2" fill="#C5E4F3" />
        <circle cx="8" cy="16" r="2" fill="#C5E4F3" />
        <circle cx="24" cy="16" r="2" fill="#C5E4F3" />
      </svg>
    </button>
  </React.Fragment>
);

ToolExit.propTypes = {
  onClick: PropTypes.func
};

ToolExit.defaultProps = {
  onClick: null
};

export default ToolExit;
