import React, { useRef, useState, useEffect } from 'react';
import { Line, Doughnut, Bar } from 'react-chartjs-2';

import PropTypes from 'prop-types';

const doughnutChartOptions = {
  animation: {
    duration: 0
  },
  plugins: {
    legend: {
      display: false
    },
    tooltips: {
      enabled: false
    }
  },
  bezierCurve: false
};

const chartOptions = {
  animation: {
    duration: 0
  },
  plugins: {
    legend: {
      display: false
    },
    tooltips: {
      enabled: false
    }
  },
  scales: {
    y: {
      type: 'linear',
      display: true,
      position: 'right',
      grid: {
        display: false
      }
    }
  },
  bezierCurve: false
};

const Chart = ({
  labels,
  data,
  colors,
  type
}) => {
  if (!labels?.length || !data?.length)
    return null;

  const divRef = useRef();
  const [height, setHeight] = useState();
  const [width, setWidth] = useState();

  useEffect(() => {
    if (divRef.current) {
      const rect = divRef.current.getBoundingClientRect();
      setHeight(rect.height - 10);
      setWidth(rect.width - 10);
    }
  }, [divRef]);

  if (type === 'Doughnut') {
    if (data.filter((e) => !e).length === data.length) {
      const doughnutChartDataEmpty = () => (
        {
          labels: [''],
          datasets: [
            {
              fill: true,
              borderWidth: 0,
              pointRadius: 0,
              backgroundColor: '#2b314e',
              data: [0.00001]
            }
          ]
        });

      return (
        <div ref={divRef} style={{ height: '100%', width: 'calc(100% - 8px)' }}>
          {height && (
            <Doughnut
              data={doughnutChartDataEmpty}
              options={doughnutChartOptions}
              height={height}
              width={width}
              redraw={false}
            />
          )}
        </div>
      );
    }

    const doughnutChartData = () => (
      {
        labels,
        datasets: [
          {
            fill: true,
            borderWidth: 0,
            pointRadius: 0,
            backgroundColor: [...colors],
            data
          }
        ]
      });

    return (
      <div ref={divRef} style={{ height: '100%', width: 'calc(100% - 8px)' }}>
        {height && (
          <Doughnut
            data={doughnutChartData}
            options={doughnutChartOptions}
            height={height}
            width={width}
            redraw={false}
          />
        )}
      </div>
    );
  }
  if (type === 'Bar') {
    const barChartData = (canvas) => {
      const ctx = canvas.getContext('2d');
      let gradient = null;
      if (ctx) {
        gradient = ctx.createLinearGradient(0, 0, 0, 400);
        gradient.addColorStop(0, 'rgba(197, 228, 243, 1)');
        gradient.addColorStop(0.2, 'rgba(197, 228, 243, 0.8)');
        gradient.addColorStop(0.4, 'rgba(197, 228, 243, 0.4)');
        gradient.addColorStop(0.7, 'rgba(197, 228, 243, 0.05)');
        gradient.addColorStop(1, 'rgba(197, 228, 243, 0.01)');
      }

      return {
        labels,
        datasets: [
          {
            fill: true,
            borderWidth: 0,
            pointRadius: 0,
            backgroundColor: gradient,
            data
          }
        ]
      };
    };

    return (
      <div ref={divRef} style={{ height: '100%', width: 'calc(100% - 8px)' }}>
        {height && (
          <Bar
            data={barChartData}
            options={chartOptions}
            height={height}
            width={width}
            redraw={false}
          />
        )}
      </div>
    );
  }

  const lineChartData = (canvas) => {
    const ctx = canvas.getContext('2d');
    let gradient = null;
    if (ctx) {
      gradient = ctx.createLinearGradient(0, 0, 0, 400);
      gradient.addColorStop(0, 'rgba(197, 228, 243, 0.2)');
      gradient.addColorStop(0.3, 'rgba(197, 228, 243, 0.1)');
      gradient.addColorStop(0.5, 'rgba(197, 228, 243, 0.01)');
      gradient.addColorStop(1, 'rgba(197, 228, 243, 0)');
    }

    return {
      labels,
      datasets: [
        {
          fill: true,
          borderColor: '#C5E4F3',
          borderWidth: 1,
          pointRadius: 0,
          backgroundColor: gradient,
          data
        }
      ]
    };
  };

  return (
    <div ref={divRef} style={{ height: '100%', width: 'calc(100% - 8px)' }}>
      {height && (
        <Line
          data={lineChartData}
          options={chartOptions}
          height={height}
          width={width}
          redraw={false}
        />
      )}
    </div>
  );
};

Chart.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(PropTypes.number),
  colors: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.string
};

Chart.defaultProps = {
  labels: null,
  data: null,
  colors: null,
  type: null
};

export default Chart;
