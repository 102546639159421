import BigNumber from 'bignumber.js';

const getChartInterval = (dailyValues) => {
  if (Object.keys(dailyValues).length > 500)
    return 'monthly';
  if (Object.keys(dailyValues).length > 20)
    return 'weekly';
  return 'daily';
};

const getChartData = ({ dailyValues, aggregate, interval }) => {
  if (!dailyValues || Object.keys(dailyValues).length === 0)
    return { labels: [], data: [] };

  const chartInterval = interval ?? getChartInterval(dailyValues);
  const dateByDay = (d) => new Date(Date.parse(`${d}T00:00:00+00:00`));
  const dayByDate = (d) => d.toISOString().substring(0, 10);
  const minDay = dayByDate(new Date(Math.min(...Object.keys(dailyValues).map((k) => dateByDay(k).getTime()))));
  const maxDay = dayByDate(new Date(Math.max(...Object.keys(dailyValues).map((k) => dateByDay(k).getTime()))));
  const labels = [];
  const data = [];
  let day = minDay;
  const weeklyMulti = 1000 * 3600 * 24 * 7;
  const getLabel = (d) => {
    if (chartInterval === 'weekly') {
      return dayByDate(new Date(Math.floor((dateByDay(d).getTime() - dateByDay(minDay).getTime()) / weeklyMulti)
        * weeklyMulti + dateByDay(minDay).getTime()));
    }
    if (chartInterval === 'monthly')
      return d.substring(0, 7);
    return d;
  };
  while (dateByDay(day).getTime() <= dateByDay(maxDay).getTime()) {
    const label = getLabel(day);
    const value = dailyValues[day];
    if (labels.includes(label))
      data.splice(data.length - 1, 1, aggregate(data[data.length - 1], value));
    else {
      labels.push(label);
      data.push(value);
    }
    day = dayByDate(new Date(dateByDay(day).getTime() + 1000 * 3600 * 24));
  }
  return { labels, data };
};

const getMyStakesAmounts = (stakes) => {
  const r = stakes.reduce((a, s) => {
    if (!s.dateUtc)
      return a;
    const res = a;
    if (s.withdrawed) {
      res.withdrawed = res.withdrawed.plus(s.withdrawed);
      res.ordered = res.ordered.plus(s.ordered);
    }
    if (s.stake) {
      if (res.withdrawed.lte(0)) {
        res.items.push({
          day: s.dateUtc.substring(0, 10),
          tvl: new BigNumber(s.stake ?? 0)
        });
      }
      else {
        if (res.withdrawed.minus(s.fee ?? 0).lt(s.stake ?? 0)) {
          const stake = new BigNumber(s.stake ?? 0).plus(s.fee ?? 0).minus(res.withdrawed);
          if (stake.gt('1000000000')) {
            res.items.push({
              day: s.dateUtc.substring(0, 10),
              tvl: stake
            });
          }
        }
        res.withdrawed = res.withdrawed.minus(s.stake).minus(s.fee ?? 0);
        if (res.withdrawed.lt(1))
          res.withdrawed = new BigNumber(0);
      }
    }
    return res;
  }, {
    items: [],
    withdrawed: new BigNumber(0),
    ordered: new BigNumber(0)
  });
  return r.items;
};

const statistics = {
  normalize: ({ overview }) => {
    if (!overview)
      return overview;
    const result = [];
    const smooth = 0.9;
    overview.forEach((item, index) => {
      if (index === overview.length - 1)
        result.push(item);
      else if (index === overview.length - 2) {
        result.push({
          date: item.date,
          apy: item.apy,
          tvl: new BigNumber(item.tvl).plus(overview[index + 1].tvl).toString()
        });
      }
      else if (new BigNumber(item.tvl).multipliedBy(smooth).isGreaterThan(overview[index + 1].tvl)
        && new BigNumber(item.tvl).multipliedBy(smooth).isGreaterThan(overview[index + 2].tvl)) {
        result.push({
          date: item.date,
          apy: item.apy,
          tvl: item.tvl
        });
      }
      else if (new BigNumber(overview[index + 1].tvl).multipliedBy(smooth).isGreaterThan(item.tvl)
        && new BigNumber(overview[index + 1].tvl).multipliedBy(smooth).isGreaterThan(overview[index + 2].tvl)) {
        result.push({
          date: item.date,
          apy: item.apy,
          tvl: new BigNumber(overview[index + 1].tvl).dividedBy(2).plus(item.tvl).toString()
        });
      }
      else {
        result.push({
          date: item.date,
          apy: item.apy,
          tvl: new BigNumber(item.tvl).plus(overview[index + 1].tvl).toString()
        });
      }
    });
    return result;
  },
  getStakesTotal: ({ depools, prop }) => (depools ?? [])
    .reduce((acc, e) => acc.plus(e[prop ?? 'stake']), new BigNumber(0)).toString(),
  getTvlLast: ({ overview }) => {
    if (!overview)
      return overview;
    const normalized = statistics.normalize({ overview });
    if (normalized.length === 1)
      return normalized[0]?.tvl;
    return normalized[1]?.tvl;
  },
  getStakesDelta: ({ depools }) => {
    if (!depools?.length)
      return null;
    const today = new Date().toISOString().substring(0, 10);
    const yesterday = new Date(new Date(`${today}T00:00:00+00:00`).getTime() - 1000 * 3600 * 24).toISOString().substring(0, 10);

    return (depools ?? []).filter((d) => d.myRounds)
      .flatMap((d) => Object.values(d.myRounds).flatMap((r) => ([]
        .concat(!r.dateUtc ? []
          : [{
            day: r.dateUtc.substring(0, 10),
            tvl: new BigNumber(r.reward ?? 0).minus(r.withdrawed ?? 0)
          }])
        .concat(!r.stakes ? [] : getMyStakesAmounts(r.stakes)))))
      .filter((e) => e.day >= yesterday)
      .reduce((a, e) => a.plus(e.tvl), new BigNumber(0))
      .toString();
  },
  getStakeDelta: ({ myRounds }) => {
    if (!myRounds)
      return null;
    const today = new Date().toISOString().substring(0, 10);
    const yesterday = new Date(new Date(`${today}T00:00:00+00:00`).getTime() - 1000 * 3600 * 24).toISOString().substring(0, 10);

    return Object.values(myRounds)
      .flatMap((r) => ([]
        .concat(!r.dateUtc ? []
          : [{
            day: r.dateUtc.substring(0, 10),
            tvl: new BigNumber(r.reward ?? 0).minus(r.withdrawed ?? 0)
          }])
        .concat(!r.stakes ? [] : getMyStakesAmounts(r.stakes))))
      .filter((e) => e.day >= yesterday)
      .reduce((a, e) => a.plus(e.tvl), new BigNumber(0))
      .toString();
  },
  getParticipantsTotal: ({ depools }) => (depools ?? []).filter((e) => !e.poolClosed)
    .reduce((acc, e) => acc + (e.participantQty ?? e.participants?.length ?? 0), 0),
  getRewardsTotal: ({ depools }) => (depools ?? []).filter((d) => d.myRounds).map((d) => (
    Object.values(d.myRounds).reduce((acc, r) => acc.plus(r.reward ?? '0'), new BigNumber('0'))
  )).reduce((a, r) => a.plus(r), new BigNumber('0')).toString(),
  getDepoolReward: ({ myRounds }) => Object.values(myRounds ?? {}).filter((r) => r.reward)
    .reduce((acc, r) => acc.plus(r.reward ?? '0'), new BigNumber('0')).toString(),
  getRewardsDelta: ({ depools }) => {
    if (!depools?.length)
      return null;
    const today = new Date().toISOString().substring(0, 10);
    const yesterday = new Date(new Date(`${today}T00:00:00+00:00`).getTime() - 1000 * 3600 * 24).toISOString().substring(0, 10);
    const rewards = (depools ?? []).filter((d) => d.myRounds)
      .flatMap((d) => Object.values(d.myRounds).filter((r) => r.reward).map((r) => ({
        day: r.dateUtc.substring(0, 10),
        reward: r.reward
      })))
      .filter((e) => e.day >= yesterday);
    const rewardYesterday = rewards.filter((e) => e.day === yesterday)
      .reduce((a, e) => a.plus(e.reward), new BigNumber(0));
    const rewardToday = rewards.filter((e) => e.day === today)
      .reduce((a, e) => a.plus(e.reward), new BigNumber(0));
    return rewardToday.minus(rewardYesterday).toString();
  },
  getDepoolRewardDelta: ({ myRounds }) => {
    if (!myRounds)
      return null;
    const today = new Date().toISOString().substring(0, 10);
    const yesterday = new Date(new Date(`${today}T00:00:00+00:00`).getTime() - 1000 * 3600 * 24).toISOString().substring(0, 10);
    const rewards = Object.values(myRounds)
      .filter((r) => r.reward).map((r) => ({
        day: r.dateUtc.substring(0, 10),
        reward: r.reward
      }))
      .filter((e) => e.day >= yesterday);
    const rewardYesterday = rewards.filter((e) => e.day === yesterday)
      .reduce((a, e) => a.plus(e.reward), new BigNumber(0));
    const rewardToday = rewards.filter((e) => e.day === today)
      .reduce((a, e) => a.plus(e.reward), new BigNumber(0));
    return rewardToday.minus(rewardYesterday).toString();
  },
  getApyAverage: ({ depools }) => {
    const items = (depools ?? [])
      .filter((d) => !d.poolClosed && d.apyPercent && d.apyPercent > 0).map((r) => Math.log10(r.apyPercent / 100));
    const count = parseFloat(items.length);
    const tapy = items.reduce((a, r) => a + r, 0.0);
    if (count === 0)
      return 0.0;
    return parseFloat((10 ** (tapy / count)).toFixed(5));
  },
  getMyApyAverage: ({ depools }) => {
    const items = (depools ?? []).filter((d) => d.myRounds).flatMap((d) => (
      Object.values(d.myRounds).filter((r) => r?.apy).map((r) => Math.log10(r.apy))
    ));
    if (items.length === 0)
      return 0.0;
    const count = parseFloat(items.length);
    const tapy = items.reduce((a, r) => a + r, 0.0);
    return parseFloat((10 ** (tapy / count)).toFixed(5));
  },
  getDepoolMyApyAverage: ({ myRounds }) => {
    if (!myRounds)
      return null;
    const items = Object.values(myRounds ?? {})
      .filter((r) => r?.apy).map((r) => Math.log10(r.apy));
    if (items.length === 0)
      return 0.0;
    const count = parseFloat(items.length);
    const tapy = items.reduce((a, r) => a + r, 0.0);
    return parseFloat((10 ** (tapy / count)).toFixed(5));
  },
  getRewardsChart: ({ depools }) => {
    if (!depools?.length)
      return null;
    const aggregate = (a, v) => new BigNumber(a ?? 0).plus(v ?? 0);
    const dailyValues = (depools ?? []).filter((d) => d.electorRewards)
      .flatMap((d) => Object.values(d.electorRewards).filter((r) => r.dateUtc).map((r) => ({
        day: r.dateUtc.substring(0, 10),
        reward: r.reward
      })))
      .reduce((a, r) => ({ ...a, [r.day]: aggregate(a[r.day], r.reward) }), {});
    const res = getChartData({ dailyValues, aggregate });
    return {
      labels: res?.labels,
      data: !res?.data ? null
        : res.data.map((v) => parseFloat(parseFloat(new BigNumber(v ?? 0).dividedBy(10 ** 9).toString()).toFixed(5)))
    };
  },
  getMyRewardsChart: ({ depools }) => {
    if (!depools?.length)
      return null;
    const aggregate = (a, v) => new BigNumber(a ?? 0).plus(v ?? 0);
    const dailyValues = (depools ?? []).filter((d) => d.myRounds)
      .flatMap((d) => Object.values(d.myRounds).filter((r) => r.dateUtc).map((r) => ({
        day: r.dateUtc.substring(0, 10),
        reward: r.reward
      })))
      .reduce((a, r) => ({ ...a, [r.day]: aggregate(a[r.day], r.reward) }), {});
    const res = getChartData({ dailyValues, aggregate });
    return {
      labels: res?.labels,
      data: !res?.data ? null
        : res.data.map((v) => parseFloat(parseFloat(new BigNumber(v ?? 0).dividedBy(10 ** 9).toString()).toFixed(5)))
    };
  },
  getStakesDailyValues: ({ depools }) => {
    if (!depools?.length)
      return null;
    const aggregate = (a, v) => {
      const result = { ...(a ?? {}) };
      if (v) {
        Object.keys(v).forEach((proxy) => {
          result[proxy] = v[proxy].isGreaterThan((a ?? {})[proxy] ?? 0) ? v[proxy] : a[proxy];
        });
      }
      return result;
    };
    const dailyValues = (depools ?? []).filter((d) => d.electorRewards)
      .flatMap((d) => Object.values(d.electorRewards)
        .filter((r) => !new BigNumber(r.stake ?? 0).isEqualTo(0))
        .map((r) => ({
          day: r.dateUtc.substring(0, 10),
          tvl: { [r.proxy]: new BigNumber(r.stake ?? 0) }
        })))
      .reduce((a, r) => ({ ...a, [r.day]: aggregate(a[r.day], r.tvl) }), {});
    Object.keys(dailyValues).forEach((key) => {
      dailyValues[key] = Object.values(dailyValues[key]).reduce((a, v) => a.plus(v), new BigNumber(0)).toString();
    });
    return dailyValues;
  },
  sumStakesSet: (v1, v2) => {
    const res = v1;
    Object.keys(v2).forEach((key) => {
      res[key] = new BigNumber(v2[key]).plus(res[key] ?? 0).toString();
    });
    return res;
  },
  getStoredStakesChart: ({ values, lastStake }) => {
    const aggregate = (a, v) => (new BigNumber(a ?? 0).isGreaterThan(v ?? 0) ? a : v);
    const res = getChartData({ dailyValues: values, aggregate });
    if (res.data?.length && lastStake) {
      const prevStake = res.data[res.data.length - 2];
      if (new BigNumber(prevStake).multipliedBy(1.1).isGreaterThan(lastStake))
        res.data.splice(res.data.length - 1, 1, lastStake);
      else
        res.data.splice(res.data.length - 1, 1, prevStake);
    }
    return {
      labels: res?.labels,
      data: !res?.data ? null
        : res.data.map((v) => parseFloat(new BigNumber(v).dividedBy(10 ** 9).toFixed(0)))
    };
  },
  getOverviewStakesChart: ({ overview }) => {
    const aggregate = (a, v) => (new BigNumber(a ?? 0).isGreaterThan(v ?? 0) ? a : v);
    const dailyValues = (statistics.normalize({ overview }) ?? []).filter((e) => e.apy)
      .map((e) => ({
        day: e.date.substring(0, 10),
        value: !e.tvl ? null : new BigNumber(e.tvl)
      }))
      .filter((e) => e.day && e.value)
      .reduce((a, r) => ({ ...a, [r.day]: aggregate(a[r.day], r.value) }), {});
    const res = getChartData({ dailyValues, aggregate });
    return {
      labels: res?.labels,
      data: !res?.data ? null
        : res.data.map((v) => parseFloat(new BigNumber(v).dividedBy(10 ** 9).toFixed(0)))
    };
  },
  getStakesChart: ({ depools }) => {
    const aggregate = (a, v) => new BigNumber(0).plus(a ?? 0).plus(v ?? 0).toString();
    const dailyValues = statistics.getStakesDailyValues({ depools });
    const res = getChartData({ dailyValues, aggregate });
    return {
      labels: res?.labels,
      data: !res?.data ? null
        : res.data.map((v) => parseFloat(new BigNumber(v).dividedBy(10 ** 9).toFixed(0)))
    };
  },
  getMyStakesChart: ({ depools }) => {
    if (!depools?.length)
      return null;
    const aggregate = (a, v) => new BigNumber(a ?? 0).plus(v ?? 0);
    const dailyValues = (depools ?? []).filter((d) => d.myRounds)
      .flatMap((d) => Object.values(d.myRounds).flatMap((r) => ([]
        .concat(!r.dateUtc ? []
          : [{
            day: r.dateUtc.substring(0, 10),
            tvl: new BigNumber(r.reward ?? 0)
              .minus(r.withdrawed ?? 0)
          }])
        .concat(!r.stakes ? [] : getMyStakesAmounts(r.stakes))
      ))).filter((e) => !e.tvl.isZero())
      .reduce((a, r) => ({ ...a, [r.day]: aggregate(a[r.day], r.tvl) }), {});
    const res = getChartData({ dailyValues, aggregate });
    if (res.data) {
      for (let i = 0; i < res.data.length; i++) {
        if (i > 0)
          res.data.splice(i, 1, aggregate(res.data[i], res.data[i - 1]));
      }
    }
    return {
      labels: res?.labels,
      data: !res?.data ? null
        : res.data.map((v) => {
          const value = parseFloat(parseFloat(new BigNumber(v ?? 0).dividedBy(10 ** 9).toString()).toFixed(5));
          if (value < 0)
            return 0;
          return value;
        })
    };
  },
  getApysDailyValues: ({ depools }) => {
    if (!depools?.length)
      return null;
    const aggregate = (a, v) => ({ m: (a?.m ?? 0) + (v?.m ?? 0), p: (a?.p ?? 0) + (v?.p ?? 0) });
    const dailyValues = (depools ?? []).filter((d) => d.electorRewards)
      .flatMap((d) => Object.values(d.electorRewards).filter((r) => r.dateUtc && (r.apy ?? 0) > 0).map((r) => ({
        day: r.dateUtc.substring(0, 10),
        value: ({ m: Math.log10(r.apy), p: 1 })
      })))
      .filter((e) => e.day && e.value)
      .reduce((a, r) => ({ ...a, [r.day]: aggregate(a[r.day], r.value) }), {});
    return dailyValues;
  },
  sumApysSet: (v1, v2) => {
    const res = v1;
    Object.keys(v2).forEach((key) => {
      res[key] = ({ m: (v2[key]?.m ?? 0) + (res[key]?.m ?? 0), p: (v2[key]?.p ?? 0) + (res[key]?.p ?? 0) });
    });
    return res;
  },
  getStoredApysChart: ({ values }) => {
    const aggregate = (a, v) => ({ m: (a?.m ?? 0) + (v?.m ?? 0), p: (a?.p ?? 0) + (v?.p ?? 0) });
    const res = getChartData({ dailyValues: values, aggregate });
    return {
      labels: res?.labels,
      data: !res?.data ? null
        : res.data.map((v) => ((v?.m ?? 0) === 0 ? 0
          : parseFloat((parseFloat(10 ** ((v?.m ?? 0) / (v?.p ?? 1))) * 100).toFixed(2))))
    };
  },
  getOverviewApysChart: ({ overview }) => {
    const aggregate = (a, v) => ({ m: (a?.m ?? 0) + (v?.m ?? 0), p: (a?.p ?? 0) + (v?.p ?? 0) });
    const dailyValues = (overview ?? []).filter((e) => e.apy)
      .map((e) => ({
        day: e.date.substring(0, 10),
        value: ({ m: Math.log10(e.apy), p: 1 })
      }))
      .filter((e) => e.day && e.value)
      .reduce((a, r) => ({ ...a, [r.day]: aggregate(a[r.day], r.value) }), {});
    const res = getChartData({ dailyValues, aggregate });
    return {
      labels: res?.labels,
      data: !res?.data ? null
        : res.data.map((v) => ((v?.m ?? 0) === 0 ? 0
          : parseFloat((parseFloat(10 ** ((v?.m ?? 0) / (v?.p ?? 1))) * 100).toFixed(2))))
    };
  },
  getApyChart: ({ depools }) => {
    if (!depools?.length)
      return null;
    const aggregate = (a, v) => ({ m: (a?.m ?? 0) + (v?.m ?? 0), p: (a?.p ?? 0) + (v?.p ?? 0) });
    const dailyValues = (depools ?? []).filter((d) => d.electorRewards)
      .flatMap((d) => Object.values(d.electorRewards).filter((r) => r.dateUtc && (r.apy ?? 0) > 0).map((r) => ({
        day: r.dateUtc.substring(0, 10),
        value: ({ m: Math.log10(r.apy), p: 1 })
      })))
      .filter((e) => e.day && e.value)
      .reduce((a, r) => ({ ...a, [r.day]: aggregate(a[r.day], r.value) }), {});
    const res = getChartData({ dailyValues, aggregate });
    return {
      labels: res?.labels,
      data: !res?.data ? null
        : res.data.map((v) => ((v?.m ?? 0) === 0 ? 0
          : parseFloat((parseFloat(10 ** ((v?.m ?? 0) / (v?.p ?? 1))) * 100).toFixed(2))))
    };
  },
  getMyApyChart: ({ depools }) => {
    if (!depools?.length)
      return null;
    const aggregate = (a, v) => ({ m: (a?.m ?? 0) + (v?.m ?? 0), p: (a?.p ?? 0) + (v?.p ?? 0) });
    const dailyValues = (depools ?? []).filter((d) => d.myRounds)
      .flatMap((d) => Object.values(d.myRounds).filter((r) => r.dateUtc).map((r) => ({
        day: r.dateUtc.substring(0, 10),
        value: !r.apy ? null : ({ m: Math.log10(r.apy), p: 1 })
      })))
      .filter((e) => e.day && e.value)
      .reduce((a, r) => ({ ...a, [r.day]: aggregate(a[r.day], r.value) }), {});
    const res = getChartData({ dailyValues, aggregate });
    return {
      labels: res?.labels,
      data: !res?.data ? null
        : res.data.map((v) => ((v?.m ?? 0) === 0 ? 0
          : parseFloat((parseFloat(10 ** ((v?.m ?? 0) / (v?.p ?? 1))) * 100).toFixed(2))))
    };
  }
};

export default statistics;
