import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Toggle = ({
  isActive,
  onChange,
  images
}) => {
  const handleInactiveClick = () => {
    if (!onChange)
      return;
    onChange(false);
  };

  const handleActiveClick = () => {
    if (!onChange)
      return;
    onChange(true);
  };

  return (
    <div className="swap-layaut-bar">
      <button onClick={handleInactiveClick} type="button" className={classnames(['btn swap-layaut-tool', !isActive ? 'active' : null])}>
        <img src={images[0]} alt="" />
      </button>
      <button onClick={handleActiveClick} type="button" className={classnames(['btn swap-layaut-tool', isActive ? 'active' : null])}>
        <img src={images[1]} alt="" />
      </button>
    </div>
  );
};

Toggle.propTypes = {
  isActive: PropTypes.bool,
  images: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func
};

Toggle.defaultProps = {
  isActive: null,
  images: null,
  onChange: null
};

export default Toggle;
