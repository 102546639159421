import React from 'react';
import Chart from 'components/_common/Chart';

import PropTypes from 'prop-types';
import { tonf, prcf } from 'utils/format';
import { BigNumber } from 'bignumber.js';

import './index.scss';

const colors = [
  '#EB4361',
  '#3C6CCE',
  '#C5E4F3',
  '#4AB44A',
  '#52556D'
];

const formats = {
  ton: (v) => tonf(v, { fixed: 0 }),
  percent: (v) => prcf(v),
  unknown: () => {}
};

const DistributionRow = ({ meta, item, index }) => (
  <tr>
    {meta.map((m) => (
      <td key={m.label}>
        {m.type === 'color' && (
          <div className="swap-rounds-table-legend">
            <div className="swap-rounds-table-legend__squer" style={{ backgroundColor: colors[index] }} />
            <div>{item[m.id]}</div>
          </div>
        )}
        {m.type !== 'color' && (
          (formats[m.type] ?? formats.unknown)(item[m.id])
        )}
      </td>
    ))}
  </tr>
);

DistributionRow.propTypes = {
  meta: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string
  })).isRequired,
  item: PropTypes.shape({}).isRequired,
  index: PropTypes.number.isRequired
};

const Distribution = ({ meta, data }) => (
  <React.Fragment>
    <div className="swap-rounds-diagramm">
      <Chart
        type="Doughnut"
        labels={data.map((e) => e[meta[0].id])}
        data={data.map((e) => Math.round(new BigNumber(e[meta[1].id]).dividedBy(10 ** 9).toNumber()))}
        colors={colors}
      />
    </div>
    <table className="swap-rounds-table">
      <thead>
        <tr>
          {meta.map((m) => <th key={m.label}>{m.label}</th>)}
        </tr>
      </thead>
      <tbody>
        {data.map((d, ind) => <DistributionRow key={d[meta[0].id]} meta={meta} item={d} index={ind} />)}
      </tbody>
    </table>
  </React.Fragment>
);

Distribution.propTypes = {
  meta: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string
  })).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default Distribution;
