import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import AddressAvatar from 'components/_common/AddressAvatar';
import Section from 'components/_common/Broxus/Section';
import ChartBar from 'components/_common/Broxus/ChartBar';
import Overview from 'components/Overview';
import Depools from 'components/ParticipantPage/Depools';
import BigNumber from 'bignumber.js';

import { addrf } from 'utils/format';

import {
  PARTICIPANT_DEPOOLS_LOAD,
  SET_LOADED
} from 'constants/actions';

import statistics from 'utils/statistics';
import Withdrawals from './Withdrawals';

const getWithdrawingDepools = (data) => (!data ? null : data.filter((e) => (e.myWithdraw ?? '0') !== '0' || !(e.myReinvest ?? true)));
const getStats = (items, all) => [
  {
    label: 'TVL, EVER',
    type: 'ton',
    value: statistics.getStakesTotal({ depools: items, prop: 'myTotal' }),
    delta: statistics.getStakesDelta({ depools: all })
  },
  {
    label: 'Reward, EVER',
    type: 'ton',
    value: statistics.getRewardsTotal({ depools: all }),
    delta: statistics.getRewardsDelta({ depools: all })
  },
  { label: 'Average APY', value: `${(statistics.getMyApyAverage({ depools: all }) * 100).toFixed(1)}%` },
  { label: 'Active depools', value: !items ? 0 : items.filter((e) => new BigNumber(e.myTotal).gt(0)).length }
];

const getMyCharts = (items) => [
  { label: 'TVL', ...statistics.getMyStakesChart({ depools: items }) },
  { label: 'APY', ...statistics.getMyApyChart({ depools: items }) },
  { label: 'Rewards', ...statistics.getMyRewardsChart({ depools: items }) }
];

const ParticipantPage = () => {
  const { participant } = useParams();
  const dispatch = useDispatch();
  const [myDepools, setMyDepools] = useState();
  const [stats, setStats] = useState(getStats([], []));
  const [myCharts, setMyCharts] = useState(getMyCharts([]));
  const [withdrawingDepools, setWithdrawingDepools] = useState(null);

  const {
    depools,
    depoolsLoaded,
    participants,
    myAddress
  } = useSelector((state) => state.config);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (myAddress)
      dispatch({ type: SET_LOADED, loaded: false });
  }, [myAddress]);

  useEffect(() => {
    const participantDepools = (participants[participant] ?? []).map((e) => {
      const depool = depools.filter((d) => d.id === e.id)[0];
      return {
        ...depool,
        ...e
      };
    });

    const participantActiveDepools = participantDepools.filter((e) => e.myTotal);
    setMyDepools(participantActiveDepools);
    setStats(getStats(participantActiveDepools, participantDepools));
    setMyCharts(getMyCharts(participantDepools));
    setWithdrawingDepools(getWithdrawingDepools(participantDepools));

    if (participants && Object.keys(participants).includes(participant))
      dispatch({ type: SET_LOADED, loaded: true });
  }, [depools, participants, depoolsLoaded]);

  useEffect(async () => {
    if (!depoolsLoaded)
      return;
    dispatch({ type: PARTICIPANT_DEPOOLS_LOAD, participant, data: depools });
  }, [participant, depoolsLoaded]);

  const title = (
    <React.Fragment>
      <AddressAvatar address={participant} small />
      &nbsp;
      <a type="button" title={participant} onClick={() => window.open(`https://everscan.io/accounts/${participant}`, '_blank')}>{addrf(participant)}</a>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Section title={title} />
      <Section title="Portfolio Performance">
        <Overview statistics={stats}>
          <ChartBar data={myCharts} />
        </Overview>
      </Section>
      <Section title="Pending withdrawals from participants">
        <Withdrawals data={withdrawingDepools} />
      </Section>
      <Section title="Depools">
        <Depools data={myDepools} />
      </Section>
    </React.Fragment>
  );
};

export default ParticipantPage;
