import React from 'react';
import PropTypes from 'prop-types';

import List from 'components/_common/Broxus/List';
import Manage from 'components/MyPage/Withdrawals/Manage';

import { tonf } from 'utils/format';
import history from 'utils/history';

const tonFixed = (value) => (((value ?? '')).length < 12 ? 2 : 0);

const meta = [
  {
    id: 'id',
    type: 'address',
    label: 'Depool address',
    image: (item) => item.imageUrl,
    title: (item) => item.name,
    onClick: (item) => history.push(`/depools/${item.id}`)
  },
  {
    id: 'myWithdrawTxId',
    type: 'address',
    label: 'Tx id',
    onClick: (item) => window.open(`https://everscan.io/transactions/${item.myWithdrawTxId}`, '_blank')
  },
  {
    id: 'myWithdraw', type: 'ton', label: 'Withdrawal, EVER', view: (v) => tonf(v, tonFixed(v))
  },
  {
    id: 'myTotal', type: 'ton', label: 'TVL, EVER', view: (v) => tonf(v, tonFixed(v))
  },
  { id: 'myWithdrawDateUtc', type: 'datetime', label: 'Requested at, UTC' },
  { label: 'Manage', action: 'manage' }
];

const Details = ({ item, action }) => (
  <React.Fragment>
    {action === 'manage' && <Manage {...item} />}
  </React.Fragment>
);
Details.propTypes = {
  item: PropTypes.shape({}),
  action: PropTypes.string
};
Details.defaultProps = {
  item: null,
  action: null
};

const Withdrawals = ({ data }) => (
  <List data={data} meta={meta} className="pending-withdrawals-from-depools-table">
    <Details />
  </List>
);

Withdrawals.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({}))
};

Withdrawals.defaultProps = {
  data: null
};

export default Withdrawals;
