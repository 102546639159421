import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const PageInfo = ({
  children
}) => (
  <div className="swap-container swap-graph-sec">
    <div className="swap-profile">
      {children}
    </div>
  </div>
);

PageInfo.propTypes = {
  children: PropTypes.node
};

PageInfo.defaultProps = {
  children: null
};

export default PageInfo;
