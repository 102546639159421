import React from 'react';

import PropTypes from 'prop-types';

import './index.scss';

const ToolAction = ({ label, onClick }) => (
  <button type="button" className="btn btn-dark tools-btn" onClick={onClick}>{label}</button>
);

ToolAction.propTypes = {
  label: PropTypes.node,
  onClick: PropTypes.func
};

ToolAction.defaultProps = {
  label: null,
  onClick: null
};

export default ToolAction;
