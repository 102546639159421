import { takeEvery, call, put } from 'redux-saga/effects';
import cache from 'utils/cache';

import {
  FAVORITES_LOAD,
  FAVORITE_STORE,
  SET_FAVORITES,
  SET_FAVORITE
} from 'constants/actions';

import { DATABASE_NAME } from 'constants/cache';
import tonWalletExt from 'utils/tonWalletExt';

async function getDbName() {
  let interaction = null;
  try {
    interaction = await tonWalletExt.getAccountInteraction();
  }
  catch {
    interaction = null;
  }

  if (!interaction)
    return DATABASE_NAME;

  return `${DATABASE_NAME}${interaction?.address}`;
}

function* loadFavorites() {
  const dbName = yield call(getDbName);
  try {
    yield call(cache.init, dbName);
  }
  catch (e) {
    console.log('Error to init cache', e);
    return;
  }

  let data = [];
  try {
    data = yield call(cache.getAllKeys, dbName);
  }
  catch (e) {
    console.log('Error to get all keys', e);
    return;
  }

  yield put({ type: SET_FAVORITES, favorites: data });
}

function* storeFavorite({ id, isFavorite }) {
  const dbName = yield call(getDbName);
  yield put({ type: SET_FAVORITE, id, isFavorite });

  if (isFavorite) {
    try {
      yield call(cache.put, dbName, { updated_at: new Date().toISOString() }, id);
    }
    catch (e) {
      console.log('Error to put key', e);
    }
  }
  else {
    try {
      yield call(cache.delete, dbName, id);
    }
    catch (e) {
      console.log('Error to delete key', e);
    }
  }
}

export default function* favorites() {
  yield takeEvery(FAVORITES_LOAD, loadFavorites);
  yield takeEvery(FAVORITE_STORE, storeFavorite);
}
