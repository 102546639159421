import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Overview from 'components/Overview';
import Section from 'components/_common/Broxus/Section';
import ChartBar from 'components/_common/Broxus/ChartBar';
import StakeDistribution from 'components/DepoolPage/StakeDistribution';
import Stakeholders from 'components/DepoolPage/Stakeholders';
import Profile from 'components/DepoolPage/Profile';
import ToolBack from 'components/_common/Broxus/ToolBack';

import PropTypes from 'prop-types';
import { BigNumber } from 'bignumber.js';
import { DEPOOL_LOAD, PARTICIPANT_DEPOOLS_LOAD, SET_LOADED } from 'constants/actions';
import { currentRounds } from 'constants/depools';

import { getSearchString } from 'utils/url';
import MyStakeDistribution from './MyStakeDistribution';
import Rounds from './Rounds';

const EmptyAddress = '0:0000000000000000000000000000000000000000000000000000000000000000';

const getTotalStats = (stake, current, inactive, poolClosed) => [
  {
    label: 'TVL, EVER',
    type: 'ton',
    value: poolClosed ? null : new BigNumber(current?.ordinaryStake ?? '0')
      .plus(inactive?.ordinaryStake ?? '0')
      .plus(current?.vestingStake ?? '0')
      .plus(inactive?.vestingStake ?? '0')
      .plus(current?.lockedStake ?? '0')
      .plus(inactive?.lockedStake ?? '0')
      .plus(current?.validatorStake ?? '0')
      .plus(inactive?.validatorStake ?? '0')
      .toString()
  },
  {
    label: 'Ordinary stakes, EVER',
    type: 'ton',
    value: poolClosed ? null : new BigNumber(current?.ordinaryStake ?? '0').plus(inactive?.ordinaryStake ?? '0').toString()
  },
  {
    label: 'Vesting stakes, EVER',
    type: 'ton',
    value: poolClosed ? null : new BigNumber(current?.vestingStake ?? '0').plus(inactive?.vestingStake ?? '0').toString()
  },
  {
    label: 'Locked stakes, EVER',
    type: 'ton',
    value: poolClosed ? null : new BigNumber(current?.lockedStake ?? '0').plus(inactive?.lockedStake ?? '0').toString()
  },
  {
    label: 'Validator stakes, EVER',
    type: 'ton',
    value: poolClosed ? null : new BigNumber(current?.validatorStake ?? '0').plus(inactive?.validatorStake ?? '0').toString()
  }
];

const getTotalCharts = (rounds, currentNumber) => {
  const checking = rounds.filter((e) => e.number <= currentNumber + 1).slice(rounds.length - 16, rounds.length);

  return [
    {
      label: 'By round',
      labels: checking.map((e) => e.number.toString()),
      data: checking.map((e) => new BigNumber(e.ordinaryStake ?? 0).plus(e.lockedStake ?? 0)
        .plus(e.vestingStake ?? 0).plus(e.validatorStake ?? 0)
        .dividedBy(10 ** 9)
        .toNumber())
    },
    {
      label: 'Efficiency',
      labels: checking.map((e) => e.number.toString()),
      data: checking.map((e) => (e.stake === '0' ? 0
        : new BigNumber(e.stake).minus(e.unused).multipliedBy(100).dividedBy(e.stake)
          .toNumber()))
    },
    {
      label: 'APY',
      labels: checking.map((e) => e.number.toString()),
      data: checking.map((e) => {
        const prev = rounds.filter((i) => i.number === e.number - 2)[0];
        const multi = !prev ? 1 : (e.supposedElectedAt - prev.supposedElectedAt) / 3600 / 24 / 2;
        return (e.stake === '0' ? 0
          : new BigNumber(e.participantReward).multipliedBy(365 * 100 * multi)
            .dividedBy(new BigNumber(e.stake ?? 0).plus(e.validatorStake ?? 0))
            .toNumber());
      })
    }
  ];
};

const getStakeRounds = (round, isCurrent) => {
  const total = new BigNumber(round?.ordinaryStake ?? '0')
    .plus(round?.vestingStake ?? '0')
    .plus(round?.lockedStake ?? '0')
    .plus(round?.validatorStake ?? '0')
    .toString();
  if (total === '0')
    return [];
  const efficiency = new BigNumber(total).minus(round.unused).multipliedBy(100).dividedBy(total)
    .toFixed();

  return [
    {
      type: 'Ordinary',
      round: `${round && round.number % 2 ? 'Odd' : 'Even'}${isCurrent ? ' (current)' : ''}`,
      totalAmount: round.ordinaryStake,
      unusedAmount: new BigNumber(round?.unused ?? '0').dividedBy(total).multipliedBy(round.ordinaryStake ?? '0').toFixed(),
      efficiency
    },
    {
      type: 'Locked',
      round: `${round && round.number % 2 ? 'Odd' : 'Even'}${isCurrent ? ' (current)' : ''}`,
      totalAmount: round.lockedStake,
      unusedAmount: new BigNumber(round?.unused ?? '0').dividedBy(total).multipliedBy(round.lockedStake ?? '0').toFixed(),
      efficiency
    },
    {
      type: 'Vesting',
      round: `${round && round.number % 2 ? 'Odd' : 'Even'}${isCurrent ? ' (current)' : ''}`,
      totalAmount: round.vestingStake,
      unusedAmount: new BigNumber(round?.unused ?? '0').dividedBy(total).multipliedBy(round.vestingStake ?? '0').toFixed(),
      efficiency
    },
    {
      type: 'Validator',
      round: `${round && round.number % 2 ? 'Odd' : 'Even'}${isCurrent ? ' (current)' : ''}`,
      totalAmount: round.validatorStake,
      unusedAmount: new BigNumber(round?.unused ?? '0').dividedBy(total).multipliedBy(round.validatorStake ?? '0').toFixed(),
      efficiency
    }
  ];
};

const getStakeholderByRounds = (participantId, rounds, current) => {
  const stakedAt = Math.min(...rounds.map((r) => r.stakedAt));
  const stakedDate = stakedAt;
  const lastRoundNumber = Math.max(...rounds.filter((r) => r.reward).map((r) => r.round));
  const round = rounds.filter((r) => r.round === lastRoundNumber)[0];
  const prevRound = rounds.filter((r) => r.round === lastRoundNumber - 2)[0];

  const totalReward = current?.reward ?? (round ? round?.reward : rounds[0]?.reward);
  const dayReward = new BigNumber(round?.reward ?? 0).minus(prevRound?.reward ?? 0).toString();
  const stakeAmount = !current.stakes ? null
    : Object.values(current.stakes).reduce((a, s) => a.plus(s), new BigNumber(0));
  const vestingAmount = current.vestingDonor === EmptyAddress ? null
    : Object.values(current.vestings).reduce((ap, e) => ap.plus(e.remainingAmount), new BigNumber(0));
  const lockAmount = current.lockDonor === EmptyAddress ? null
    : Object.values(current.locks).reduce((ap, e) => ap.plus(e.remainingAmount), new BigNumber(0));
  const total = new BigNumber(0).plus(stakeAmount ?? 0).plus(vestingAmount ?? 0).plus(lockAmount ?? 0);

  const result = [];
  if (stakeAmount && !stakeAmount.eq(0)) {
    result.push({
      address: participantId,
      type: 'ordinary',
      amount: stakeAmount.toString(),
      unusedStake: '0',
      dayReward: total.eq(0) || stakeAmount.eq(totalReward) ? dayReward
        : stakeAmount.multipliedBy(dayReward).idiv(total).toString(),
      totalReward: total.eq(0) || stakeAmount.eq(totalReward) ? totalReward
        : stakeAmount.multipliedBy(totalReward).idiv(total).toString(),
      date: stakedDate
    });
  }
  if (current.vestingDonor !== EmptyAddress) {
    result.push({
      address: participantId,
      type: 'vestingBeneficiary',
      amount: null,
      unusedStake: null,
      dayReward: total.eq(0) || !vestingAmount ? null
        : vestingAmount.multipliedBy(dayReward).idiv(total).toString(),
      totalReward: total.eq(0) || !vestingAmount ? null
        : vestingAmount.multipliedBy(totalReward).idiv(total).toString(),
      date: stakedDate
    });
  }
  if (current.lockDonor !== EmptyAddress) {
    result.push({
      address: participantId,
      type: 'lockedBeneficiary',
      amount: null,
      unusedStake: null,
      dayReward: total.eq(0) || !lockAmount ? null
        : lockAmount.multipliedBy(dayReward).idiv(total).toString(),
      totalReward: total.eq(0) || !lockAmount ? null
        : lockAmount.multipliedBy(totalReward).idiv(total).toString(),
      date: stakedDate
    });
  }

  return result;
};

const getStakeholderByVesting = (vestingRounds, currentParticipants) => {
  const stakedAt = Math.min(...vestingRounds.map((r) => r.stakedAt));
  const stakedDate = stakedAt;
  const beneficiaries = [...new Set(vestingRounds.map((r) => r.participantId))];
  const amounts = beneficiaries.map((b) => {
    const rounds = vestingRounds.filter((e) => e.participantId === b);
    const lastRoundNumber = Math.max(...rounds.map((r) => r.round));
    return rounds.filter((e) => e.vestingStake && [lastRoundNumber - 1, lastRoundNumber].includes(e.round))
      .reduce((a, e) => a.plus(e.vestingStake), new BigNumber(0)).toString();
  });

  if (!amounts.length && !currentParticipants.length)
    return [];

  return {
    address: vestingRounds.length ? vestingRounds[0].vestingDonor : currentParticipants[0].vestingDonor,
    type: 'vestingDonor',
    amount: amounts.length > 0 ? amounts.reduce((a, e) => a.plus(e), new BigNumber(0)).toString()
      : currentParticipants.reduce((a, p) => a.plus(Object.values(p.vestings)
        .reduce((ap, e) => ap.plus(e.remainingAmount), new BigNumber(0))), new BigNumber(0))
        .toString(),
    unusedStake: null,
    dayReward: null,
    totalReward: null,
    date: vestingRounds.length ? stakedDate
      : Math.min(...currentParticipants.flatMap((e) => Object.values(e.vestings).map((v) => v.lastWithdrawalTime)))
  };
};

const getStakeholderByLocked = (lockedRounds, currentParticipants) => {
  const stakedAt = Math.min(...lockedRounds.map((r) => r.stakedAt));
  const stakedDate = stakedAt;
  const beneficiaries = [...new Set(lockedRounds.map((r) => r.participantId))];
  const amounts = beneficiaries.map((b) => {
    const rounds = lockedRounds.filter((e) => e.participantId === b);
    const lastRoundNumber = Math.max(...rounds.map((r) => r.round));
    return rounds.filter((e) => e.lockedStake && [lastRoundNumber - 1, lastRoundNumber].includes(e.round))
      .reduce((a, e) => a.plus(e.lockedStake), new BigNumber(0)).toString();
  });

  if (!amounts.length && !currentParticipants.length)
    return [];

  return {
    address: lockedRounds.length ? lockedRounds[0].lockedDonor : currentParticipants[0].lockDonor,
    type: 'lockedDonor',
    amount: amounts.length > 0 ? amounts.reduce((a, e) => a.plus(e), new BigNumber(0)).toString()
      : currentParticipants.reduce((a, p) => a.plus(Object.values(p.locks)
        .reduce((ap, e) => ap.plus(e.remainingAmount), new BigNumber(0))), new BigNumber(0))
        .toString(),
    unusedStake: null,
    dayReward: null,
    totalReward: null,
    date: lockedRounds.length ? stakedDate
      : Math.min(...currentParticipants.flatMap((e) => Object.values(e.locks).map((v) => v.lastWithdrawalTime)))
  };
};

const getStakeholders = (rounds, currentRound, participantRounds, currentParticipants) => {
  const participants = [...new Set(currentParticipants.map((e) => e.participantId))].sort();
  let stakeholdersTemp = participants
    .flatMap((p) => getStakeholderByRounds(p,
      participantRounds.filter((e) => e.participantId === p),
      currentParticipants.filter((e) => e.participantId === p)[0]));
  const vestingDonors = [...new Set(participantRounds.filter((e) => e.vestingDonor).map((e) => e.vestingDonor)
    .concat(currentParticipants.map((e) => e.vestingDonor)))]
    .filter((e) => e !== EmptyAddress)
    .sort();
  stakeholdersTemp = stakeholdersTemp.concat(vestingDonors
    .flatMap((p) => getStakeholderByVesting(participantRounds.filter((e) => e.vestingDonor === p),
      currentParticipants.filter((e) => e.vestingDonor === p))));
  const lockedDonors = [...new Set(participantRounds.filter((e) => e.lockedDonor).map((e) => e.lockedDonor)
    .concat(currentParticipants.map((e) => e.lockDonor)))]
    .filter((e) => e !== EmptyAddress)
    .sort();
  stakeholdersTemp = stakeholdersTemp.concat(lockedDonors
    .flatMap((p) => getStakeholderByLocked(participantRounds.filter((e) => e.lockedDonor === p),
      currentParticipants.filter((e) => e.lockDonor === p))));
  stakeholdersTemp.filter((e) => !e.date || !Number.isFinite(e.date)).forEach((e) => {
    const minRound = Math.min(...new Set(participantRounds
      .filter((pr) => pr.participantId === e.address || pr.vestingDonor === e.address || pr.lockedDonor === e.address)
      .map((pr) => pr.round)));
    const minDate = rounds.filter((r) => r.number === minRound)[0]?.supposedElectedAt;
    if (minDate)
      e.date = minDate;
  });
  if (currentRound?.currentRoundUnused) {
    const totalByParticipants = stakeholdersTemp
      .filter((e) => ['ordinary', 'vestingDonor', 'lockedDonor'].includes(e.type))
      .reduce((a, e) => a.plus(e.amount ?? '0'), new BigNumber('0'));
    stakeholdersTemp.filter((e) => e.amount).forEach((e) => {
      e.unusedStake = new BigNumber(currentRound?.currentRoundUnused)
        .multipliedBy(e.amount).dividedBy(totalByParticipants).toFixed();
    });
  }
  return stakeholdersTemp;
};

const DepoolPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const roundsRef = useRef();
  const stakeholdersRef = useRef();
  const stakeRoundsRef = useRef();
  const defaultRound = getSearchString(window.location.search, 'round');

  const [rounds, setRounds] = useState([]);
  const [participantRounds, setParticipantRounds] = useState([]);
  const [currentParticipants, setCurrentParticipants] = useState([]);
  const [depool, setDepool] = useState({});
  const [currentRound, setCurrentRound] = useState({});
  const [selectedRoundNum, setSelectedRoundNum] = useState(parseInt(defaultRound, 10));
  const [stats, setStats] = useState([]);
  const [stakeRounds, setStakeRounds] = useState([]);
  const [stakeholders, setStakeholders] = useState([]);
  const [totalCharts, setTotalCharts] = useState();
  const [loading, setLoading] = useState(false);
  const [completedRoundsCount, setCompletedRoundsCount] = useState();
  const {
    depools,
    myAddress
  } = useSelector((state) => state.config);

  useEffect(() => {
    const current = depools?.filter((e) => e.id === id)[0];
    if (!current)
      return;

    setDepool(current);
    if (!current.detailedRounds && !current.detailedParticipants && !loading) {
      setLoading(true);
      dispatch({ type: SET_LOADED, loaded: false });
      dispatch({ type: DEPOOL_LOAD, id });
    }
    else {
      dispatch({ type: SET_LOADED, loaded: true });
      if (current.detailedRounds)
        setRounds(current.detailedRounds);
      if (current.detailedParticipants)
        setParticipantRounds(current.detailedParticipants);
      if (current.currentParticipants)
        setCurrentParticipants(current.currentParticipants);
    }
  }, [id, depools, myAddress]);

  useEffect(() => {
    if (getSearchString(window.location.search, 'stakeholder'))
      stakeholdersRef.current.scrollIntoView();
    else if (defaultRound)
      roundsRef.current.scrollIntoView();
  }, []);

  useEffect(() => {
    if (!rounds)
      return;
    let [current] = rounds.filter((e) => currentRounds.includes(e.step)).reverse();
    if (depool.poolClosed)
      [current] = rounds.filter((e) => e.step !== 0).reverse();
    let inactiveNumber = current?.number - 1;
    if (!current) {
      const roundNumbers = rounds
        .filter((e) => !new BigNumber(e.ordinaryStake ?? 0).isZero()
          || !new BigNumber(e.vestingStake ?? 0).isZero()
          || !new BigNumber(e.lockedStake ?? 0).isZero()
          || !new BigNumber(e.validatorStake ?? 0).isZero())
        .map((e) => e.number);
      const currentNumber = Math.max(...roundNumbers);
      inactiveNumber = currentNumber - 1;
      [current] = rounds.filter((e) => e.number === currentNumber);
    }
    if (!current) {
      current = rounds[rounds.length - 3];
      inactiveNumber = !current ? null : (current.number - 1);
    }
    const inactiveRound = rounds.filter((e) => e.number === inactiveNumber)[0];
    setStats(getTotalStats(depool?.stake, current, inactiveRound, depool?.poolClosed));
    setCurrentRound({
      currentRound: current?.number,
      currentRoundTotal: current?.stake,
      currentRoundUnused: current?.unused
    });

    if (current && !selectedRoundNum)
      setSelectedRoundNum(current.number);

    if (!current)
      return;

    setTotalCharts(getTotalCharts(rounds, current.number));
    const items = [
      ...getStakeRounds(current, true),
      ...getStakeRounds(inactiveRound, false)
    ];
    setStakeRounds([
      ...items.filter((e) => e.type === 'Ordinary'),
      ...items.filter((e) => e.type === 'Locked'),
      ...items.filter((e) => e.type === 'Vesting'),
      ...items.filter((e) => e.type === 'Validator')
    ]);
  }, [depool, rounds]);

  useEffect(() => {
    if (!participantRounds)
      return;

    setStakeholders(getStakeholders(rounds, currentRound, participantRounds, currentParticipants));
  }, [participantRounds]);

  useEffect(async () => {
    if (!myAddress || !depools)
      return;

    dispatch({ type: PARTICIPANT_DEPOOLS_LOAD, participant: myAddress, data: depools });
  }, [myAddress, depools]);

  useEffect(() => {
    if (!rounds)
      return;
    setCompletedRoundsCount((rounds.filter((e) => e.completionReason !== 2 && (e.step === 8 || e.step === 9))).length);
  }, [rounds]);

  const handleCurrentRoundClick = () => {
    setSelectedRoundNum(currentRound?.currentRound);
    roundsRef.current.scrollIntoView();
  };

  const handleTotalValueLockedClick = () => {
    stakeRoundsRef.current.scrollIntoView();
  };

  const handleStakeholdersClick = () => {
    stakeholdersRef.current.scrollIntoView();
  };

  return (
    <React.Fragment>
      <ToolBack title="All depools" url="/" />
      <Section title="Depool profile">
        {depool?.id
        && (
          <Profile
            key="profile"
            {...depool}
            {...currentRound}
            rounds={rounds}
            completedRoundsCount={completedRoundsCount}
            onCurrentRoundClick={handleCurrentRoundClick}
            onTotalValueLockedClick={handleTotalValueLockedClick}
            onStakeholdersClick={handleStakeholdersClick}
          />
        )}
      </Section>
      {!depool?.poolClosed && depool?.detailedRounds && myAddress && <MyStakeDistribution {...depool} />}
      <Section ref={stakeRoundsRef} title="Stakes distribution">
        <Overview key="stakes" statistics={stats}>
          <ChartBar data={totalCharts} type="Bar" />
        </Overview>
        {!depool?.poolClosed && <StakeDistribution data={stakeRounds} />}
      </Section>
      <Rounds ref={roundsRef} data={rounds} currentRound={selectedRoundNum} />
      {!depool?.poolClosed && <Stakeholders ref={stakeholdersRef} data={stakeholders} />}
    </React.Fragment>
  );
};

DepoolPage.propTypes = {
  id: PropTypes.string
};

DepoolPage.defaultProps = {
  id: null
};

export default DepoolPage;
