import { ProviderRpcClient } from 'ton-inpage-provider';
import BigNumber from 'bignumber.js';

import Depoolv3Abi from 'assets/Depoolv3.abi.json';

let interaction = null;
const ton = new ProviderRpcClient();

const TonWalletExt = {
  getAccountInteraction: () => {
    if (interaction)
      return interaction;

    return new Promise((resolve) => {
      let count = 0;
      const timer = setInterval(async () => {
        count += 1;
        let state;
        try {
          state = await ton.rawApi.getProviderState();
        }
        catch {
          state = null;
        }
        if (!interaction)
          interaction = state?.permissions?.accountInteraction;
        if (interaction || count >= 5) {
          resolve(interaction);
          clearInterval(timer);
        }
      }, 1000);
    });
  },
  requestAccountInteraction: async () => {
    if (interaction)
      return interaction;

    const state = await ton.rawApi.getProviderState();
    interaction = state?.permissions?.accountInteraction;
    if (interaction)
      return interaction;

    const { accountInteraction } = await ton.rawApi.requestPermissions({
      permissions: ['tonClient', 'accountInteraction']
    });
    interaction = accountInteraction;
    if (interaction)
      return interaction;
    return null;
  },
  disconnect: async () => {
    await ton.rawApi.disconnect();
    interaction = null;
  },
  hasProvider: async () => {
    if (!(await ton.hasProvider()))
      return false;
    await ton.ensureInitialized();
    return true;
  },
  hasPermissions: () => !!interaction,
  deposit: async ({
    addressFrom,
    addressTo,
    stake,
    stakeFee
  }) => {
    const result = await ton.rawApi.sendMessage({
      sender: addressFrom,
      recipient: addressTo,
      amount: new BigNumber(stake).plus(stakeFee).toString(),
      bounce: true,
      payload: {
        abi: JSON.stringify(Depoolv3Abi),
        method: 'addOrdinaryStake',
        params: {
          stake
        }
      }
    });
    if (!result)
      return null;
    const { transaction } = result;
    return transaction;
  },
  withdrawPart: async ({
    addressFrom,
    addressTo,
    amount,
    withdrawValue
  }) => {
    const result = await ton.rawApi.sendMessage({
      sender: addressFrom,
      recipient: addressTo,
      amount,
      bounce: true,
      payload: {
        abi: JSON.stringify(Depoolv3Abi),
        method: 'withdrawPart',
        params: {
          withdrawValue
        }
      }
    });
    if (!result)
      return null;
    const { transaction } = result;
    return transaction;
  },
  withdrawFromPoolingRound: async ({
    addressFrom,
    addressTo,
    amount,
    withdrawValue
  }) => {
    const result = await ton.rawApi.sendMessage({
      sender: addressFrom,
      recipient: addressTo,
      amount,
      bounce: true,
      payload: {
        abi: JSON.stringify(Depoolv3Abi),
        method: 'withdrawFromPoolingRound',
        params: {
          withdrawValue
        }
      }
    });
    if (!result)
      return null;
    const { transaction } = result;
    return transaction;
  },
  withdrawAll: async ({
    addressFrom,
    addressTo,
    amount
  }) => {
    const result = await ton.rawApi.sendMessage({
      sender: addressFrom,
      recipient: addressTo,
      amount,
      bounce: true,
      payload: {
        abi: JSON.stringify(Depoolv3Abi),
        method: 'withdrawAll',
        params: {
        }
      }
    });
    if (!result)
      return null;
    const { transaction } = result;
    return transaction;
  },
  cancelWithdrawal: async ({
    addressFrom,
    addressTo,
    amount
  }) => {
    const result = await ton.rawApi.sendMessage({
      sender: addressFrom,
      recipient: addressTo,
      amount,
      bounce: true,
      payload: {
        abi: JSON.stringify(Depoolv3Abi),
        method: 'cancelWithdrawal',
        params: {
        }
      }
    });
    if (!result)
      return null;
    const { transaction } = result;
    return transaction;
  },
  reduceTransactions: ({
    address,
    filter,
    reducer,
    finalizer
  }) => {
    const subscr = ton.createSubscriber();
    const oldTransactionsStream = subscr.oldTransactions(address, filter);

    return new Promise((resolve) => {
      let acc = null;
      oldTransactionsStream
        .makeProducer(async (evt) => {
          acc = await reducer(acc, evt);
        }, () => {
          try {
            if (finalizer)
              acc = finalizer(acc);
          }
          catch (err) {
            resolve(null);
            subscr.unsubscribe();
            return;
          }
          resolve(acc);
          subscr.unsubscribe();
        });
    });
  },
  getTransactions: ({
    address,
    filter
  }) => {
    const subscr = ton.createSubscriber();
    const oldTransactionsStream = subscr.oldTransactions(address, filter);

    return new Promise((resolve) => {
      let acc = [];
      oldTransactionsStream
        .makeProducer(async (evt) => {
          acc = acc.concat(evt.transactions);
        }, () => {
          resolve(acc);
          subscr.unsubscribe();
        });
    });
  },
  getContractDetails: async ({
    address
  }) => ton.getFullContractState({ address })
};

export default TonWalletExt;
