import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Layout from 'components/_common/Broxus/Layout';
import WalletConnectPopup from 'components/_common/Broxus/WalletConnectPopup';
import Main from 'components/_common/Broxus/Main';

import NavPanel from 'components/NavPanel';
import ToolPanel from 'components/ToolPanel';
import MainPage from 'components/MainPage';
import DepoolPage from 'components/DepoolPage';
import ParticipantPage from 'components/ParticipantPage';
import Footer from 'components/_common/Broxus/Footer';
import MyPage from 'components/MyPage';
import ExplorerPage from 'components/ExplorerPage';
import { Route, Switch } from 'react-router-dom';

import { DEPOOLS_LOAD, BALANCE_LOAD } from 'constants/actions';
import tonWalletExt from 'utils/tonWalletExt';
import { FAVORITES_STORE } from 'constants/cache';

import history from 'utils/history';

import WalletLoginPopup from './_common/Broxus/WalletLoginPopup';

const App = () => {
  const dispatch = useDispatch();

  const {
    cantconnectview,
    myAddress
  } = useSelector((state) => state.config);
  const [pathname, setPathname] = useState('');
  const [showWalletConnect, setShowWalletConnect] = useState(false);
  const [showWalletLogin, setShowWalletLogin] = useState(false);

  useEffect(async () => {
    dispatch({ type: DEPOOLS_LOAD });
    const accountInteraction = await tonWalletExt.getAccountInteraction();

    if (accountInteraction) {
      dispatch({ type: BALANCE_LOAD, address: accountInteraction.address });
      dispatch({ type: FAVORITES_STORE, address: accountInteraction.address });
    }
  }, [myAddress]);

  useEffect(() => {
    setInterval(async () => {
      if (!myAddress)
        return;
      dispatch({ type: BALANCE_LOAD, address: myAddress });
    }, 60000);
  }, []);

  useEffect(() => {
    setPathname(window.location.pathname);
    history.listen((location) => {
      setPathname(location.pathname);
    });
  }, []);

  useEffect(() => {
    setShowWalletLogin(pathname && pathname.startsWith('/stakeholders/') && !myAddress);
    tonWalletExt.hasProvider();
  }, [pathname, myAddress]);

  useEffect(() => {
    setShowWalletConnect(cantconnectview
      || (pathname && pathname.startsWith('/stakeholders/') && !tonWalletExt.hasProvider()));
  }, [pathname, cantconnectview]);

  return (
    <Layout>
      <NavPanel />
      {showWalletConnect && <WalletConnectPopup />}
      {showWalletLogin && !showWalletConnect && <WalletLoginPopup />}
      <Main>
        <Switch>
          <Route path="/depools/:id" component={DepoolPage} />
          <Route path="/explorer" component={ExplorerPage} />
          <Route path="/my" component={MyPage} />
          <Route path="/stakeholders/:participant" component={ParticipantPage} />
          <Route component={MainPage} />
        </Switch>
      </Main>
      <ToolPanel />
      <Footer />
    </Layout>
  );
};

export default App;
