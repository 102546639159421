import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './index.scss';

const Stage = ({
  data,
  index
}) => (
  <div className="swap-roadmap">
    {data && data.map((item, ind) => (
      <div
        key={item}
        className={classnames([
          'swap-roadmap__item',
          ind < index ? 'swap-roadmap__item--prev' : null,
          ind === index ? 'swap-roadmap__item--now' : null,
          ind > index ? 'swap-roadmap__item--next' : null
        ])}
      >
        {item}
      </div>
    ))}
  </div>
);

Stage.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string),
  index: PropTypes.number
};

Stage.defaultProps = {
  data: null,
  index: null
};

export default Stage;
