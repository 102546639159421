import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BigNumber from 'bignumber.js';

import Section from 'components/_common/Broxus/Section';
import FilterPanel from 'components/ExplorerPage/FilterPanel';
import Depools from 'components/ExplorerPage/Depools';
import history from 'utils/history';

import {
  PARTICIPANT_DEPOOLS_LOAD,
  EXPLORER_SORTED,
  SET_LOADED
} from 'constants/actions';

const ExplorerPage = () => {
  const dispatch = useDispatch();
  const [filteredDepools, setFilteredDepools] = useState([]);

  const {
    depools,
    depoolsLoaded,
    myAddress,
    poolClosed,
    apyFrom,
    apyTo,
    feeFrom,
    feeTo,
    tvlFrom,
    tvlTo,
    stakeholdersFrom,
    stakeholdersTo,
    search,
    explorerSorted
  } = useSelector((state) => state.config);

  useEffect(() => {
    dispatch({ type: SET_LOADED, loaded: depoolsLoaded });
  }, [depoolsLoaded]);

  useEffect(async () => {
    if (!myAddress || !depoolsLoaded)
      return;

    dispatch({ type: PARTICIPANT_DEPOOLS_LOAD, participant: myAddress, data: depools });
  }, [myAddress, depools, depoolsLoaded]);

  const getQuery = () => {
    const query = [];
    if (poolClosed)
      query.push(`pool_closed=${!poolClosed}`);
    if (apyFrom)
      query.push(`apy_from=${apyFrom}`);
    if (apyTo)
      query.push(`apy_to=${apyTo}`);
    if (feeFrom)
      query.push(`fee_from=${feeFrom}`);
    if (feeTo)
      query.push(`fee_to=${feeTo}`);
    if (tvlFrom)
      query.push(`tvl_from=${tvlFrom}`);
    if (tvlTo)
      query.push(`tvl_to=${tvlTo}`);
    if (stakeholdersFrom)
      query.push(`stakeholders_from=${stakeholdersFrom}`);
    if (stakeholdersTo)
      query.push(`stakeholders_to=${stakeholdersTo}`);
    if (search)
      query.push(`search=${encodeURIComponent(search)}`);
    if (explorerSorted?.sortedBy)
      query.push(`sorted_by=${explorerSorted.sortedBy}&sorted_asc=${explorerSorted.sortedAsc}`);

    return query.length !== 0 ? `/explorer?${query.reduce((a, e) => (!a ? e : `${a}&${e}`), '')}` : '/explorer';
  };

  const currentQuery = window.location.pathname + window.location.search;
  const changeQuery = () => {
    if (window.location.pathname !== '/explorer')
      return;
    const query = getQuery();
    if (query !== currentQuery)
      history.push(query);
  };

  useEffect(() => {
    if (currentQuery === '/explorer')
      changeQuery();
  });

  useEffect(() => {
    let filtered = depools;
    if (!filtered)
      return;

    if (search)
      filtered = filtered.filter((e) => e.id.includes(search));
    if (poolClosed)
      filtered = filtered.filter((e) => !e.poolClosed);
    if (apyFrom)
      filtered = filtered.filter((e) => e.apyPercent && e.apyPercent >= apyFrom);
    if (apyTo)
      filtered = filtered.filter((e) => !e.apyPercent || e.apyPercent <= apyTo);
    if (feeFrom)
      filtered = filtered.filter((e) => e.validatorRewardFraction && e.validatorRewardFraction >= feeFrom);
    if (feeTo)
      filtered = filtered.filter((e) => !e.validatorRewardFraction || e.validatorRewardFraction <= feeTo);
    if (tvlFrom) {
      filtered = filtered
        .filter((e) => e.stake && Math.ceil(new BigNumber(e.stake).dividedBy(1000000000).toNumber()) >= tvlFrom);
    }
    if (tvlTo) {
      filtered = filtered
        .filter((e) => !e.stake || Math.floor(new BigNumber(e.stake).dividedBy(1000000000).toNumber()) <= tvlTo);
    }
    if (stakeholdersFrom)
      filtered = filtered.filter((e) => e.participantCount && e.participantCount >= stakeholdersFrom);
    if (stakeholdersTo)
      filtered = filtered.filter((e) => !e.participantCount || e.participantCount <= stakeholdersTo);

    setFilteredDepools(filtered);
    changeQuery();
  }, [depools, apyFrom, apyTo, feeFrom, feeTo, tvlFrom, tvlTo, stakeholdersFrom, stakeholdersTo, poolClosed, search]);

  useEffect(() => {
    changeQuery();
  }, [explorerSorted]);

  const handleSorted = (sorted) => {
    dispatch({
      type: EXPLORER_SORTED,
      ...sorted
    });
  };

  return (
    <React.Fragment>
      <Section title="Depools" tool={<FilterPanel />} comment={!depoolsLoaded ? ' loading...' : null}>
        <Depools key="total" data={filteredDepools} {...explorerSorted} onSorted={handleSorted} />
      </Section>
    </React.Fragment>
  );
};

export default ExplorerPage;
