import React from 'react';
import { BigNumber } from 'bignumber.js';

export const tonf = (nano, params) => {
  if (!nano)
    return '0';

  const value = new BigNumber(nano, 10).dividedBy(10 ** 9).toNumber();

  if (params?.fixed)
    return value.toLocaleString(undefined, { maximumFractionDigits: params.fixed });

  if (params && params?.fixed === 0)
    return Math.round(value).toLocaleString();

  return value.toLocaleString(undefined, { maximumFractionDigits: 9 });
};

export const prcf = (value) => (!value ? '-' : `${parseInt(value, 10)}%`);

export const addrf = (value) => (
  <span title={value}>
    {!value ? '-' : `${value.substr(0, value.startsWith('-1:') ? 9 : 8)}...${value.substr(value.length - 9, 9)}`}
  </span>
);

export const addressf = (value) => (
  !value ? '-' : `${value.substr(0, value.startsWith('-1:') ? 9 : 8)}...${value.substr(value.length - 9, 9)}`
);

export const shortaddrf = (value) => (
  !value ? '-' : `${value.substr(0, value.startsWith('-1:') ? 7 : 6)}...${value.substr(value.length - 4, 4)}`
);

export const recommendFixed = (value) => {
  if (value === null || value === undefined)
    return null;

  let fixed = 5;
  if ((value ?? '').length < 8)
    fixed = 3;
  if ((value ?? '').length > 14)
    fixed = 0;

  return fixed;
};

export const roundStage = (roundStep, roundReason) => {
  if (roundStep === 8 && [4, 5, 6, 8].includes(roundReason))
    return 9;
  return roundStep ?? 0;
};
