import React from 'react';

import PropTypes from 'prop-types';

import './index.scss';

const ToolLabel = ({ label }) => (
  <div className="tools-label">{label}</div>
);

ToolLabel.propTypes = {
  label: PropTypes.node
};

ToolLabel.defaultProps = {
  label: null
};

export default ToolLabel;
