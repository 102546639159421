import { handleActions } from 'redux-actions';
import { BigNumber } from 'bignumber.js';
import DepoolsData from 'assets/Depools.json';

import {
  LOADING_ERROR,
  SET_DEPOOLS,
  SET_DEPOOL,
  SET_PARTICIPANT_DEPOOLS,
  SET_DEPOSITED_DEPOOL,
  SET_DEPOOLS_LOADED,
  SET_MY_ADDRESS,
  SET_BALANCE,
  SET_DEPOOL_ELECTION_REWARDS,
  WALLET_CONNECT_POPUP,
  DEPOOL_FILTER_APPLY,
  DEPOOL_SEARCHING,
  SET_OVERVIEW,
  SET_DEPOOLS_STATISTICS,
  SET_FAVORITES,
  SET_FAVORITE,
  STAKEHOLDERS_FILTER_APPLY,
  STAKEHOLDERS_SEARCHING,
  MAIN_PAGE_SORTED,
  EXPLORER_SORTED,
  SET_LOADED,
  MY_DEPOOL_FILTER_APPLY
} from 'constants/actions';

import {
  depoolVersions
} from 'constants/depools';

import statistics from 'utils/statistics';
import { getSearchString, getSearchFloat, getSearchBool } from 'utils/url';

const initialState = {
  error: null,
  depools: null,
  depoolsCount: null,
  depoolsLoaded: false,
  myAddress: null,
  myBalance: null,
  depoolsRewardLoaded: 0,
  loaded: true,
  cantconnectview: false,
  stakes: {},
  apys: {},
  apyFrom: getSearchFloat(window.location.search, 'apy_from'),
  apyTo: getSearchFloat(window.location.search, 'apy_to'),
  feeFrom: getSearchFloat(window.location.search, 'fee_from'),
  feeTo: getSearchFloat(window.location.search, 'fee_to'),
  tvlFrom: getSearchFloat(window.location.search, 'tvl_from'),
  tvlTo: getSearchFloat(window.location.search, 'tvl_to'),
  stakeholdersFrom: getSearchFloat(window.location.search, 'stakeholders_from'),
  stakeholdersTo: getSearchFloat(window.location.search, 'stakeholders_to'),
  poolClosed: getSearchBool(window.location.search, 'pool_closed', false),
  myPoolsHistory: getSearchBool(window.location.search, 'my_pools_history', false),
  search: getSearchString(window.location.search, 'search'),
  explorerSorted: {
    sortedBy: getSearchString(window.location.search, 'sorted_by'),
    sortedAsc: getSearchString(window.location.search, 'sorted_asc') === 'true'
  },
  mainPageSorted: {
    sortedBy: !getSearchString(window.location.search, 'sorted_by') ? 'stake' : getSearchString(window.location.search, 'sorted_by'),
    sortedAsc: getSearchString(window.location.search, 'sorted_asc') === 'true'
  },
  overview: null,
  favorites: [],
  participants: {}
};

const reducer = handleActions({
  [LOADING_ERROR]: (state, { error }) => ({
    ...state,
    error
  }),

  [SET_OVERVIEW]: (state, { overview }) => ({
    ...state,
    overview
  }),

  [SET_DEPOOLS_STATISTICS]: (state, { data }) => {
    const { depools } = state;
    if (!depools) {
      return {
        ...state,
        depools: data.map((item) => ({
          ...item,
          apyPercent: (item.apy ?? 0) * 100,
          participants: []
        }))
      };
    }

    data.forEach((item) => {
      const [oldDepool] = depools.map((d, ind) => ({ ind, value: d })).filter((e) => e.value.id === item.id);
      const depoolItem = {
        ...(oldDepool?.value ?? {}),
        ...item,
        apyPercent: (item.apy ?? 0) * 100,
        electorRewardsLoaded: oldDepool?.participantCount
      };
      if (oldDepool)
        depools.splice(oldDepool.ind, 1, depoolItem);
      else
        depools.push(depoolItem);
    });

    return {
      ...state,
      depools,
      depoolsRewardLoaded: depools.filter((d) => d.electorRewardsLoaded).length
    };
  },

  [SET_DEPOOLS]: (state, { data, loaded }) => {
    let { depools, stakes, apys } = state;
    if (!depools)
      depools = [];
    const { overview, depoolsCount } = state;
    data.forEach((item) => {
      const [oldDepool] = depools.map((d, ind) => ({ ind, value: d })).filter((e) => e.value.id === item.id);
      const constData = DepoolsData.filter((d) => d.id === item.id)[0];
      const depoolItem = {
        ...(oldDepool?.value ?? {}),
        ...item,
        codeHash: item.codeHash ?? item.code_hash,
        depoolVersion: Object.keys(depoolVersions)
          .find((key) => depoolVersions[key] === (item.codeHash ?? item.code_hash)),
        participantCount: item.poolClosed ? 0 : (item.participantQty ?? item.participants?.length),
        stake: item?.stake ?? Object.values(item.rounds)
          .filter(({ stake }) => stake !== '0')
          .reduce((acc, { stake }) => acc.plus(stake), new BigNumber(0)).toString(),
        electorRewardsLoaded: item?.apy ?? oldDepool?.apy,
        apyPercent: (item.apy ?? 0) * 100,
        name: constData?.name,
        imageUrl: constData?.avatar
      };
      delete depoolItem.electorRewards;
      if (oldDepool)
        depools.splice(oldDepool.ind, 1, depoolItem);
      else
        depools.push(depoolItem);
      if (item.electorRewardsLoaded && !overview?.length) {
        stakes = statistics.sumStakesSet(stakes, statistics.getStakesDailyValues({ depools: [item] }));
        apys = statistics.sumApysSet(apys, statistics.getApysDailyValues({ depools: [item] }));
      }
    });

    let depoolsLoaded = !!loaded;

    if (!depoolsLoaded && depoolsCount)
      depoolsLoaded = depools.length === depoolsCount;

    return {
      ...state,
      depools,
      depoolsLoaded,
      depoolsRewardLoaded: depools.filter((d) => d.electorRewardsLoaded).length,
      stakes,
      apys,
      error: null
    };
  },

  [SET_DEPOOL]: (state, {
    id,
    poolClosed,
    balance,
    stake,
    rounds,
    participants,
    currentParticipants
  }) => {
    const { depools } = state;
    return {
      ...state,
      depools: [...depools.map((e) => (e.id === id ? {
        ...e,
        poolClosed: poolClosed || e.poolClosed,
        balance,
        stake: stake ?? e.stake,
        detailedRounds: rounds,
        detailedParticipants: participants,
        currentParticipants
      } : e))]
    };
  },

  [SET_DEPOOLS_LOADED]: (state, { count }) => ({
    ...state,
    depoolsCount: count,
    depoolsLoaded: state.depools?.length === count
  }),

  [SET_PARTICIPANT_DEPOOLS]: (state, { participant, data }) => {
    const participantDepools = data.map((d) => ({
      id: d.id,
      my: d.depool,
      myRounds: d.rounds,
      myReward: d.depool?.reward,
      myTotal: d.depool?.total,
      myWithdraw: d.depool?.withdrawValue,
      myReinvest: d.depool?.reinvest,
      myWithdrawTxId: d.depool?.withdrawTxId,
      myWithdrawDateUtc: d.depool?.withdrawDateUtc
    }));
    let { depools } = state;
    if (participant === state.myAddress) {
      depools = !depools || !data?.length || depools.filter((e) => e.my).length === data.filter((e) => e.depool).length
        ? depools : depools.map((e) => {
          const d = participantDepools.filter((m) => m.id === e.id)[0];
          if (d) {
            return {
              ...e,
              ...d
            };
          }
          return e;
        });
    }
    return {
      ...state,
      depools,
      participants: {
        ...state.participants,
        [participant]: participantDepools
      }
    };
  },

  [SET_DEPOSITED_DEPOOL]: (state, { depool }) => {
    const { depools } = state;
    for (let i = 0; i < depools.length; i++) {
      if (depools[i].id === depool.id) {
        depools.splice(i, 1, {
          ...depools[i],
          ...depool,
          codeHash: depool.code_hash,
          depoolVersion: Object.keys(depoolVersions).find((key) => depoolVersions[key] === depool.code_hash),
          participantCount: depool.poolClosed ? 0 : depool.participants?.length,
          stake: Object.values(depool.rounds)
            .filter(({ stake }) => stake !== '0')
            .reduce((acc, { stake }) => acc.plus(stake), new BigNumber(0)).toString(),
          myReward: depool.my.reward,
          myTotal: depool.my?.total,
          myWithdraw: depool.my?.withdrawValue,
          myReinvest: depool.my.reinvest
        });
        return {
          ...state,
          depools: [...depools]
        };
      }
    }

    return state;
  },

  [SET_DEPOOL_ELECTION_REWARDS]: (state, { depool }) => {
    const { depools } = state;
    for (let i = 0; i < depools.length; i++) {
      if (depools[i].id === depool.id) {
        const depoolItem = {
          ...depools[i],
          ...depool,
          apyPercent: ((depool.electorRewards ?? []).filter((m) => m.dateUtc)[0]?.apy ?? 0) * 100
        };
        depools.splice(i, 1, depoolItem);

        delete depoolItem.electorRewards;

        let { stakes, apys } = state;
        if (depool.electorRewardsLoaded) {
          stakes = statistics.sumStakesSet(stakes, statistics.getStakesDailyValues({ depools: [depool] }));
          apys = statistics.sumApysSet(apys, statistics.getApysDailyValues({ depools: [depool] }));
        }

        return {
          ...state,
          depools,
          depoolsRewardLoaded: depools.filter((d) => d.electorRewardsLoaded).length,
          stakes,
          apys
        };
      }
    }

    return {
      ...state,
      depools
    };
  },

  [SET_MY_ADDRESS]: (state, { address }) => {
    const { depools } = state;
    if (!address) {
      return {
        ...state,
        depools: !depools ? null : [...depools.map((e) => {
          if (e.my)
            delete e.my;
          if (e.myTotal)
            delete e.myTotal;
          if (e.myReward)
            delete e.myReward;
          if (e.myRounds)
            delete e.myRounds;
          if (e.myWithdraw)
            delete e.myWithdraw;
          if (e.myReinvest)
            delete e.myReinvest;
          return e;
        })],
        myAddress: null,
        myBalance: null
      };
    }

    return {
      ...state,
      myAddress: address
    };
  },

  [SET_BALANCE]: (state, { address, balance }) => (
    state.myAddress === (address ?? state.myAddress) && state.myBalance === balance ? state : {
      ...state,
      myAddress: address ?? state.myAddress,
      myBalance: balance
    }
  ),

  [WALLET_CONNECT_POPUP]: (state, { cantconnectview }) => ({
    ...state,
    cantconnectview
  }),

  [DEPOOL_FILTER_APPLY]: (state, {
    apyFrom,
    apyTo,
    feeFrom,
    feeTo,
    tvlFrom,
    tvlTo,
    stakeholdersFrom,
    stakeholdersTo,
    poolClosed
  }) => ({
    ...state,
    apyFrom,
    apyTo,
    feeFrom,
    feeTo,
    tvlFrom,
    tvlTo,
    stakeholdersFrom,
    stakeholdersTo,
    poolClosed
  }),

  [MY_DEPOOL_FILTER_APPLY]: (state, {
    myPoolsHistory
  }) => ({
    ...state,
    myPoolsHistory
  }),

  [DEPOOL_SEARCHING]: (state, { search }) => ({
    ...state,
    search
  }),

  [SET_FAVORITES]: (state, { favorites }) => ({
    ...state,
    favorites
  }),

  [SET_FAVORITE]: (state, { id, isFavorite }) => {
    let { favorites } = state;
    if (isFavorite && !favorites.includes(id))
      favorites = favorites.concat([id]);
    else if (!isFavorite && favorites.includes(id))
      favorites = favorites.filter((e) => e !== id);
    return { ...state, favorites };
  },

  [STAKEHOLDERS_FILTER_APPLY]: (state, {
    amountFrom,
    amountTo,
    unusedStakeFrom,
    unusedStakeTo,
    dayRewardFrom,
    dayRewardTo,
    totalRewardFrom,
    totalRewardTo
  }) => ({
    ...state,
    amountFrom,
    amountTo,
    unusedStakeFrom,
    unusedStakeTo,
    dayRewardFrom,
    dayRewardTo,
    totalRewardFrom,
    totalRewardTo
  }),

  [STAKEHOLDERS_SEARCHING]: (state, { search }) => ({
    ...state,
    search
  }),

  [MAIN_PAGE_SORTED]: (state, { sortedBy, sortedAsc }) => ({
    ...state,
    mainPageSorted: !sortedBy && !sortedAsc ? null : { sortedBy, sortedAsc }
  }),

  [EXPLORER_SORTED]: (state, { sortedBy, sortedAsc }) => ({
    ...state,
    explorerSorted: !sortedBy && !sortedAsc ? null : { sortedBy, sortedAsc }
  }),

  [SET_LOADED]: (state, { loaded }) => ({
    ...state,
    loaded
  })
}, initialState);

export default reducer;
