import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ActionBar from 'components/_common/Broxus/ActionBar';
import PropTypes from 'prop-types';
import BigNumber from 'bignumber.js';

import API from 'utils/api';
import { tonf } from 'utils/format';

import tonWalletExt from 'utils/tonWalletExt';

import { MY_DEPOOL_LOAD, BALANCE_LOAD, SET_BALANCE } from 'constants/actions';

const fee = '6000000';

const getTotalStakes = (stakes) => {
  if (!stakes)
    return '0';
  return Object.values(stakes)
    .reduce((a, s) => a.plus(s), new BigNumber(0));
};

const Deposit = ({ id: address, minStake, stakeFee }) => {
  const dispatch = useDispatch();

  const { myAddress, myBalance } = useSelector((state) => state.config);
  const [value, setValue] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [transfering, setTransfering] = useState(false);

  useEffect(() => {
    if (!myAddress)
      return;
    dispatch({ type: BALANCE_LOAD, address: myAddress });
  }, [address]);

  useEffect(async () => {
    const balanceNum = new BigNumber(myBalance);
    if (!value) {
      setDisabled(true);
      return;
    }

    const minNum = new BigNumber(minStake);
    const valueNum = new BigNumber(value).multipliedBy(10 ** 9);
    setDisabled(valueNum.isLessThan(minNum)
      || balanceNum.isLessThan(valueNum));
  }, [myBalance, value, minStake]);

  const handleHalf = () => {
    setValue(new BigNumber(myBalance).dividedBy(10 ** 9).dividedBy(2)
      .toString());
  };

  const handleFull = () => {
    setValue(new BigNumber(myBalance).minus(stakeFee).minus(fee).dividedBy(10 ** 9)
      .toString());
  };

  const handleDeposit = async () => {
    setTransfering(true);
    const { balance: prevBalance } = await API.getAccount({ address: myAddress });
    const stake = new BigNumber(value).multipliedBy(10 ** 9).toString();
    const restOfBalance = (new BigNumber(prevBalance)).minus(stake).minus(stakeFee);
    if (restOfBalance.isLessThanOrEqualTo(0)) {
      setTransfering(false);
      dispatch({ type: SET_BALANCE, balance: prevBalance });
      return;
    }

    let sent = null;
    try {
      sent = await tonWalletExt.deposit({
        addressFrom: myAddress,
        addressTo: address,
        stake,
        stakeFee
      });
    }
    catch {
      setTransfering(false);
      return;
    }
    if (prevBalance !== myBalance)
      dispatch({ type: SET_BALANCE, balance: prevBalance });

    const acc = await API.getAccount({ address });
    const participant = await API.getParticipants({ address, boc: acc?.boc });
    let currentStake = null;
    if (!participant)
      currentStake = new BigNumber(0);
    const participantInfo = await API.getParticipantInfo({ address, boc: acc?.boc, participant: myAddress });
    if (!participantInfo)
      currentStake = new BigNumber(0);
    else
      currentStake = getTotalStakes(participantInfo.stakes);

    if (sent?.id?.lt && sent?.endStatus === 'active') {
      const timerId = setInterval(async () => {
        // TODO. Check transaction processing
        const newAcc = await API.getAccount({ address });
        let newStake = null;
        const newParticipant = await API.getParticipants({ address, boc: newAcc?.boc });
        if (!newParticipant)
          newStake = new BigNumber(0);
        const newParticipantInfo = await API.getParticipantInfo({ address, boc: newAcc?.boc, participant: myAddress });
        if (!newParticipantInfo)
          newStake = new BigNumber(0);
        else
          newStake = getTotalStakes(newParticipantInfo.stakes);

        if (newStake.gt(currentStake)) {
          clearInterval(timerId);
          setValue('');
          setTransfering(false);
          dispatch({ type: BALANCE_LOAD, address: myAddress });
          dispatch({ type: MY_DEPOOL_LOAD, participant: myAddress, address });
        }
      }, 1000);
    }
    else
      setTransfering(false);
  };

  const description = (
    <React.Fragment>
      Your wallet balance:&nbsp;
      {tonf(myBalance)}
      &nbsp;EVER
      <br />
      Minimum stake:&nbsp;
      {tonf(minStake)}
      &nbsp;EVER
      <br />
      Stake fee:&nbsp;
      {tonf(new BigNumber(stakeFee).toString())}
      &nbsp;EVER
    </React.Fragment>
  );

  return (
    <ActionBar
      title="Deposit EVER Funds"
      description={description}
      value={value}
      actionTitle="Deposit"
      onChangeValue={(v) => setValue(v)}
      onHalf={handleHalf}
      onFull={handleFull}
      onApply={handleDeposit}
      locked={disabled}
      disabled={transfering}
    />
  );
};

Deposit.propTypes = {
  id: PropTypes.string.isRequired,
  minStake: PropTypes.string.isRequired,
  stakeFee: PropTypes.string.isRequired
};

export default Deposit;
