export const LOADING_ERROR = 'LOADING_ERROR';

export const DEPOOLS_LOAD = 'DEPOOLS_LOAD';

export const DEPOOL_LOAD = 'DEPOOL_LOAD';

export const PARTICIPANT_DEPOOLS_LOAD = 'PARTICIPANT_DEPOOLS_LOAD';

export const MY_DEPOOL_LOAD = 'MY_DEPOOL_LOAD';

export const BALANCE_LOAD = 'BALANCE_LOAD';

export const DEPOOLS_DECODE = 'DEPOOLS_DECODE';

export const SET_DEPOOLS = 'SET_DEPOOLS';

export const SET_DEPOOL = 'SET_DEPOOL';

export const SET_PARTICIPANT_DEPOOLS = 'SET_PARTICIPANT_DEPOOLS';

export const SET_DEPOSITED_DEPOOL = 'SET_DEPOSITED_DEPOOL';

export const SET_MY_ADDRESS = 'SET_MY_ADDRESS';

export const SET_MY_TX_STAT = 'SET_MY_TX_STAT';

export const SET_BALANCE = 'SET_BALANCE';

export const SET_OVERVIEW = 'SET_OVERVIEW';

export const SET_DEPOOLS_STATISTICS = 'SET_DEPOOLS_STATISTICS';

export const SET_DEPOOL_ELECTION_REWARDS = 'SET_DEPOOL_ELECTION_REWARDS';

export const SET_DEPOOLS_LOADED = 'SET_DEPOOLS_LOADED';

export const WALLET_CONNECT_POPUP = 'WALLET_CONNECT_POPUP';

export const DEPOOL_FILTER_APPLY = 'DEPOOL_FILTER_APPLY';

export const DEPOOL_SEARCHING = 'DEPOOL_SEARCHING';

export const FAVORITES_LOAD = 'FAVORITES_LOAD';

export const FAVORITE_STORE = 'FAVORITE_STORE';

export const SET_FAVORITES = 'SET_FAVORITES';

export const SET_FAVORITE = 'FAVORITE_SET';

export const STAKEHOLDERS_FILTER_APPLY = 'STAKEHOLDERS_FILTER_APPLY';

export const STAKEHOLDERS_SEARCHING = 'STAKEHOLDERS_SEARCHING';

export const MAIN_PAGE_SORTED = 'MAIN_PAGE_SORTED';

export const EXPLORER_SORTED = 'EXPLORER_SORTED';

export const SET_LOADED = 'SET_LOADED';

export const MY_DEPOOL_FILTER_APPLY = 'MY_DEPOOL_FILTER_APPLY';
