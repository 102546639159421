import React from 'react';
import PropTypes from 'prop-types';

const FormCheckBox = ({
  title,
  isChecked,
  onClick
}) => (
  <label className="checkbox" htmlFor="inputId">
    <input type="checkbox" onChange={onClick} checked={isChecked} id="inputId" />
    <div className="checkbox__box"><img src="/assets/svg/checkbox.svg" alt="" /></div>
    <div className="checkbox__txt">{title}</div>
  </label>
);

FormCheckBox.propTypes = {
  title: PropTypes.node,
  isChecked: PropTypes.bool,
  onClick: PropTypes.func
};

FormCheckBox.defaultProps = {
  title: null,
  isChecked: null,
  onClick: null
};

export default FormCheckBox;
