import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const Popup = ({
  onClose,
  children
}) => (
  <div className="swap-popup">
    <div className="swap-popup-overlay" />
    <div className="swap-popup__wrap">
      <button type="button" className="swap-popup-close" title="Close" onClick={onClose}>
        <img src="/assets/svg/close.svg" alt="" />
      </button>
      {children}
    </div>
  </div>
);

Popup.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.node
};

Popup.defaultProps = {
  onClose: null,
  children: null
};

export default Popup;
