import React from 'react';
import { useDispatch } from 'react-redux';

import { isChrome } from 'react-device-detect';

import Popup from 'components/_common/Broxus/Popup';

import { WALLET_CONNECT_POPUP } from 'constants/actions';

const WalletConnectPopup = () => {
  const dispatch = useDispatch();
  const isSupport = isChrome;

  const handleClose = () => {
    dispatch({ type: WALLET_CONNECT_POPUP, cantconnectview: false });
  };

  const handleClick = () => {
    window.open('https://chrome.google.com/webstore/detail/ton-crystal-wallet/cgeeodpfagjceefieflmdfphplkenlfk', '_blank');
  };

  return (
    <Popup onClose={handleClose}>
      <h2 className="swap-popup-title">Connect to a wallet</h2>
      <div className="swap-popup-main">
        <div className="swap-popup-main__ava">
          <img src="/assets/svg/ton-ava.svg" alt="" />
        </div>
        <div className="swap-popup-main__nane">Crystal Wallet</div>
      </div>
      {isSupport && (
        <React.Fragment>
          <div className="swap-popup-txt">
            <p>At the moment, Crystal Wallet is necessary in order to complete transactions on EVER Pools.</p>
            <p>
              If you haven&#39;t installed the wallet extension yet, you can do so here:
            </p>
          </div>
          <button type="button" onClick={handleClick} className="btn btn--grey btn-block swap-popup-btn">Install Crystal Wallet</button>
        </React.Fragment>
      )}
      {!isSupport && (
        <div className="swap-popup-txt">
          <p>At the moment, Crystal Wallet is necessary in order to complete transactions on EVER Pools.</p>
          <p>Open in Chrome browser on desktop.</p>
        </div>
      )}
    </Popup>
  );
};

export default WalletConnectPopup;
