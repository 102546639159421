import React from 'react';
import PropTypes from 'prop-types';

const Section = ({ title, children }) => (
  <React.Fragment>
    <div className="swap-rounds-info">
      <h3 className="swap-rounds-info__title">{title}</h3>
      {children}
    </div>
  </React.Fragment>
);

Section.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node
};

Section.defaultProps = {
  children: null
};

export default Section;
