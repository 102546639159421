import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const Icons = {
  info: <img src="/assets/svg/info.svg" alt="" />
};

const InfoList = ({ data }) => (
  <ul className="swap-rounds-info__list">
    {data.map((item) => (
      <li key={item.label} className="swap-rounds-info-group">
        <div className="swap-rounds-info-group__name">
          {item.label}
          <div className="swap-info" onClick={item.onClick}>
            <a href="#" className="swap-info__icn">
              {!item.onClick && Icons[item.hint]}
            </a>
          </div>
        </div>
        <div className="swap-rounds-info-group__val">
          {item.onClick && <div>{item.value}</div>}
          {!item.onClick && <div>{item.value}</div>}
        </div>
        <div className="swap-rounds-info-group__name">{item.rating}</div>
      </li>
    ))}
  </ul>
);

InfoList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.node,
    hint: PropTypes.string,
    onClick: PropTypes.func
  })).isRequired
};

export default InfoList;
