import React from 'react';
import PropTypes from 'prop-types';

const Slider = ({
  value,
  total,
  onChange,
  children
}) => {
  const handlePrevClick = () => {
    if (value <= 1 || !onChange)
      return;
    onChange(value - 1);
  };

  const handleNextClick = () => {
    if (value >= total || !onChange)
      return;
    onChange(value + 1);
  };

  const handleChange = (e) => {
    const p = !e.target.value ? 1 : parseInt(e.target.value, 10);
    if (!p || !onChange)
      return;
    onChange(p > total ? total : p);
  };

  const handleMaxClick = () => {
    onChange(total);
  };

  return (
    <div className="swap__header-bar">
      <div className="swap-swiper-bar">
        <button type="button" enabled={(value > 0).toString()} onClick={handlePrevClick} className="btn swap-swiper-bar__arrow">
          <img src="/assets/svg/arrow-left-swipe.svg" alt="" />
        </button>
        <div className="swap-swiper-bar__counter">
          <input
            type="text"
            value={!value ? 0 : value}
            onChange={handleChange}
            size="1"
          />
          of&nbsp;
          <span onClick={handleMaxClick}>{total}</span>
        </div>
        <button type="button" enabled={(value < total).toString()} onClick={handleNextClick} className="btn swap-swiper-bar__arrow">
          <img src="/assets/svg/arrow-right-swipe.svg" alt="" />
        </button>
      </div>
      {children}
    </div>
  );
};

Slider.propTypes = {
  value: PropTypes.number,
  total: PropTypes.number,
  onChange: PropTypes.func,
  children: PropTypes.node
};

Slider.defaultProps = {
  value: null,
  total: null,
  onChange: null,
  children: null
};

export default Slider;
