import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Header from 'components/_common/Broxus/Header';
import HeaderAction from 'components/_common/Broxus/Header/HeaderAction';

import ToolPanel from 'components/ToolPanel';
import history from 'utils/history';

const NavPanel = () => {
  const location = useLocation(),
    { myAddress } = useSelector((state) => state.config);

  return (
    <Header tools={<ToolPanel />}>
      <HeaderAction
        url="/explorer"
        onClick={() => history.push('/explorer')}
        disabled={location.pathname !== '/explorer'}
      >
        Explore
      </HeaderAction>
      {myAddress && (
        <HeaderAction
          url="/my"
          onClick={() => history.push('/my')}
          disabled={location.pathname !== '/my'}
        >
          My depools
        </HeaderAction>
      )}
      <HeaderAction
        url="https://en.wiki.everscale.network/DePool"
      >
        Depools for dummies
      </HeaderAction>
    </Header>
  );
};

export default NavPanel;
