import React from 'react';

import { isChrome } from 'react-device-detect';

import Popup from 'components/_common/Broxus/Popup';
import { useDispatch } from 'react-redux';
import history from 'utils/history';
import tonWalletExt from 'utils/tonWalletExt';
import { BALANCE_LOAD, FAVORITES_LOAD } from 'constants/actions';

const WalletLoginPopup = () => {
  const dispatch = useDispatch();
  const isSupport = isChrome;

  const handleClose = () => {
    history.push('/');
  };

  const handleLogin = async () => {
    const accountInteraction = await tonWalletExt.requestAccountInteraction();
    if (!accountInteraction?.address)
      return;

    dispatch({ type: FAVORITES_LOAD });
    dispatch({ type: BALANCE_LOAD, address: accountInteraction.address });
  };

  return (
    <Popup onClose={handleClose}>
      <h2 className="swap-popup-title">Connect to a wallet</h2>
      <div className="swap-popup-main">
        <div className="swap-popup-main__ava">
          <img src="/assets/svg/ton-ava.svg" alt="" />
        </div>
        <div className="swap-popup-main__nane">Crystal Wallet</div>
      </div>
      {isSupport && (
        <React.Fragment>
          <div className="swap-popup-txt">
            <p>This page requires authorized wallet.</p>
          </div>
          <button type="button" onClick={handleLogin} className="btn btn--grey btn-block swap-popup-btn">Login</button>
        </React.Fragment>
      )}
      {!isSupport && (
        <div className="swap-popup-txt">
          <p>At the moment, only Crystal Wallet supports EVER Swap.</p>
          <p>Open in Chrome browser on desktop.</p>
        </div>
      )}
    </Popup>
  );
};

export default WalletLoginPopup;
