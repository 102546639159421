import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Icons = {
  info: (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="9" strokeWidth="2" />
      <path d="M10 9V14.5" strokeWidth="2" />
      <path d="M10 5V7" strokeWidth="2" />
    </svg>
  ),
  deposit: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.167 2V15.3333M12.167 15.3333L17.167 10.3333M12.167 15.3333L7.16699 10.3333" strokeWidth="2" />
      <path d="M3 14.5V21.1667H21.3333V14.5" strokeWidth="2" />
    </svg>
  ),
  withdraw: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 15.334L12 3.00065M12 3.00065L7 8.00065M12 3.00065L17 8.00065" strokeWidth="2" />
      <path opacity="0.9" d="M2.83301 13.5V20.1667H21.1663V13.5" strokeWidth="2" />
    </svg>
  ),
  manage: (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.2048 16.8743C14.842 16.47 15.4191 15.977 15.9192 15.4117L17.7221 16.4689L19.3638 13.5816L17.5645 12.5267C17.7932 11.8175 17.9278 11.0653 17.953 10.2855L20 9.91899L19.4297 6.63575L17.3831 7.00224C17.1002 6.29044 16.7208 5.62856 16.2606 5.03374L17.5984 3.41499L15.0833 1.27214L13.7466 2.88952C13.0937 2.53427 12.3867 2.2687 11.6415 2.10935V0L8.35831 0V2.10935C7.61311 2.2687 6.90608 2.53427 6.25313 2.88952L4.91649 1.27214L2.40135 3.41506L3.73904 5.03382C3.27894 5.62863 2.89948 6.29059 2.61665 7.00232L0.570047 6.63583L0 9.91906L2.04667 10.2856C2.0719 11.0654 2.20654 11.8175 2.43531 12.527L0.635863 13.5818L2.27755 16.469L4.08054 15.4121C4.58048 15.9771 5.15761 16.4702 5.7949 16.8745L5.08321 18.8598L8.16854 19.9998L8.87926 18.0173C9.24553 18.0699 9.61957 18.0978 9.99992 18.0978C10.3803 18.0978 10.7544 18.07 11.1207 18.0173L11.8313 20L14.9165 18.8596L14.2048 16.8743ZM9.99977 15C7.23841 14.9999 5 12.7614 5 10C5 7.23858 7.23858 5 10 5C12.7614 5 15 7.23858 15 10C15 12.7615 12.7613 15.0001 9.99977 15Z" />
    </svg>
  )
};

const ActionContent = ({
  icon,
  isEnabled,
  isActive,
  onClick,
  children
}) => (
  <React.Fragment>
    {isEnabled && (
      <button type="button" onClick={onClick} className={classnames(['btn', 'swap-acc-table__button', isActive ? 'active' : null])}>
        {icon && Icons[icon]}
        {children}
      </button>
    )}
  </React.Fragment>
);

ActionContent.propTypes = {
  icon: PropTypes.string,
  isEnabled: PropTypes.bool,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node
};

ActionContent.defaultProps = {
  icon: null,
  isEnabled: true,
  isActive: false,
  onClick: null,
  children: null
};

export default ActionContent;
