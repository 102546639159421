import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ActionBar from 'components/_common/Broxus/ActionBar';
import PropTypes from 'prop-types';
import BigNumber from 'bignumber.js';

import API from 'utils/api';
import TonWalletExt from 'utils/tonWalletExt';

import { MY_DEPOOL_LOAD } from 'constants/actions';

const ZeroNum = new BigNumber(0);
const WaitingTimeout = 120000;

const Manage = ({
  id: address,
  stakeFee: depoolFee,
  myReward
}) => {
  const dispatch = useDispatch();

  const { myAddress } = useSelector((state) => state.config);
  const [value, setValue] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [all, setAll] = useState(false);
  const [transfering, setTransfering] = useState(false);

  useEffect(async () => {
    if (!value) {
      setDisabled(true);
      return;
    }

    const rewardNum = new BigNumber(myReward);
    const valueNum = new BigNumber(value).multipliedBy(10 ** 9);
    setDisabled(valueNum.isLessThan(ZeroNum)
      || rewardNum.isLessThan(valueNum));
  }, [value]);

  const handleHalf = () => {
    setValue(new BigNumber(myReward).dividedBy(10 ** 9).dividedBy(2).toString());
    setAll(false);
  };

  const handleFull = () => {
    setValue(new BigNumber(myReward).dividedBy(10 ** 9).toString());
    setAll(true);
  };

  const handleSetValue = (v) => {
    setValue(v.toString());
    setAll(false);
  };

  const handleChange = async () => {
    const withdrawValueNum = new BigNumber(value).multipliedBy(10 ** 9);
    const {
      reinvest: curReinvest,
      reward: curReward
    } = await API.getParticipantInfo({ address, participant: myAddress });

    setTransfering(true);
    let result;
    try {
      if (all && (curReinvest ?? true)) {
        result = await TonWalletExt.withdrawAll({
          addressFrom: myAddress,
          addressTo: address,
          amount: depoolFee
        });
      }
      else if (!all && withdrawValueNum.isLessThanOrEqualTo(curReward ?? '0')) {
        result = await TonWalletExt.withdrawPart({
          addressFrom: myAddress,
          addressTo: address,
          amount: depoolFee,
          withdrawValue: withdrawValueNum.toString()
        });
      }
    }
    catch {
      result = null;
    }

    if (!result) {
      setTransfering(false);
      return;
    }

    const untilTime = new Date(new Date().getTime() + WaitingTimeout);
    const timerId = setInterval(async () => {
      try {
        const {
          withdrawValue: newWithdrawal,
          reinvest: newReinvest
        } = await API.getParticipantInfo({ address, participant: myAddress });

        if ((!all && withdrawValueNum.isEqualTo(newWithdrawal))
          || (all && newReinvest) || (untilTime < new Date())) {
          clearInterval(timerId);
          setValue('');
          setTransfering(false);
          dispatch({ type: MY_DEPOOL_LOAD, participant: myAddress, address });
        }
      }
      catch {
        clearInterval(timerId);
        setTransfering(false);
      }
    }, 1000);
  };

  const handleCancel = async () => {
    setTransfering(true);
    try {
      await TonWalletExt.cancelWithdrawal({
        addressFrom: myAddress,
        addressTo: address,
        amount: depoolFee
      });
    }
    catch {
      setTransfering(false);
    }

    const untilTime = new Date(new Date().getTime() + WaitingTimeout);
    const timerId = setInterval(async () => {
      try {
        const {
          withdrawValue: newWithdrawal,
          reinvest: newReinvest
        } = await API.getParticipantInfo({ address, participant: myAddress });

        if ((ZeroNum.isEqualTo(newWithdrawal) && newReinvest) || (untilTime < new Date())) {
          clearInterval(timerId);
          setValue('');
          setTransfering(false);
          dispatch({ type: MY_DEPOOL_LOAD, participant: myAddress, address });
        }
      }
      catch {
        clearInterval(timerId);
        setTransfering(false);
      }
    }, 1000);
  };

  return (
    <ActionBar
      title="Withdrawal management"
      description="Only possible before the end of validation cycle"
      value={value}
      actionTitle="Change"
      onChangeValue={(v) => handleSetValue(v)}
      onHalf={handleHalf}
      onFull={handleFull}
      onApply={handleChange}
      onCancel={handleCancel}
      locked={disabled}
      disabled={transfering}
    />
  );
};

Manage.propTypes = {
  id: PropTypes.string.isRequired,
  stakeFee: PropTypes.string.isRequired,
  myReward: PropTypes.string
};

Manage.defaultProps = {
  myReward: null
};

export default Manage;
