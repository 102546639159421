import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import copy from 'clipboard-copy';

import List from 'components/_common/Broxus/List';
import Deposit from 'components/ExplorerPage/Depools/Deposit';
import Withdraw from 'components/ExplorerPage/Depools/Withdraw';
import FavoriteActionContent from 'components/_common/Broxus/List/FavoriteActionContent';
import { FAVORITE_STORE } from 'constants/actions';
import history from 'utils/history';

const Details = ({ item, action }) => (
  <React.Fragment>
    {action === 'deposit' && <Deposit {...item} />}
    {action === 'withdraw' && <Withdraw {...item} />}
  </React.Fragment>
);

Details.propTypes = {
  item: PropTypes.shape({}),
  action: PropTypes.string
};

Details.defaultProps = {
  item: null,
  action: null
};

const Depools = ({
  data,
  sortedBy,
  sortedAsc,
  onSorted
}) => {
  const dispatch = useDispatch();
  const { favorites } = useSelector((state) => state.config);

  const meta = [
    {
      action: 'favorite',
      content: <FavoriteActionContent />,
      isActive: (item) => favorites && favorites.includes(item.id),
      onClick: (item, event) => {
        dispatch({ type: FAVORITE_STORE, id: item.id, isFavorite: event.target.checked });
      }
    },
    {
      id: 'id',
      type: 'address',
      label: 'Address',
      image: (item) => item.imageUrl,
      title: (item) => item.name,
      onClick: (item) => history.push(`/depools/${item.id}`),
      onCopy: (item) => copy(item.id),
      onOpen: (item) => window.open(`https://everscan.io/accounts/${item.id}`, '_blank')
    },
    {
      id: 'validatorRewardFraction', type: 'percent', label: 'Fee', hideable: true
    },
    {
      id: 'apyPercent', type: 'percent', label: 'APY', hideable: true
    },
    {
      id: 'participantCount', type: 'numeric', label: 'Stakeholders', hideable: true
    },
    { id: 'stake', type: 'ton', label: 'TVL, EVER' },
    {
      label: 'Deposit',
      action: 'deposit',
      onGetEnabled: ({ poolClosed }) => !poolClosed
    },
    {
      label: 'Withdraw',
      action: 'withdraw',
      onGetEnabled: ({ poolClosed }) => !poolClosed
    }
  ];

  return (
    <List data={data} meta={meta} className="explore-depools-table" sortedBy={sortedBy} sortedAsc={sortedAsc} onSorted={onSorted}>
      <Details />
    </List>
  );
};

Depools.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  sortedBy: PropTypes.string,
  sortedAsc: PropTypes.bool,
  onSorted: PropTypes.func
};

Depools.defaultProps = {
  data: null,
  sortedBy: null,
  sortedAsc: null,
  onSorted: PropTypes.func
};

export default Depools;
