import {
  takeEvery, put, call, all
} from 'redux-saga/effects';
import API from 'utils/api';
import tonWalletExt from 'utils/tonWalletExt';

import BigNumber from 'bignumber.js';

import {
  DEPOOLS_LOAD,
  DEPOOL_LOAD,
  PARTICIPANT_DEPOOLS_LOAD,
  MY_DEPOOL_LOAD,
  DEPOOLS_DECODE,
  BALANCE_LOAD,
  FAVORITES_LOAD,
  LOADING_ERROR,
  SET_DEPOOLS,
  SET_DEPOOL,
  SET_MY_ADDRESS,
  SET_PARTICIPANT_DEPOOLS,
  SET_DEPOSITED_DEPOOL,
  SET_DEPOOLS_LOADED,
  SET_DEPOOL_ELECTION_REWARDS,
  SET_BALANCE,
  SET_OVERVIEW
} from 'constants/actions';

const elector = '-1:3333333333333333333333333333333333333333333333333333333333333333';

function* loadDepools() {
  try {
    yield put({ type: FAVORITES_LOAD });

    let useApiRewards = true;
    try {
      const overview = yield call(API.getOverview, {});

      yield put({
        type: SET_OVERVIEW,
        overview: overview.map((e) => ({
          date: new Date(e.dateTimestamp).toISOString(),
          apy: parseFloat(e.apy),
          tvl: e.tvl.toString()
        }))
      });
    }
    catch {
      useApiRewards = false;
    }

    if (useApiRewards) {
      const data = yield call(API.getDepoolsCache, {});

      const closedDepoolIds = [...new Set(data.filter((d) => d.poolClosed && d.proxies.filter((p) => !p).length > 0).map((d) => d.id))];
      const openDepools = data.filter((d) => !closedDepoolIds.includes(d.id));

      let closedDepools = [];
      const closedAccounts = yield all(closedDepoolIds.map((address) => call(API.getAccount, { address })));
      for (let i = 0; i < closedAccounts.length; i++) {
        let account = null;
        try {
          account = closedAccounts[i];

          if (account) {
            const depool = yield call(API.getDepoolInfo, { address: account.id, boc: account.boc });
            if (depool) {
              const balance = yield call(API.getDepoolBalance, { address: account.id, boc: account.boc });
              const item = data.filter((d) => d.id === account.id)[0];
              const result = {
                ...item,
                ...account,
                ...depool,
                balance
              };
              closedDepools = [...closedDepools, result];
            }
          }
        }
        catch { 
          // Do nothing
        }
      }
      yield put({ type: SET_DEPOOLS, data: [...openDepools, ...closedDepools], loaded: true });

      return;
    }

    let next = null;
    let count = 0;
    // eslint-disable no-await-in-loop
    while (true) {
      const loadingDepools = yield call(API.getDepools, { limit: 50, next });
      if (!loadingDepools || loadingDepools.length === 0) {
        yield put({ type: SET_DEPOOLS_LOADED, count });
        break;
      }

      count += loadingDepools.length;
      yield put({ type: DEPOOLS_DECODE, data: loadingDepools, needRewards: !useApiRewards });
      next = loadingDepools[loadingDepools.length - 1].id;

      if (loadingDepools.length < 50) {
        yield put({ type: SET_DEPOOLS_LOADED, count });
        break;
      }
    }
    // eslint-enable no-await-in-loop
  }
  catch (error) {
    yield put({ type: LOADING_ERROR, error });
  }
}

function* loadDepool({ id }) {
  try {
    let rounds = yield call(API.getDepoolsRoundsCache, { id });
    rounds.sort((a, b) => a.number - b.number);
    let index = 0;
    let lastNumber = 0;
    while (index < rounds.length) {
      if (rounds[index].number === lastNumber)
        rounds.splice(index, 1);
      else {
        lastNumber = rounds[index].number;
        index += 1;
      }
    }
    rounds = rounds.map((round) => {
      const totalStake = new BigNumber(round.validatorStake ?? '0')
        .plus(round.ordinaryStake ?? '0')
        .plus(round.vestingStake ?? '0')
        .plus(round.lockedStake ?? '0')
        .plus(round.unused ?? '0');
      let { ordinaryStake } = round;
      if (totalStake.lt(round.stake ?? 0)) {
        ordinaryStake = new BigNumber(round.stake ?? '0')
          .minus(totalStake)
          .plus(round.ordinaryStake ?? '0')
          .toString();
      }
      return {
        ...round,
        ordinaryStake
      };
    });
    const maxRound = Math.max(...rounds.filter((r) => new BigNumber(r.stake).gt(0)).map((r) => r.number));
    const total = rounds.filter((r) => r.number === (maxRound ?? 0) || r.number === (maxRound ?? 0) - 1)
      .map((r) => r.stake).reduce((a, e) => a.plus(e), new BigNumber('0'));

    const depool = yield call(API.getAccount, { address: id }); // TODO: change api to ext.
    const poolClosed = !depool || !depool.code_hash;
    if (poolClosed) {
      yield put({
        type: SET_DEPOOL,
        id,
        stake: total,
        poolClosed,
        balance: depool?.balance,
        rounds,
        participants: [],
        currentParticipants: []
      });

      return;
    }

    const participants = yield call(API.getDepoolsRoundsParticipantsCache, { id });
    const currentParticipantIds = (yield call(API.getParticipants, { address: id, boc: depool?.boc })).participants;
    const currentParticipants = yield all(
      Object.values(currentParticipantIds).map((pid) => call(API.getParticipantInfo,
        { address: id, boc: depool?.boc, participant: pid }))
    );

    yield put({
      type: SET_DEPOOL,
      id,
      poolClosed,
      balance: depool.balance,
      rounds,
      participants,
      currentParticipants: currentParticipantIds.map((pid, ind) => ({
        participantId: pid,
        ...currentParticipants[ind]
      }))
    });
  }
  catch (error) {
    yield put({ type: LOADING_ERROR, error });
  }
}

function getElectorRewards(messages) {
  messages.sort((a, b) => parseInt(b.created_at, 10) - parseInt(a.created_at, 10));

  const electorRewards = messages.reduce((a, m) => {
    const proxy = m.dst === elector ? m.src : m.dst;
    const lastInd = a.map((am, ind) => ({ ind, proxy: am.proxy }))
      .filter((am) => am.proxy === proxy).reduce((aa, am) => am.ind, -1);
    const last = lastInd >= 0 ? a[lastInd] : null;
    const dateUtc = new Date(m.created_at * 1000).toISOString();
    if (m.src === elector && (!last || last.outcomeValue))
      a.push({ incomeDateUtc: dateUtc, incomeValue: m.value, proxy });
    else if (m.src === elector)
      last.incomeValue = new BigNumber(last.incomeValue ?? 0).plus(m.value);
    else if (last) {
      last.outcomeDateUtc = dateUtc;
      if (!last.outcomeValue)
        last.outcomeValue = m.value;
      else
        last.outcomeValue = new BigNumber(last.outcomeValue).plus(m.Value).toString();
    }
    return a;
  }, [])
    .filter((m) => m.incomeValue && m.outcomeValue
      && new BigNumber(m.incomeValue).isGreaterThan(m.outcomeValue))
    .map((m) => ({
      ...m,
      reward: new BigNumber(m.incomeValue ?? 0).minus(m.outcomeValue ?? 0).toString()
    }));
  return electorRewards.map((m) => ({
    ...m,
    dateUtc: m.outcomeDateUtc,
    stake: m.outcomeValue,
    outcomeValue: new BigNumber(m.outcomeValue).toString(),
    incomeValue: new BigNumber(m.incomeValue).toString(),
    reward: m.reward,
    apy: parseFloat(new BigNumber(m.reward).multipliedBy(1000)
      .dividedBy(m.outcomeValue)
      .multipliedBy(3600 * 24 * 365)
      .dividedBy(Math.abs(new Date(m.incomeDateUtc) - new Date(m.outcomeDateUtc)))
      .toFixed(10))
  })).filter((m) => m.apy < 1 && m.apy > 0);
}

function* loadElectorMessages({ messages, depool }) {
  let depoolMessages = messages;
  const depoolAddresses = [elector, ...depool.proxies];
  let next = depoolMessages[depoolMessages.length - 1]?.created_at;
  // eslint-disable no-await-in-loop
  while (true) {
    const loaded = yield call(API.getElectorMessages, { addresses: depoolAddresses, next });
    if (!loaded || loaded.length === 0)
      break;

    depoolMessages = depoolMessages.concat(loaded);
    next = loaded[loaded.length - 1].created_at;

    if (loaded.length < 50)
      break;
  }
  // eslint-enable no-await-in-loop

  const electorRewards = getElectorRewards(depoolMessages);
  if (electorRewards.length > 0) {
    yield put({
      type: SET_DEPOOL_ELECTION_REWARDS,
      depool: { id: depool.id, electorRewards, electorRewardsLoaded: true }
    });
  }
}

function* decodeDepools({ data, needRewards }) {
  const info = data.map(({ id, boc }) => call(API.getDepoolInfo, { address: id, boc }));
  const participants = data.map(({ id, boc }) => call(API.getParticipants, { address: id, boc }));
  const rounds = data.map(({ id, boc }) => call(API.getRounds, { address: id, boc }));
  const balance = data.map(({ id, boc }) => call(API.getDepoolBalance, { address: id, boc }));
  const results = yield all([...info, ...participants, ...rounds, ...balance]);

  for (let i = 0; i < data.length; i++) {
    data.splice(i, 1, {
      ...data[i],
      ...results[i],
      ...results[data.length + i],
      ...results[2 * data.length + i],
      balance: results[3 * data.length + i]?.value0
    });
  }

  yield put({ type: SET_DEPOOLS, data });

  if (!needRewards)
    return;

  data.sort((a, b) => (
    Math.max(...(b.rounds ? Object.keys(b.rounds) : [0])) - Math.max(...(a.rounds ? Object.keys(a.rounds) : [0]))
  ));
  yield call(loadElectorMessages, { depool: data[0], messages: [] });
  data.splice(0, 1);

  const dataMessages = data.reduce((a, e) => ({ ...a, [e.id]: [] }), {});
  const batches = [];
  const batchLength = 8;
  for (let i = 0; i < Math.ceil(data.length * 1.0 / batchLength); i++) {
    batches.push([elector]
      .concat(data.filter((d, ind) => ind >= i * 5 && ind < (i + 1) * batchLength - 1).flatMap((d) => d.proxies)));
  }

  for (let i = 0; i < batches.length; i++) {
    const loaded = yield call(API.getElectorMessages, { addresses: batches[i] });

    loaded.forEach((m) => {
      let depool = null;
      if (m.src === elector)
        [depool] = data.filter((d) => d.proxies.includes(m.dst));
      else
        [depool] = data.filter((d) => d.proxies.includes(m.src));
      dataMessages[depool.id].push(m);
    });

    Object.keys(dataMessages).forEach((depoolId) => {
      const [depool] = data.filter((d) => d.id === depoolId);
      depool.electorRewards = getElectorRewards(dataMessages[depoolId]);
      depool.electorRewardsLoaded = false;
    });

    yield put({ type: SET_DEPOOLS, data: data.filter((d, ind) => ind >= i * 5 && ind < (i + 1) * batchLength - 1) });
  }

  const emptyRewards = data.filter((d) => !d.electorRewards?.length);

  for (let i = 0; i < emptyRewards.length; i++) {
    const depool = emptyRewards[i];
    yield call(loadElectorMessages, { depool, messages: dataMessages[emptyRewards[i].id] });
    data.splice(data.indexOf(depool), 1);
  }

  for (let i = 0; i < data.length; i++)
    yield call(loadElectorMessages, { depool: data[i], messages: dataMessages[data[i].id] });
}

function* loadParticipantDepools({ participant, data }) {
  try {
    if (!data)
      return;

    const depoolIds = data.map((e) => e.id);

    const reducer = async (acc, evt, depoolId) => {
      let val = acc;
      if (!val)
        val = {};
      const filteringIds = depoolId ? [depoolId] : depoolIds;
      for (let i = 0; i < evt.transactions.length; i++) {
        try {
          const tx = evt.transactions[i];
          if (tx.outMessages.length > 0) {
            if (!tx.aborted && tx.outMessages
              .filter((e) => e.dst && filteringIds.includes(e.dst.toString())).length > 0) {
              const key = tx.outMessages[0].dst.toString();
              if (!val[key])
                val[key] = {};
              if (!val[key].rounds)
                val[key].rounds = {};
              const roundIds = Object.keys(val[key].rounds);
              const round = roundIds.length > 0 ? Math.min(...roundIds) : -1;
              if (!val[key].rounds[round])
                val[key].rounds[round] = {};

              /* eslint-disable no-await-in-loop */
              const decoded = await API.decodeDepoolMessageBody({ body: tx.outMessages[0].body });
              /* eslint-enable no-await-in-loop */
              if (decoded?.name === 'addOrdinaryStake') {
                if (val[key].isOperationCompleted) {
                  delete val[key].isOperationCompleted;
                  if (!val[key].rounds[round].stakes)
                    val[key].rounds[round].stakes = [];
                  val[key].rounds[round].stakes.push({
                    stake: decoded.value.stake,
                    value: tx.outMessages[0].value,
                    fee: data.filter((e) => e.id === key).map((e) => e.stakeFee)[0],
                    dateUtc: new Date(tx.createdAt * 1000).toISOString(),
                    type: 'ordinary',
                    txId: tx.id.hash
                  });
                }
              }
              else if (decoded?.name === 'addVestingStake') {
                if (val[key].isOperationCompleted) {
                  delete val[key].isOperationCompleted;
                  if (!val[key].rounds[round].stakes)
                    val[key].rounds[round].stakes = [];
                  val[key].rounds[round].stakes.push({
                    stake: decoded.value.stake,
                    value: tx.outMessages[0].value,
                    dateUtc: new Date(tx.createdAt * 1000).toISOString(),
                    type: 'vesting',
                    txId: tx.id.hash
                  });
                }
              }
              else if (decoded?.name === 'addLockStake') {
                if (val[key].isOperationCompleted) {
                  delete val[key].isOperationCompleted;
                  if (!val[key].rounds[round].stakes)
                    val[key].rounds[round].stakes = [];
                  val[key].rounds[round].stakes.push({
                    stake: decoded.value.stake,
                    value: tx.outMessages[0].value,
                    dateUtc: new Date(tx.createdAt * 1000).toISOString(),
                    type: 'locked',
                    txId: tx.id.hash
                  });
                }
              }
              if (decoded?.name === 'withdrawFromPoolingRound') {
                if (val[key]?.rounds[round]?.stakes) {
                  const lastWithdrawal = val[key]?.rounds[round]?.stakes
                    .filter((s) => s.withdrawed && !s.ordered)[0];
                  if (lastWithdrawal)
                    lastWithdrawal.ordered = decoded.value.withdrawValue;
                }
              }
              else if (round === -1 && !val[key].lastWithdrawId && ['withdraw', 'withdrawPart'].includes(decoded?.name)) {
                val[key].lastWithdrawTxId = tx.id.hash;
                val[key].lastWithdrawDateUtc = new Date(tx.createdAt * 1000).toISOString();
              }
            }
          }
          else if (tx.inMessage.src && filteringIds.includes(tx.inMessage.src.toString())) {
            const key = tx.inMessage.src.toString();
            if (!val[key])
              val[key] = {};
            if (!val[key].rounds)
              val[key].rounds = {};
            let decoded = null;
            try {
              /* eslint-disable no-await-in-loop */
              decoded = await API.decodeDepoolParticipantMessageBody({ body: tx.inMessage.body });
              /* eslint-enable no-await-in-loop */
            }
            catch {
              // Withdraw
              if (new BigNumber(tx.inMessage.value).gte(1000000000)) {
                const round = Object.keys(val[key].rounds).length === 0 ? -1
                  : Math.max(...Object.keys(val[key].rounds));
                if (!val[key].rounds[round])
                  val[key].rounds[round] = {};
                if (!val[key].rounds[round].stakes)
                  val[key].rounds[round].stakes = [];
                val[key].rounds[round].stakes.push({
                  withdrawed: tx.inMessage.value,
                  txId: tx.id.hash,
                  dateUtc: new Date(tx.createdAt * 1000).toISOString()
                });
              }
            }
            if (decoded?.name === 'onRoundComplete') {
              const round = parseInt(decoded.value.roundId, 10);
              val[key].rounds[round] = {
                ...(val[key].rounds[round] ?? {}),
                ...decoded.value,
                dateUtc: new Date(tx.createdAt * 1000).toISOString(),
                withdrawed: tx.inMessage.value === '1' ? '0' : tx.inMessage.value
              };
              if (val[key].rounds[-1]) {
                val[key].rounds[round + 1] = val[key].rounds[-1];
                delete val[key].rounds[-1];
              }
            }
            else if (decoded?.name === 'receiveAnswer' && decoded.value?.errcode === '0') {
              if (!val[key])
                val[key] = {};
              val[key].isOperationCompleted = true;
            }
          }
        }
        catch {
          // Do nothing
        }
      }
      return val;
    };
    const finalizer = (result) => {
      const depoolsRounds = {};
      Object.keys(result).forEach((depoolKey) => {
        const depoolRounds = result[depoolKey];
        depoolsRounds[depoolKey] = {
          lastWithdrawTxId: depoolRounds.lastWithdrawTxId,
          lastWithdrawDateUtc: depoolRounds.lastWithdrawDateUtc
        };
        depoolsRounds[depoolKey].rounds = Object.keys(depoolRounds.rounds).reduce((a, key) => {
          const r = depoolRounds.rounds[key];
          const prevRounds = Object.keys(depoolRounds.rounds).filter((ri) => parseInt(ri, 10) < parseInt(key, 10) - 1);
          const prevRound = prevRounds.length === 0 ? null : depoolRounds.rounds[Math.max(...prevRounds)];
          let prevDate = prevRound?.dateUtc;
          if (!prevDate && r.stakes)
            prevDate = r.stakes[r.stakes.length - 1].dateUtc;
          return {
            ...a,
            [key]: {
              ...r,
              apy: !depoolRounds.rounds[key].reward ? null
                : parseFloat(new BigNumber(depoolRounds.rounds[key].reward).multipliedBy(1000)
                  .dividedBy(new BigNumber(r.ordinaryStake ?? '0').plus(r.vestingStake ?? '0').plus(r.lockedStake ?? '0'))
                  .multipliedBy(3600 * 24 * 365)
                  .dividedBy(Math.abs(new Date(r.dateUtc) - new Date(prevDate)))
                  .toFixed(10))
            }
          };
        }, {});
      });
      return depoolsRounds;
    };

    const participantDepools = yield call(API.getDepoolsByParticipantCache, { participant });

    const trans = yield call(tonWalletExt.getTransactions, { address: participant });
    const stats = !trans ? null : finalizer(yield reducer(null, { transactions: trans }));
    const processingDepoolIds = participantDepools
      .map(({ id }) => id).concat(Object.keys(stats ?? {}));
    const resultDepools = yield all([...new Set(processingDepoolIds)]
      .map(async (address) => {
        const acc = await API.getAccount({ address }); // TODO: change api to ext.
        if (!acc?.code_hash)
          return null;
        let depoolParticipants = null;
        try {
          depoolParticipants = await API.getParticipants({ address, boc: acc.boc });
        }
        catch (e) {
          console.log(`invalid address ${address}`, acc);
          return null; // Invalid boc of address
        }
        if (depoolParticipants?.participants && !depoolParticipants.participants.includes(participant))
          return null;
        const result = await API.getParticipantInfo({
          address,
          boc: acc.boc,
          participant
        });
        if (!result)
          return null;
        return {
          id: address,
          depool: result
        };
      }));

    let updatingDepools = resultDepools.filter((e) => e).map((d) => ({
      id: d.id,
      rounds: (!stats ? null : stats[d.id])?.rounds ?? [],
      depool: !stats ? d.depool : {
        ...(d.depool ?? {}),
        withdrawTxId: stats[d.id]?.lastWithdrawTxId,
        withdrawDateUtc: !stats[d.id]?.lastWithdrawDateUtc ? null
          : new Date(stats[d.id].lastWithdrawDateUtc).getTime() / 1000
      }
    }));
    updatingDepools = yield all(updatingDepools.map(async (d) => {
      const result = await reducer(null, { transactions: trans }, d.id);
      const transactions = result[d.id];
      if (!transactions.rounds[-1]?.stakes)
        return d;
      let total = new BigNumber(d.total ?? 0).plus(
        transactions.rounds[-1].stakes
          .reduce((a, e) => a.plus(e.stake ?? 0).minus(e.withdrawed ?? 0), new BigNumber(0))
      );
      if (total.lt(0))
        total = 0;
      return {
        ...d,
        total: total.toString()
      };
    }));
    if (stats) {
      const emptyData = Object.keys(stats).filter((id) => !updatingDepools.filter((d) => d.id === id).length);
      const lastDepools = yield all(emptyData.map(async (id) => {
        const result = await reducer(null, { transactions: trans }, id);
        const transactions = result[id];
        if (transactions.rounds[-1] && !transactions.rounds[-1].stakes)
          return null;
        let total = 0;
        if (transactions.rounds[-1]?.stakes) {
          total = transactions.rounds[-1].stakes
            .reduce((a, e) => a.plus(e.stake ?? 0).minus(e.withdrawed ?? 0), new BigNumber(0));
          if (total.lt(0))
            total = 0;
        }
        return {
          id,
          rounds: (!stats ? null : stats[id])?.rounds ?? [],
          depool: {
            total: total.toString(),
            withdrawTxId: stats[id]?.lastWithdrawTxId,
            withdrawDateUtc: stats[id]?.lastWithdrawDateUtc
          }
        };
      }));
      updatingDepools = updatingDepools.concat(lastDepools.filter((e) => e));
    }

    yield put({
      type: SET_PARTICIPANT_DEPOOLS,
      participant,
      data: updatingDepools
    });
  }
  catch (error) {
    yield put({ type: LOADING_ERROR, error });
  }
}

function* loadMyDepool({ participant, address, boc }) {
  try {
    const { state } = yield call(tonWalletExt.getContractDetails, { address });
    let depool = {
      id: address,
      balance: state.balance,
      boc: state.boc
    };
    if (boc)
      depool.boc = boc;
    const results = yield all([
      call(API.getDepoolInfo, { address, boc: depool.boc }),
      call(API.getParticipants, { address, boc: depool.boc }),
      call(API.getRounds, { address, boc: depool.boc }),
      call(API.getDepoolBalance, { address, boc: depool.boc }),
      call(API.getParticipantInfo, { address, boc: depool.boc, participant })
    ]);
    depool = {
      ...depool,
      ...results[0],
      ...results[1],
      ...results[2],
      balance: results[3]?.value0,
      my: { ...results[4] }
    };

    yield put({ type: SET_DEPOSITED_DEPOOL, depool });
  }
  catch (error) {
    yield put({ type: LOADING_ERROR, error });
  }
}

function* loadBalance({ address }) {
  try {
    yield put({ type: SET_MY_ADDRESS, address });

    const { state } = yield call(tonWalletExt.getContractDetails, { address });

    yield put({ type: SET_BALANCE, address, balance: state.balance });
  }
  catch (error) {
    yield put({ type: LOADING_ERROR, error });
  }
}

export default function* depools() {
  yield takeEvery(DEPOOLS_LOAD, loadDepools);
  yield takeEvery(DEPOOL_LOAD, loadDepool);
  yield takeEvery(PARTICIPANT_DEPOOLS_LOAD, loadParticipantDepools);
  yield takeEvery(MY_DEPOOL_LOAD, loadMyDepool);
  yield takeEvery(BALANCE_LOAD, loadBalance);
  yield takeEvery(DEPOOLS_DECODE, decodeDepools);
}
