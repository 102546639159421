import React, { useState } from 'react';
import AddressAvatar from 'components/_common/AddressAvatar';

import PropTypes from 'prop-types';
import classnames from 'classnames';

import { tonf, shortaddrf } from 'utils/format';

import './index.scss';
import { BigNumber } from 'bignumber.js';

const getDateUtc = (dateAt) => {
  if (!dateAt || !Number.isFinite(dateAt))
    return null;
  const date = new Date(dateAt * 1000);
  if (Object.prototype.toString.call(date) !== '[object Date]')
    return null;
  const datePart = date.toLocaleDateString();
  let timePart = date.toLocaleTimeString().substring(0, 5);
  if (timePart[4] === ':')
    timePart = timePart.substring(0, 4);
  return `${datePart} ${timePart}`;
};

const TableRow = ({
  meta,
  item,
  children
}) => {
  const [action, setAction] = useState('');

  const views = {
    address: (v) => shortaddrf(v),
    ton: (v) => tonf(v, { fixed: 0 }),
    percent: (v) => `${parseFloat(new BigNumber(v).toFixed(2))}%`,
    datetime: (v) => getDateUtc(v)
  };

  const handleAction = (event, value) => {
    const metaItem = meta.filter((m) => m.action === value)[0];
    if (metaItem?.onClick) {
      metaItem?.onClick(item, event);
      return;
    }

    if (action === value)
      setAction('');
    else
      setAction(value);
  };

  const checkActionActive = (value) => {
    const metaItem = meta.filter((m) => m.action === value)[0];
    if (metaItem?.isActive)
      return metaItem?.isActive(item);

    return action === value;
  };

  const cloneChildren = () => React.Children.map(children, (child) => {
    if (React.isValidElement(child))
      return React.cloneElement(child, { item, action });
    return child;
  });

  const cloneActionContent = ({
    content,
    onGetEnabled,
    isActive,
    onClick
  }) => React.Children.map(content, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        isEnabled: onGetEnabled ? onGetEnabled(item) : true,
        isActive,
        onClick
      });
    }
    return child;
  });

  const alignClass = (e) => {
    if (['numeric', 'percent', 'ton', 'datetime'].includes(e.type))
      return 'swap-table-col--align-right';
    return 'swap-table-col--align-left';
  };

  const startedInlineAction = !!meta[0].action && !meta[0].label;
  const startedIndex = startedInlineAction ? 1 : 0;
  const rowStart = {
    prefix: startedInlineAction ? meta[0] : null,
    data: meta[startedIndex],
    meta: meta[startedIndex]
  };
  const nameValue = rowStart.data.view ? rowStart.data.view(item[rowStart.data.id], rowStart.data.view, item)
    : (views[rowStart.data.type] ?? ((v) => v))(item[rowStart.data.id], rowStart.data.view);
  const titleValue = rowStart.data.title ? rowStart.data.title(item) : null;
  rowStart.image = rowStart.data.image ? rowStart.data.image(item) : null;
  if (titleValue) {
    rowStart.name = titleValue;
    rowStart.text = nameValue;
  }
  else
    rowStart.name = nameValue;

  const handleRowStartOnClick = () => {
    if (!rowStart?.meta?.onClick)
      return;
    rowStart.meta.onClick(item);
  };

  const handleItemValueClick = (e) => {
    if (!e.onClick)
      return;
    e.onClick(item);
  };

  const isClickableRowStart = !!rowStart?.meta?.onClick;

  return (
    <React.Fragment>
      <div className="swap-table__row">
        <div className={classnames(['swap-table-col', alignClass(rowStart.data)])}>
          <div className="swap-table-col__row cust-table-col__row--justify">
            <div className="swap-table-col__row cust-table-col__row--left">
              {rowStart.prefix && cloneActionContent({
                content: rowStart.prefix.content,
                onGetEnabled: rowStart.prefix.onGetEnabled,
                isActive: checkActionActive(rowStart.prefix.action),
                onClick: (event) => handleAction(event, rowStart.prefix.action)
              })}
              <div className="token">
                {rowStart.data.type === 'address' && (
                  <div className="token__icon">
                    {rowStart.image && <img src={rowStart.image} alt="" />}
                    {!rowStart.image && <AddressAvatar address={item[rowStart.data.id]} small />}
                  </div>
                )}
                {rowStart.meta.hasAvatar && (
                  <div className="token__icon">
                    <AddressAvatar number={item[rowStart.data.id]} small />
                  </div>
                )}
                <div className="token__main">
                  <div onClick={handleRowStartOnClick} className={classnames(['token__name', isClickableRowStart ? 'clickable' : null])}>
                    {rowStart.name}
                  </div>
                  {rowStart.text && (
                    <div className="token__txt">
                      {rowStart.text}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {(rowStart.meta.onCopy || rowStart.meta.onOpen || rowStart.meta.onLink) && (
              <div className="swap-table-tools">
                {rowStart.meta.onCopy && (
                  <button onClick={() => rowStart.meta.onCopy(item)} type="button" className="swap-table-tool">
                    <img src="/assets/svg/copy-tool.svg" alt="" />
                  </button>
                )}
                {rowStart.meta.onOpen && (
                  <button onClick={() => rowStart.meta.onOpen(item)} type="button" className="swap-table-tool">
                    <img src="/assets/svg/link-tool.svg" alt="" />
                  </button>
                )}
                {rowStart.meta.onLink && (
                  <button onClick={() => rowStart.meta.onLink(item)} type="button" className="swap-table-tool">
                    <img src="/assets/svg/chain-tool.svg" alt="" />
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
        {meta.filter((e, ind) => ind > startedIndex && !e.action).map((e) => (
          <div key={e.id} className={classnames(['swap-table-col', alignClass(e)])}>
            <div className="swap-table-col__hide-title">{e.label?.replace('\n', ' ')}</div>
            <div className={classnames(['swap-table-col__val', e.onClick ? 'clickable' : null])} onClick={() => handleItemValueClick(e)}>
              {e.view ? (e.view(item[e.id], e.view, item))
                : (views[e.type] ?? ((v) => v))(item[e.id], e.view)}
            </div>
            {(e.onCopy || e.onOpen || e.onLink) && (
              <div className="swap-table-tools">
                {e.onCopy && (
                  <button onClick={() => e.onCopy(item)} type="button" className="swap-table-tool">
                    <img src="/assets/svg/copy-tool.svg" alt="" />
                  </button>
                )}
                {e.onOpen && (
                  <button onClick={() => e.onOpen(item)} type="button" className="swap-table-tool">
                    <img src="/assets/svg/link-tool.svg" alt="" />
                  </button>
                )}
                {e.onLink && (
                  <button onClick={() => e.onLink(item)} type="button" className="swap-table-tool">
                    <img src="/assets/svg/chain-tool.svg" alt="" />
                  </button>
                )}
              </div>
            )}
          </div>
        ))}
        {meta.filter((e, ind) => ind > startedIndex && e.action).map((e) => (
          <div key={e.action} className="swap-table-col swap-table-col--align-center">
            {cloneActionContent({
              content: e.content,
              onGetEnabled: e.onGetEnabled,
              isActive: checkActionActive(e.action),
              onClick: (event) => handleAction(event, e.action)
            })}
          </div>
        ))}
      </div>
      {children && action && (
        <div className="swap-acc-table-frame">
          {cloneChildren()}
        </div>
      )}
    </React.Fragment>
  );
};

TableRow.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired,
  meta: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    label: PropTypes.string,
    content: PropTypes.node,
    action: PropTypes.string,
    onGetEnabled: PropTypes.func,
    onCopy: PropTypes.func,
    onOpen: PropTypes.func,
    onLink: PropTypes.func,
    hasAvatar: PropTypes.bool
  })).isRequired,
  children: PropTypes.node
};

TableRow.defaultProps = {
  children: null
};

export default TableRow;
