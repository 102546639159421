import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Section from 'components/_common/Broxus/Section';
import ChartBar from 'components/_common/Broxus/ChartBar';
import InlineSearchBar from 'components/_common/Broxus/InlineSearchBar';
import Overview from 'components/Overview';
import Depools from 'components/MainPage/Depools';
import history from 'utils/history';

import {
  PARTICIPANT_DEPOOLS_LOAD,
  DEPOOL_FILTER_APPLY,
  MAIN_PAGE_SORTED,
  SET_LOADED,
  DEPOOL_SEARCHING
} from 'constants/actions';
import { mainPageFilter } from 'constants/depools';

import statistics from 'utils/statistics';
import { BigNumber } from 'bignumber.js';

const getTotalStats = (items, overviewStatistics) => [
  { label: 'TVL, EVER', type: 'ton', value: statistics.getTvlLast({ overview: overviewStatistics }) },
  { label: 'Current APY', value: `${(statistics.getApyAverage({ depools: items }) * 100).toFixed(1)}%` },
  { label: 'Depools', value: items?.filter((e) => !e.poolClosed)?.length ?? 0 },
  { label: 'Stakeholders', value: statistics.getParticipantsTotal({ depools: items }) }
];

const getTotalCharts = ({ overview }) => [
  { label: 'TVL', ...statistics.getOverviewStakesChart({ overview }) },
  { label: 'APY', ...statistics.getOverviewApysChart({ overview }) }
];

const MainPage = () => {
  const dispatch = useDispatch();
  const [totalStats, setTotalStats] = useState(getTotalStats([], []));
  const [totalCharts, setTotalCharts] = useState(getTotalCharts({}));
  const [filteredDepools, setFilteredDepools] = useState([]);

  const {
    depools,
    depoolsLoaded,
    myAddress,
    depoolsRewardLoaded,
    overview,
    search,
    mainPageSorted
  } = useSelector((state) => state.config);

  useEffect(() => {
    dispatch({ type: SET_LOADED, loaded: depoolsLoaded });
  }, [depoolsLoaded]);

  useEffect(() => {
    const stats = getTotalStats(depools, overview);
    const depoolsItem = stats.filter((e) => e.label === 'Depools')[0];
    if (depoolsItem) {
      depoolsItem.onClick = () => {
        dispatch({
          type: DEPOOL_FILTER_APPLY,
          poolClosed: false
        });
        history.push('/explorer');
      };
    }
    setTotalStats(stats);
  }, [depools, depoolsRewardLoaded, depoolsLoaded]);

  useEffect(() => {
    setTotalCharts(getTotalCharts({ overview }));
  }, [overview, depools, depoolsRewardLoaded, depoolsLoaded]);

  useEffect(async () => {
    if (!myAddress || !depoolsLoaded)
      return;

    dispatch({ type: PARTICIPANT_DEPOOLS_LOAD, participant: myAddress, data: depools });
  }, [myAddress, depools, depoolsLoaded]);

  const getQuery = () => {
    let query = [];
    if (search)
      query.push(`search=${encodeURIComponent(search)}`);
    if (mainPageSorted?.sortedBy)
      query.push(`sorted_by=${mainPageSorted.sortedBy}&sorted_asc=${mainPageSorted.sortedAsc}`);
    if (mainPageSorted?.sortedBy === 'stake' && !mainPageSorted.sortedAsc)
      query = '';
    return query.length !== 0 ? `/?${query.reduce((a, e) => (!a ? e : `${a}&${e}`), '')}` : '/';
  };

  const currentQuery = window.location.pathname + window.location.search;
  const changeQuery = () => {
    if (window.location.pathname !== '/')
      return;
    const query = getQuery();
    if (query !== currentQuery)
      history.push(query);
  };

  useEffect(() => {
    if (currentQuery === '/')
      changeQuery();
  });

  useEffect(() => {
    let filtered = depools;
    if (!filtered)
      return;

    if (search)
      filtered = depools.filter((e) => e.id.includes(search));
    else {
      filtered = depools.filter((e) => !e.poolClosed
        && e.validatorRewardFraction < mainPageFilter.validatorRewardFraction
        && new BigNumber(e.stake).gte(mainPageFilter.stake)
        && e.maxSupposedElectedAt
        && e.maxSupposedElectedAt >= mainPageFilter.minElectedAt);
    }

    setFilteredDepools(filtered);
    changeQuery();
  }, [depools, search]);

  useEffect(() => {
    changeQuery();
  }, [mainPageSorted]);

  const handleSorted = (sorted) => {
    dispatch({
      type: MAIN_PAGE_SORTED,
      ...sorted
    });
  };

  const handleSearchChange = (event) => {
    dispatch({ type: DEPOOL_SEARCHING, search: event.target.value });
  };

  return (
    <React.Fragment>
      <Section title="Overview">
        <Overview key="total" statistics={totalStats}>
          <ChartBar data={totalCharts} />
        </Overview>
      </Section>
      <Section title="Depools" tool={<InlineSearchBar search={search} onSearch={handleSearchChange} />} comment={!depoolsLoaded ? ' loading...' : null}>
        <Depools key="total" data={filteredDepools} {...mainPageSorted} onSorted={handleSorted} />
      </Section>
    </React.Fragment>
  );
};

export default MainPage;
