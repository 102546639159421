import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './index.scss';

const SideBar = ({ data, children }) => (
  <div className="swap-container swap-graph-sec">
    <div className="swap-graph-sec__main">
      <div className="swap-graph-sec__bar">
        {data.map((s) => (
          <div key={s.label} onClick={s.onClick} className={classnames(['swap-graph-sec__item', s.onClick ? 'clickable' : null])}>
            <div className="swap-graph-sec__item-title">
              {s.label}
              {s.onClick && (
                <React.Fragment>
                  { '\u00A0' }
                  &gt;
                </React.Fragment>
              )}
            </div>
            <div className="swap-graph-sec__item-val">{s.value}</div>
            {s.comment && <div className={classnames(['swap-dynamic-val', s.commentHint])}>{s.comment}</div>}
          </div>
        ))}
      </div>
      {children}
    </div>
  </div>
);

SideBar.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.node,
    comment: PropTypes.node,
    commentHint: PropTypes.string
  })).isRequired,
  children: PropTypes.node
};

SideBar.defaultProps = {
  children: null
};

export default SideBar;
