import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './index.scss';

const ExpandForm = ({
  title,
  count,
  onApply,
  onClear,
  children
}) => {
  const divRef = useRef();
  const [isDropUp, setIsDropUp] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const handleApply = () => {
    if (onApply)
      onApply();

    setShowForm(false);
  };

  const handleClear = () => {
    if (onClear)
      onClear();
  };

  const divRest = () => {
    const rect = divRef.current.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const top = rect.top + scrollTop;
    return document.body.offsetHeight - top;
  };

  const handleExpandClick = () => {
    setIsDropUp(divRest() < 430);
    setShowForm(!showForm);
  };

  return (
    <div className="filter" ref={divRef}>
      <button onClick={handleExpandClick} type="button" className="btn btn--grey filter-btn">
        <span className="filter-btn__txt">{title}</span>
        <span className="filter-btn__numb">{count}</span>
      </button>
      <div className={classnames(['filter-blc', isDropUp ? 'dropup' : null, showForm ? 'open' : null])}>
        <div className="filter-blc__main">
          <div className="filter-blc__wrap">
            <h2 className="filter-blc__title">{title}</h2>
            {children}
          </div>
        </div>
        <div className="filter-blc__footer">
          {onClear && <button onClick={handleClear} type="button" className="btn btn--grey btn-s">Clear</button>}
          {onApply && <button onClick={handleApply} type="button" className="btn btn-light btn-s">Apply</button>}
        </div>
      </div>
      <div className="filter-overlay" onClick={() => setShowForm(false)} />
    </div>
  );
};

ExpandForm.propTypes = {
  title: PropTypes.node,
  count: PropTypes.node,
  onApply: PropTypes.func,
  onClear: PropTypes.func,
  children: PropTypes.node
};

ExpandForm.defaultProps = {
  title: null,
  count: null,
  onApply: null,
  onClear: null,
  children: null
};

export default ExpandForm;
