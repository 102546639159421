import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const ActionBar = ({
  title,
  description,
  value,
  actionTitle,
  onChangeValue,
  onHalf,
  onFull,
  onApply,
  onCancel,
  locked,
  disabled
}) => {
  const handleChange = (evt) => {
    let verifiedValue = evt.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
    if (verifiedValue.includes('.') && (verifiedValue.split('.')[1].length > 9))
      verifiedValue = parseFloat(verifiedValue).toFixed(9);
    onChangeValue(verifiedValue);
  };

  return (
    <React.Fragment>
      <h3 className="swap-acc-table-frame__title">
        {title}
      </h3>
      <p className="swap-acc-table-frame__txt">
        {description}
      </p>
      <div className="swap-acc-table-frame__form">
        <div className="swap-amount">
          <input type="text" className="swap-amount__input" placeholder="Amount..." value={value} onChange={handleChange} disabled={disabled} />
          {(onHalf || onFull) && (
            <div className="swap-amount__tools">
              {onHalf && <button onClick={onHalf} className="btn swap-amount__btn" type="button" disabled={disabled}>Half</button>}
              {onFull && <button onClick={onFull} className="btn swap-amount__btn" type="button" disabled={disabled}>Max</button>}
            </div>
          )}
        </div>
        {onApply && <button onClick={onApply} className="btn btn-light btn-s swap-acc-table-frame__submit" type="button" disabled={locked || disabled}>{actionTitle}</button>}
        {onCancel && <button onClick={onCancel} className="btn btn-dark btn-s swap-acc-table-frame__submit" type="button" disabled={disabled}>Cancel</button>}
      </div>
    </React.Fragment>
  );
};

ActionBar.propTypes = {
  title: PropTypes.string,
  description: PropTypes.node,
  value: PropTypes.node,
  actionTitle: PropTypes.string,
  onChangeValue: PropTypes.func,
  onHalf: PropTypes.func,
  onFull: PropTypes.func,
  onApply: PropTypes.func,
  onCancel: PropTypes.func,
  locked: PropTypes.bool,
  disabled: PropTypes.bool
};

ActionBar.defaultProps = {
  title: null,
  description: null,
  value: null,
  actionTitle: null,
  onChangeValue: null,
  onHalf: null,
  onFull: null,
  onApply: null,
  onCancel: null,
  locked: true,
  disabled: true
};

export default ActionBar;
