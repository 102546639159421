import React, { forwardRef, useEffect, useState } from 'react';
import { BigNumber } from 'bignumber.js';
import copy from 'clipboard-copy';
import PropTypes from 'prop-types';

import { getSearchString } from '../../../utils/url';
import Info from '../../_common/Broxus/AnaliticsBar/Info';
import Slider from '../../_common/Broxus/AnaliticsBar/Slider';
import Toggle from '../../_common/Broxus/AnaliticsBar/Toggle';
import Section from '../../_common/Broxus/Section';
import RoundsBars from '../RoundsBars';
import RoundsList from '../RoundsList';
import FilterPanel from '../RoundsList/FilterPanel';

const Rounds = forwardRef(({
  data,
  currentRound
}, ref) => {
  const defaultRound = getSearchString(window.location.search, 'round');

  const [selectedRoundNum, setSelectedRoundNum] = useState(parseInt(defaultRound, 10));
  const [isRoundsList, setIsRoundsList] = useState(false);
  const [filter, setFilter] = useState({});
  const [filteredRounds, setFilteredRounds] = useState(data);
  const [maxRoundNum, setMaxRoundNum] = useState();

  useEffect(() => {
    let filtered = data;
    if (filter.numberFrom)
      filtered = filtered.filter((e) => e.number >= filter.numberFrom);
    if (filter.numberTo)
      filtered = filtered.filter((e) => e.number <= filter.numberTo);
    if (filter.stakeFrom)
      filtered = filtered.filter((e) => new BigNumber(e.stake).dividedBy(10 ** 9).gte(filter.stakeFrom));
    if (filter.stakeTo)
      filtered = filtered.filter((e) => new BigNumber(e.stake).dividedBy(10 ** 9).lte(filter.stakeTo));
    if (filter.unusedFrom)
      filtered = filtered.filter((e) => new BigNumber(e.unused).dividedBy(10 ** 9).gte(filter.unusedFrom));
    if (filter.unusedTo)
      filtered = filtered.filter((e) => new BigNumber(e.unused).dividedBy(10 ** 9).lte(filter.unusedTo));
    if (filter.participantRewardFrom) {
      filtered = filtered.filter((e) => new BigNumber(e.participantReward)
        .dividedBy(10 ** 9).gte(filter.participantRewardFrom));
    }
    if (filter.participantRewardTo) {
      filtered = filtered.filter((e) => new BigNumber(e.participantReward)
        .dividedBy(10 ** 9).lte(filter.participantRewardTo));
    }
    if (filter.participantQtyFrom)
      filtered = filtered.filter((e) => e.participantQty >= filter.participantQtyFrom);
    if (filter.participantQtyTo)
      filtered = filtered.filter((e) => e.participantQty <= filter.participantQtyTo);
    if (filter.apyFrom)
      filtered = filtered.filter((e) => e.apy >= filter.apyFrom);
    if (filter.apyTo)
      filtered = filtered.filter((e) => e.apy <= filter.apyTo);
    setFilteredRounds(filtered);
    setMaxRoundNum(!data?.length ? 0 : Math.max(...data.map((e) => e.number)));
  }, [filter, data]);

  useEffect(() => {
    if (currentRound)
      setSelectedRoundNum(currentRound);
  }, [currentRound]);

  const handleRoundLink = () => {
    copy(`${window.location.protocol}//${window.location.host}${window.location.pathname}?round=${selectedRoundNum}`);
  };

  const handleFilterChanged = (e) => {
    setFilter({
      ...filter,
      ...e
    });
  };

  const roundInfo = isRoundsList ? null
    : (
      <Info
        value={!selectedRoundNum ? 0 : selectedRoundNum}
        onLink={handleRoundLink}
      />
    );
  const roundsBarTool = (
    <Slider
      value={selectedRoundNum}
      total={maxRoundNum}
      current={currentRound?.currentRound}
      onChange={(v) => setSelectedRoundNum(v)}
    >
      <Toggle isActive={isRoundsList} onChange={(v) => setIsRoundsList(v)} images={['/assets/svg/grid-col.svg', '/assets/svg/grid-row.svg']} />
    </Slider>
  );
  const roundsListTool = (
    <React.Fragment>
      <FilterPanel {...filter} onFilterChanged={handleFilterChanged} />
      <Toggle
        isActive={isRoundsList}
        onChange={(v) => setIsRoundsList(v)}
        images={['/assets/svg/grid-col.svg', '/assets/svg/grid-row.svg']}
      />
    </React.Fragment>
  );

  return (
    <Section
      ref={ref}
      title="Rounds"
      info={roundInfo}
      tool={!isRoundsList ? roundsBarTool : roundsListTool}
    >
      {!isRoundsList && <RoundsBars data={data} roundNumber={selectedRoundNum} />}
      {isRoundsList && <RoundsList data={filteredRounds} />}
    </Section>
  );
});

Rounds.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  currentRound: PropTypes.number
};

Rounds.defaultProps = {
  data: null,
  currentRound: null
};

export default Rounds;
