import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ExpandForm from 'components/_common/Broxus/ExpandForm';
import FormPair from 'components/_common/Broxus/ExpandForm/FormPair';
import InlineSearchBar from 'components/_common/Broxus/InlineSearchBar';
import FormSelector from 'components/_common/Broxus/ExpandForm/FormSelector';

import { stakeTypes } from 'constants/depools';

const FilterPanel = ({
  amountFrom,
  amountTo,
  unusedStakeFrom,
  unusedStakeTo,
  dayRewardFrom,
  dayRewardTo,
  totalRewardFrom,
  totalRewardTo,
  type,
  onFilterChanged,
  search,
  onSearchChanged
}) => {
  const [amountFromLocal, setAmountFromLocal] = useState(amountFrom);
  const [amountToLocal, setAmountToLocal] = useState(amountTo);
  const [unusedStakeFromLocal, setUnusedStakeFromLocal] = useState(unusedStakeFrom);
  const [unusedStakeToLocal, setUnusedStakeToLocal] = useState(unusedStakeTo);
  const [dayRewardFromLocal, setDayRewardFromLocal] = useState(dayRewardFrom);
  const [dayRewardToLocal, setDayRewardToLocal] = useState(dayRewardTo);
  const [totalRewardFromLocal, setTotalRewardFromLocal] = useState(totalRewardFrom);
  const [totalRewardToLocal, setTotalRewardToLocal] = useState(totalRewardTo);
  const [count, setCount] = useState(0);
  const [typeLocal, setTypeLocal] = useState(type);
  const counters = [
    () => (amountFrom || amountTo),
    () => (unusedStakeFrom || unusedStakeTo),
    () => (dayRewardFrom || dayRewardTo),
    () => (totalRewardFrom || totalRewardTo),
    () => type
  ];

  useEffect(() => {
    setCount(counters.filter((e) => e()).length);
  }, [
    amountFrom, amountTo, unusedStakeFrom, unusedStakeTo,
    dayRewardFrom, dayRewardTo, totalRewardFrom, totalRewardTo, type
  ]);

  const handleApply = () => {
    onFilterChanged({
      amountFrom: amountFromLocal ? parseFloat(amountFromLocal) : null,
      amountTo: amountToLocal ? parseFloat(amountToLocal) : null,
      unusedStakeFrom: unusedStakeFromLocal ? parseFloat(unusedStakeFromLocal) : null,
      unusedStakeTo: unusedStakeToLocal ? parseFloat(unusedStakeToLocal) : null,
      dayRewardFrom: dayRewardFromLocal ? parseFloat(dayRewardFromLocal) : null,
      dayRewardTo: dayRewardToLocal ? parseFloat(dayRewardToLocal) : null,
      totalRewardFrom: totalRewardFromLocal ? parseFloat(totalRewardFromLocal) : null,
      totalRewardTo: totalRewardToLocal ? parseFloat(totalRewardToLocal) : null,
      type: typeLocal
    });
  };

  const handleClear = () => {
    setAmountFromLocal('');
    setAmountToLocal('');
    setUnusedStakeFromLocal('');
    setUnusedStakeToLocal('');
    setDayRewardFromLocal('');
    setDayRewardToLocal('');
    setTotalRewardFromLocal('');
    setTotalRewardToLocal('');
    setTypeLocal('');
  };

  const handleSearchChange = (event) => {
    onSearchChanged({
      search: event.target.value
    });
  };

  return (
    <React.Fragment>
      <InlineSearchBar search={search} onSearch={handleSearchChange} />
      <ExpandForm title="Filter" count={count} onApply={handleApply} onClear={handleClear}>
        <FormPair
          title="Amount"
          hint="ton"
          from={amountFromLocal}
          to={amountToLocal}
          onChangeFrom={(event) => setAmountFromLocal(event.target.value)}
          onChangeTo={(event) => setAmountToLocal(event.target.value)}
        />
        <FormPair
          title="Unused stake"
          hint="ton"
          from={unusedStakeFromLocal}
          to={unusedStakeToLocal}
          onChangeFrom={(event) => setUnusedStakeFromLocal(event.target.value)}
          onChangeTo={(event) => setUnusedStakeToLocal(event.target.value)}
        />
        <FormPair
          title="Day reward"
          hint="ton"
          from={dayRewardFromLocal}
          to={dayRewardToLocal}
          onChangeFrom={(event) => setDayRewardFromLocal(event.target.value)}
          onChangeTo={(event) => setDayRewardToLocal(event.target.value)}
        />
        <FormPair
          title="Total reward"
          hint="ton"
          from={totalRewardFromLocal}
          to={totalRewardToLocal}
          onChangeFrom={(event) => setTotalRewardFromLocal(event.target.value)}
          onChangeTo={(event) => setTotalRewardToLocal(event.target.value)}
        />
        <FormSelector
          title="Stake type"
          value={typeLocal}
          onSelect={(key) => setTypeLocal(key)}
          data={stakeTypes}
        />
      </ExpandForm>
    </React.Fragment>
  );
};

FilterPanel.propTypes = {
  amountFrom: PropTypes.number,
  amountTo: PropTypes.number,
  unusedStakeFrom: PropTypes.number,
  unusedStakeTo: PropTypes.number,
  dayRewardFrom: PropTypes.number,
  dayRewardTo: PropTypes.number,
  totalRewardFrom: PropTypes.number,
  totalRewardTo: PropTypes.number,
  type: PropTypes.number,
  onFilterChanged: PropTypes.func,
  search: PropTypes.string,
  onSearchChanged: PropTypes.func
};

FilterPanel.defaultProps = {
  amountFrom: null,
  amountTo: null,
  unusedStakeFrom: null,
  unusedStakeTo: null,
  dayRewardFrom: null,
  dayRewardTo: null,
  totalRewardFrom: null,
  totalRewardTo: null,
  type: null,
  onFilterChanged: null,
  search: null,
  onSearchChanged: null
};

export default FilterPanel;
