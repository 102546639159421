import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const Main = ({ children }) => (
  <main className="main">
    {children
    && (
      <div className="swap">
        {children}
      </div>
    )}
  </main>
);

Main.propTypes = {
  children: PropTypes.node
};

Main.defaultProps = {
  children: null
};

export default Main;
