import React from 'react';

import './index.scss';

const Footer = () => (
  <footer className="swap-footer">
    <div className="swap-container">
      <div className="swap-footer__wrap">
        <div className="swap-footer__left">
          <div className="swap-footer-logo">
            <img src="/assets/svg/ever-pools-beta.svg" alt="EVER Pool beta" />
          </div>
        </div>
        <div className="swap-footer__middle">
          <nav className="swap-footer-nav">
            <div className="swap-footer-nav__col">
              <h3 className="swap-footer-nav__title">Navigation</h3>
              <ul>
                <li><a href="/">Home</a></li>
                <li><a href="/explorer">Explorer</a></li>
                <li><a href="/my">My depool</a></li>
                <li><a href="https://en.freeton.wiki/Depool">Guide</a></li>
              </ul>
            </div>
            <div className="swap-footer-nav__col">
              <h3 className="swap-footer-nav__title">Support</h3>
              <ul>
                <li><a href="https://broxus.com">About</a></li>
                <li><a href="https://discord.gg/V7xcNQKEfu">Contact us</a></li>
              </ul>
            </div>
            <div className="swap-footer-nav__col">
              <h3 className="swap-footer-nav__title">DeFi Products</h3>
              <ul>
                <li><a href="https://octusbridge.io">Octus Bridge</a></li>
                <li><a href="https://flatqube.io">FlatQube</a></li>
                <li><a href="https://everscan.io">EVER Explorer</a></li>
                <li><a href="https://wrappedever.io">WEVER</a></li>
                <li><a href="https://l1.broxus.com/everscale/wallet">EVER Wallet</a></li>
              </ul>
            </div>
          </nav>
        </div>
        <div className="swap-footer__right">
          <a href="https://tonbridge.io" type="button" className="btn btn--grey swap-footer-btn">Bridge app</a>
        </div>
      </div>
      <div className="swap-footer__bottom">
        <p className="swap-copyright">© Broxus, 2022. v.1.1-rc5</p>
        <ul className="swap-soc">
          {/* <li><a href="#" target="_blank"><img src="/assets/svg/facebook.svg" alt="Facebook" /></a></li> */}
          <li><a href="https://twitter.com/Broxus" target="_blank" rel="noreferrer"><img src="/assets/svg/twitter.svg" alt="Twitter" /></a></li>
          <li><a href="https://github.com/broxus" target="_blank" rel="noreferrer"><img src="/assets/svg/github.svg" alt="GitHub" /></a></li>
          <li><a href="https://reddit.com/r/crypto_is_easy" target="_blank" rel="noreferrer"><img src="/assets/svg/reddit.svg" alt="Reddit" /></a></li>
          <li><a href="https://broxus.medium.com" target="_blank" rel="noreferrer"><img src="/assets/svg/medium.svg" alt="Medium" /></a></li>
        </ul>
      </div>
    </div>
  </footer>
);

export default Footer;
