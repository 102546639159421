import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const ArrowIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.0002 12.5L5.00024 7.5H15.0002L10.0002 12.5Z" fill="white" />
  </svg>
);

const TableHeader = ({
  headCells,
  order,
  orderBy,
  onRequestSort
}) => {
  const sortClass = (column) => {
    if (column.id !== orderBy)
      return null;
    return order === 'asc' ? 'active up' : 'active down';
  };

  const alignClass = (column) => `swap-table-col--align-${column.align}`;

  const replaceWithBr = (text) => (
    !text?.includes('\n') ? text : (
      <React.Fragment>
        {text.substring(0, text.indexOf('\n'))}
        <br />
        {text.substring(text.indexOf('\n') + '\n'.length - 1)}
      </React.Fragment>
    )
  );

  return (
    <div className="swap-table__row swap-table__row--mob-hide">
      {headCells.filter((h) => h.id && h.type !== 'action').map((h) => (
        <div key={h.id} className={classnames(['swap-table-col', alignClass(h)])}>
          <button type="button" onClick={(e) => onRequestSort(e, h.id)} className={classnames(['swap-form-drop', sortClass(h)])}>
            <span className="swap-form-drop__name">{replaceWithBr(h.label)}</span>
            <span className="swap-form-drop__arrow">
              <ArrowIcon />
            </span>
          </button>
        </div>
      ))}
      {headCells.filter((h) => h.type === 'action').map((h) => (
        <div key={h.label ?? h.action} className="swap-table-col swap-table-col--align-center">
          <div className="swap-table-col__th">{h.label}</div>
        </div>
      ))}
    </div>
  );
};

TableHeader.propTypes = {
  headCells: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    numeric: PropTypes.bool,
    disablePadding: PropTypes.bool,
    label: PropTypes.node
  })).isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired
};

export default TableHeader;
