import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const Icons = {
  ton: <img src="/assets/svg/ton-crystal.svg" alt="" />,
  copy: <img src="/assets/svg/copy-tool.svg" alt="" />,
  link: <img src="/assets/svg/external-link.svg" alt="" />
};

const InfoList = ({
  title,
  data
}) => (
  <React.Fragment>
    <div className="swap-profile__blc swap-profile-info">
      <h3 className="swap-profile-info__title">{title}</h3>
      <ul className="swap-profile-info__list">
        {data.map((item) => (
          <li key={item.label} className="swap-profile-info__row">
            <div className="swap-profile-info__name">{item.label}</div>
            <div className="swap-profile-info__val">
              {item.onValueClick && (
                <div role="button" tabIndex={0} onClick={item.onValueClick} title={item.valueTitle} className="active">
                  {item.value}
                </div>
              )}
              {!item.onValueClick && (
                <span title={item.valueTitle}>
                  {item.value}
                </span>
              )}
              {item.onIconClick && (
                <button
                  type="button"
                  className="btn swap-profile-main__tool"
                  onClick={item.onIconClick}
                >
                  {Icons[item.hint]}
                </button>
              )}
              {!item.onIconClick && (
                <React.Fragment>
                &nbsp;
                  {Icons[item.hint]}
                </React.Fragment>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  </React.Fragment>
);

InfoList.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.node,
    valueTitle: PropTypes.node,
    hint: PropTypes.string,
    onIconClick: PropTypes.func,
    onValueClick: PropTypes.func
  })).isRequired
};

export default InfoList;
