import React from 'react';

import './index.scss';
import PropTypes from 'prop-types';

import history from 'utils/history';

const ToolBack = ({
  title,
  url
}) => {
  const handleClick = (e) => {
    history.push(url);

    e.preventDefault();
    e.stopPropagation();
    return false;
  };

  return (
    <div className="swap-container swap-tools-header">
      <a href={url} onClick={handleClick} className="swap-tool-back">
        <img src="/assets/svg/arrow-left.svg" alt="" />
        {title}
      </a>
    </div>
  );
};

ToolBack.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string
};

ToolBack.defaultProps = {
  title: null,
  url: null
};

export default ToolBack;
