import React from 'react';

import PropTypes from 'prop-types';

const TableContainer = ({ children }) => (
  <div className="swap__wrap swap__wrap--long">
    {children}
  </div>
);

TableContainer.propTypes = {
  children: PropTypes.node
};

TableContainer.defaultProps = {
  children: null
};

export default TableContainer;
