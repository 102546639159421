import React from 'react';
import PropTypes from 'prop-types';
import List from 'components/_common/Broxus/List';

const StakeDistribution = ({ data }) => {
  const meta = [
    { id: 'type', type: 'string', label: 'Type' },
    { id: 'round', type: 'string', label: 'Round' },
    { id: 'totalAmount', type: 'ton', label: 'Total amount, EVER' },
    { id: 'unusedAmount', type: 'ton', label: 'Unused Amount, EVER' },
    { id: 'efficiency', type: 'percent', label: 'Efficiency' }
  ];

  return (
    <List data={data.map((e) => ({ ...e, id: `${e.type}-${e.round}` }))} meta={meta} showPagination={false} className="stakes-distribution-table" />
  );
};

StakeDistribution.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({}))
};

StakeDistribution.defaultProps = {
  data: null
};

export default StakeDistribution;
