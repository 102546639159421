import React from 'react';
import PropTypes from 'prop-types';
import copy from 'clipboard-copy';

import List from 'components/_common/Broxus/List';
import Info from 'components/DepoolPage/RoundsList/Info';

import { roundStages } from 'constants/depools';
import { roundStage } from 'utils/format';
import { BigNumber } from 'bignumber.js';

const Details = ({ item, action }) => (
  <React.Fragment>
    {action === 'info' && <Info {...item} />}
  </React.Fragment>
);

Details.propTypes = {
  item: PropTypes.shape({}),
  action: PropTypes.string
};

Details.defaultProps = {
  item: null,
  action: null
};

const RoundsList = ({ data }) => {
  const meta = [
    {
      id: 'number',
      type: 'string',
      label: 'Round',
      hasAvatar: true,
      onLink: (item) => copy(
        `${window.location.protocol}//${window.location.host}${window.location.pathname}?round=${item.number}`
      )
    },
    { id: 'stake', type: 'ton', label: 'Total stake,\nEVER' },
    { id: 'unused', type: 'ton', label: 'Unused stake,\nEVER' },
    { id: 'participantReward', type: 'ton', label: 'Reward, EVER' },
    { id: 'participantQty', type: 'numeric', label: 'Participants' },
    { id: 'apy', type: 'percent', label: 'APY' },
    { id: 'step', label: 'Stage', view: (v, view, e) => roundStages[roundStage(v, e.completionReason)] },
    { id: 'supposedElectedAt', type: 'datetime', label: 'Elected at, UTC' },
    { label: 'More', action: 'info' }
  ];

  const items = data.map((e) => {
    const prev = data.filter((i) => i.number === e.number - 2)[0];
    const multi = !prev?.supposedElectedAt ? 1 : (e.supposedElectedAt - prev.supposedElectedAt) / 3600 / 24 / 2;
    return {
      ...e,
      apy: (e.stake === '0' ? 0
        : new BigNumber(e.participantReward).multipliedBy(365 * 100 * multi)
          .dividedBy(new BigNumber(e.stake ?? 0).plus(e.validatorStake ?? 0))
          .toNumber())
    };
  });

  return (
    <List data={items.map((e) => ({ ...e, id: e.number.toString() }))} meta={meta} className="rounds-table">
      <Details />
    </List>
  );
};

RoundsList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({}))
};

RoundsList.defaultProps = {
  data: null
};

export default RoundsList;
