import React, { useState } from 'react';
import Chart from 'components/_common/Chart';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './index.scss';

const ChartBar = ({ data, type }) => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleClick = (e, index) => {
    setTabIndex(index);

    e.preventDefault();
    e.stopPropagation();
    return false;
  };

  return (
    <div className="swap-graph-sec__content">
      <div className="swap-graph-sec__content-top">
        <nav className="swap-tabs">
          <ul>
            {data && data.map((chart, index) => (
              <li key={`${chart.label}-${data.length}`}><a href="" onClick={(e) => handleClick(e, index)} className={classnames(['swap-tab', index === tabIndex ? 'active' : null])}>{chart.label}</a></li>
            ))}
          </ul>
        </nav>
      </div>
      {data && data.map((chart, index) => (
        <div key={`${chart.label}-${data.length}`} hidden={tabIndex !== index} className="swap-graph-sec__content-graphic">
          {tabIndex === index && <Chart type={type} labels={chart.labels?.length ? chart.labels : ['', '']} data={chart.data?.length ? chart.data : [0, 0]} />}
        </div>
      ))}
    </div>
  );
};

ChartBar.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    labels: PropTypes.arrayOf(PropTypes.string),
    data: PropTypes.arrayOf(PropTypes.number)
  })),
  type: PropTypes.string
};

ChartBar.defaultProps = {
  data: null,
  type: null
};

export default ChartBar;
