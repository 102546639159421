import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const Icons = {
  ton: <img src="/assets/svg/ton-crystal.svg" alt="" />,
  copy: <img src="/assets/svg/copy.svg" alt="" />
};

const InfoList = ({ title, data }) => (
  <React.Fragment>
    <h3 className="swap-acc-table-frame__title">{title}</h3>
    <div className="swap-acc-table-frame__cols">
      {data.map((item) => (
        <div key={item.label} className="swap-acc-table-frame__row">
          <div>{item.label}</div>
          <div className="swap-acc-table-frame__rowval">
            {item.onClick && <strong>{item.value}</strong>}
            {!item.onClick && <span>{item.value}</span>}
            {item.onClick && (
              <button type="button" className="btn" onClick={item.onClick}>
                {Icons[item.hint]}
              </button>
            )}
            {!item.onClick && Icons[item.hint]}
          </div>
        </div>
      ))}
    </div>
  </React.Fragment>
);

InfoList.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.node,
    hint: PropTypes.string,
    onClick: PropTypes.func
  })).isRequired
};

export default InfoList;
