import React from 'react';

import PropTypes from 'prop-types';

import './index.scss';

const TablePagination = ({
  page,
  count,
  rowsPerPage,
  onChangePage
}) => {
  const pageCount = Math.ceil(count * 1.0 / rowsPerPage);
  const handlePrevPage = (event) => {
    if (page <= 0)
      return;
    onChangePage(event, page - 1);
  };

  const handleNextPage = (event) => {
    if (page >= pageCount - 1)
      return;
    onChangePage(event, page + 1);
  };

  const handlePageChange = (event) => {
    const p = parseInt(event.target.value, 10);
    if (p > 0)
      onChangePage(event, p - 1);
  };

  return (
    <div className="swap-pagination">
      <div className="swap-pagination__txt">Page</div>
      <input type="text" className="swap-pagination__input" value={page + 1} onChange={handlePageChange} />
      <div className="swap-pagination__txt">
        of&nbsp;
        {pageCount}
      </div>
      <button type="button" onClick={handlePrevPage} className="btn swap-pagination__btn">
        <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M-2.62268e-07 6L6 0L6 12L-2.62268e-07 6Z" />
        </svg>
      </button>
      <button type="button" onClick={handleNextPage} className="btn swap-pagination__btn">
        <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6 6L0 12L-5.24537e-07 0L6 6Z" />
        </svg>
      </button>
    </div>
  );
};

TablePagination.propTypes = {
  page: PropTypes.number,
  count: PropTypes.number,
  rowsPerPage: PropTypes.number,
  onChangePage: PropTypes.func
};

TablePagination.defaultProps = {
  page: null,
  count: null,
  rowsPerPage: null,
  onChangePage: null
};

export default TablePagination;
