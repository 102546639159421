import React from 'react';
import PropTypes from 'prop-types';
import AddressAvatar from 'components/_common/AddressAvatar';

const Info = ({ value, onLink }) => (
  <React.Fragment>
    <div className="swap__header-txt"><img src="/assets/svg/drop-right.svg" alt="" /></div>
    <div className="swap__header-ava">
      <AddressAvatar number={value} small />
    </div>
    <div className="swap__header-txt">{(value ?? '').toString()}</div>
    {onLink && (
      <button type="button" onClick={onLink} className="btn swap__header-tool">
        <img src="/assets/svg/chain.svg" alt="" />
      </button>
    )}
  </React.Fragment>
);

Info.propTypes = {
  value: PropTypes.node,
  onLink: PropTypes.func
};

Info.defaultProps = {
  value: null,
  onLink: null
};

export default Info;
