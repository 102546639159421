import superagent from 'superagent';
import { TonClient } from '@tonclient/core';
import Depoolv3Abi from 'assets/Depoolv3.abi.json';
import IParticipantv3Abi from 'assets/IParticipantv3.abi.json';

import {
  depoolVersions
} from 'constants/depools';

const host = 'https://api-tonpool-test.broxus.com';

const responseBody = (res) => {
  if (res?.body)
    return res.body;
  return res;
};

const handleError = (e) => {
  try {
    const errors = !e.response?.text ? null : JSON.parse(e.response.text)?.errors;
    return !errors ? null : { errors };
  }
  catch {
    return null;
  }
};

const requests = {
  get: (url, params) => superagent.get(`${host}${url}`).query(params)
    .catch(handleError)
    .then(responseBody),
  post: (url, params) => superagent.post(`${host}${url}`).send(params)
    .catch(handleError)
    .then(responseBody),
  put: (url, params) => superagent.put(`${host}${url}`).send(params)
    .catch(handleError)
    .then(responseBody),
  del: (url, params) => superagent.del(`${host}${url}`).send(params)
    .catch(handleError)
    .then(responseBody)
};

const tonClient = new TonClient({
  network: {
    server_address: 'mainnet.evercloud.dev/a66286efb684417a83841e2bc5561272'
  }
});

const tonClients = [
  new TonClient({
    network: {
      server_address: 'mainnet.evercloud.dev/a66286efb684417a83841e2bc5561272'
    }
  })
];

const depoolv3Abi = {
  type: 'Contract',
  value: Depoolv3Abi
};

const participantv3Abi = {
  type: 'Contract',
  value: IParticipantv3Abi
};

const executeRequest = async (gql) => {
  let res = null;
  for (let i = 0; i < tonClients.length; i++) {
    try {
      /* eslint-disable no-await-in-loop */
      res = await tonClients[i].net.query_collection(gql);
      /* eslint-enable no-await-in-loop */
      break;
    }
    catch (e) {
      if (i === tonClients.length - 1)
        throw (e);
    }
  }
  return res;
};

const runDepoolGet = async ({
  address,
  boc,
  func,
  input
}) => {
  const { message } = await tonClient.abi.encode_message({
    abi: depoolv3Abi,
    address,
    call_set: {
      function_name: func,
      input: input ?? {}
    },
    signer: { type: 'None' }
  });

  const res = await tonClient.tvm.run_tvm({ message, account: boc, abi: depoolv3Abi });
  return res?.decoded?.out_messages[0]?.value;
};

const API = {
  getOverview: () => requests.get('/v1/overview'),
  getDepoolsCache: async () => requests.get('/v1/depools'),
  getDepoolsByParticipantCache: async ({ participant }) => requests.get(`/v1/participants/${participant}/depools`),
  getDepoolsRoundsCache: async ({ id }) => requests.get(`/v1/depools/${id}/rounds`),
  getDepoolsRoundsParticipantsCache: async ({ id }) => requests.get(`/v1/depools/${id}/rounds/-/participants`),

  getAccount: async ({ address }) => {
    const gql = {
      collection: 'accounts',
      filter: {
        id: {
          eq: address
        }
      },
      result: 'id balance boc code_hash'
    };
    const res = await executeRequest(gql);
    return (res?.result ?? [])[0];
  },

  getDepools: async ({ limit, next }) => {
    const gql = {
      collection: 'accounts',
      filter: {
        code_hash: {
          in: Object.values(depoolVersions)
        }
      },
      order: [{
        path: 'id',
        direction: 'ASC'
      }],
      limit,
      result: 'id boc code_hash'
    };
    if (next) {
      gql.filter.id = {
        gt: next
      };
    }
    const res = await executeRequest(gql);
    return res?.result ?? [];
  },

  getTransactions: async ({ address, limit, next }) => {
    const gql = {
      collection: 'transactions',
      filter: {
        account_addr: {
          eq: address
        }
      },
      order: [{
        path: 'now',
        direction: 'DESC'
      }],
      limit,
      result: 'id now lt'
    };
    if (next) {
      gql.filter.now = {
        gt: next
      };
    }
    const res = await executeRequest(gql);
    return res?.result ?? [];
  },

  getDepoolInfo: ({ address, boc }) => runDepoolGet({ address, boc, func: 'getDePoolInfo' }),
  getParticipants: ({ address, boc }) => runDepoolGet({ address, boc, func: 'getParticipants' }),
  getRounds: ({ address, boc }) => runDepoolGet({ address, boc, func: 'getRounds' }),
  getDepoolBalance: ({ address, boc }) => runDepoolGet({ address, boc, func: 'getDePoolBalance' }),
  getParticipantInfo: async ({ address, boc, participant }) => {
    let accountBoc = boc;
    if (!accountBoc) {
      const acc = await API.getAccount({ address });
      accountBoc = acc?.boc;
    }
    try {
      const result = await runDepoolGet({
        address,
        boc: accountBoc,
        func: 'getParticipantInfo',
        input: { addr: participant }
      });
      return result;
    }
    catch {
      return null;
    }
  },

  decodeDepoolMessageBody: async ({
    body
  }) => {
    const result = await tonClient.abi.decode_message_body({
      abi: depoolv3Abi,
      body,
      is_internal: true
    });

    return result;
  },
  decodeDepoolParticipantMessageBody: async ({
    body
  }) => {
    const result = await tonClient.abi.decode_message_body({
      abi: participantv3Abi,
      body,
      is_internal: true
    });

    return result;
  },

  getElectorMessages: async ({ addresses, next }) => {
    const gql = {
      collection: 'messages',
      filter: {
        src: {
          in: addresses
        },
        dst: {
          in: addresses
        },
        msg_type: {
          eq: 0
        },
        status: {
          eq: 5
        },
        value: {
          gt: '0x4b9aca00'
        }
      },
      order: [{
        path: 'created_at',
        direction: 'DESC'
      }],
      result: 'id src dst created_at created_lt value'
    };
    if (next) {
      gql.filter.created_at = {
        lt: next
      };
    }
    const res = await executeRequest(gql);
    return res?.result ?? [];
  }
};

export default API;
